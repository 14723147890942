import React from "react";
import {Link} from "react-router-dom";
import info from "../../../../../../images/info.svg";
import styles from "./company-item.module.css";
import {Popover} from "antd";
import clsx from "classnames";
import {isTouchDevice} from "../../../../../../utils/isTouchDevice";
import {ChartPopup} from "./chart-popup";
import {LazyRadarChart} from "./radar-chart";
import {MobilePopover} from "./mobile-popover";
import {useDispatch} from "react-redux";
import {setScrollTop} from "../../../../../../services/reducers/share-list-slice";

const chartDataBase = [
  {subject: 'Оценка стоимости'},
  {subject: 'Рост'},
  {subject: 'Прошлые показатели'},
  {subject: 'Финансовое состояние'},
  {subject: 'Дивиденды'},
]

export const CompanyItem = React.memo(({img, title, ticker, sector, snowFlake}) => {
  const dispatch = useDispatch();
  const chartData = chartDataBase.map((item, index) => ({
    ...item,
    A: snowFlake[index] + 1,
  }));

  const snowFlakeSum = snowFlake.reduce((a, b) => a + b)

  const handleLinkClick = e => {
    dispatch(setScrollTop(document.documentElement.scrollTop));

    // If target is chart or inside chart
    if(e.target.closest(`.${styles.chart}`) || e.target.classList.contains(styles.chart)) {
      e.preventDefault();
    }
  }

  const CurrentPopover = isTouchDevice() ? MobilePopover : Popover;

  return (
    <Link to={`/screener/${ticker}`} className={styles.companyItem} onClick={handleLinkClick}>
      <div className={styles.mainInfo}>
        <img src={`https://invest-era.ru${img}`} alt=""
             width={48} height={48}/>
        <div className={styles.info}>
          <h4 className={styles.title}>{title}</h4>
        </div>
        <div className={clsx(styles.chart, styles.appear)}>
          <CurrentPopover content={<ChartPopup snowflake={snowFlake}/>} arrow={false}>
            <LazyRadarChart chartData={chartData} snowFlakeSum={snowFlakeSum}/>
            <img className={styles.infoIcon} src={info} alt="info"/>
          </CurrentPopover>
        </div>
      </div>
      <div className={styles.additionalInfo}>
        <p className={styles.ticker}>{ticker}</p>
        <div className={styles.dot}></div>
        <p className={styles.sector}>{sector}</p>
      </div>
    </Link>
  );
}, ((prevProps, nextProps) => {
  return prevProps.title === nextProps.title;
}));


