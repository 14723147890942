import React, {useEffect, useMemo, useState} from "react";
import {Dropdown, Menu, Space} from "antd";
import clsx from "classnames";
import {CompanyItem} from "./company-item/company-item";
import crossIcon from "../../../../../images/cross.svg";
import arrowDown from "../../../../../images/arrow-down-sign-to-navigate-svg.svg";
import loop from '../../../../../images/loop.svg'
import cross from '../../../../../images/cross-search.svg'
import Preloader from "../../../../preloader/preloader";
import {SearchListItem} from "./search-list-item/search-list-item";
import {getPieceCount} from "./getPieceCount";
import {BreadcrumbNavigation} from "../../../../breadcrumb-navigation/breadcrumbsNavigation";
import styles from "./screener-main.module.css";
import {
  fetchShareList,
  setSelectedCategory,
  setSelectedSector
} from "../../../../../services/reducers/share-list-slice";
import {useDispatch, useSelector} from "react-redux";

const categories = [{name: "Дивидендные", id: "dividends"}, {name: "Растущие", id: "future"}, {
  name: "Value",
  id: "value"
},]

export const ScreenerMain = () => {
  const dispatch = useDispatch();
  const { sectors, companies, loading, selectedSector, selectedCategory } = useSelector((state) => state.shareList);
  const scrollTop = useSelector((state) => state.shareList.scrollTop);

  const [isSearchFocusActive, setIsSearchFocusActive] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [searchResults, setSearchResults] = useState([])
  const [isShowSearchList, setIsShowSearchList] = useState(false)
  const [isShowCrossIcon, setIsShowCrossIcon] = useState(false)

  const onChangeHandler = (e) => {
    setSearchValue(e.target.value)
  }

  const onFocusSearchHandler = () => {
    setIsSearchFocusActive(true)
    setIsShowSearchList(true)
    setIsShowCrossIcon(true)
  }

  const onBlurSearchHandler = () => {
    setIsSearchFocusActive(false)
    setIsShowSearchList(false)
    setIsShowCrossIcon(false)
  }

  const onSectorClick = (e) => {
    const sector = sectors.find(el => el.id === +e.key)
    dispatch(setSelectedSector(sector));
  }

  const removeSelection = () => {
    dispatch(setSelectedSector({id: 0, name: 'Отрасль'}))
  }

  const clearSearchInput = () => {
    setSearchValue('')
  }

  const onCategoryClick = (category) => {
    if (selectedCategory === category) return dispatch(setSelectedCategory(null));
    dispatch(setSelectedCategory(category))
  }

  useEffect(() => {
    if(companies.length) return;

    dispatch(fetchShareList())
  }, []);

  useEffect(() => {
    document.documentElement.scrollTop = scrollTop;
  }, []);

  useEffect(() => {
    if(searchValue === '') {
      setSearchResults([]);
      return;
    }

    const results = companies.filter(company =>
      company.name.toLowerCase().includes(searchValue.toLowerCase()) ||
      company.ticker.toLowerCase().includes(searchValue.toLowerCase())
    );

    setSearchResults(results);
  }, [searchValue, companies]);


  const filteredBySectorCompanies = useMemo(() => {
    return companies
      .filter(company => company.sector.id === selectedSector.id || selectedSector.id === 0)
  }, [companies, selectedSector, selectedCategory]);

  const filteredByCategoryCompanies = useMemo(() => {
    if(!selectedCategory) return filteredBySectorCompanies

    return filteredBySectorCompanies
      .filter(company => company.categories.includes(selectedCategory.id))
  }, [selectedCategory, filteredBySectorCompanies])

  const categoriesCount = useMemo(() => {
    const result = {};

    for (let company of filteredBySectorCompanies) {
      if (company.categories) {
        for (let category of company.categories) {
          if (!result[category]) {
            result[category] = 1;
          } else {
            result[category]++;
          }
        }
      }
    }

    return result;
  }, [filteredBySectorCompanies, categories]);

  const menu = (
    <Menu onClick={onSectorClick} className={styles.dropdownMenu}>
      {sectors.length > 0 && sectors.map(item => (
        <Menu.Item
          key={item.id}
          className={`${item.id === selectedSector.id ? styles.selectedDropdownItem : ''} ${item.id === 0 && styles.notSelectItem}`}
        >
          {item.name}
        </Menu.Item>
      ))}
    </Menu>
  );


  return (
    <div className={`${styles.screenerMain} ${loading && styles.screenerMainLoader}`}>
      <BreadcrumbNavigation forBg="light" links={[{label: "Главная", url: "/"}]}/>

      <div className={styles.content}>
        <h2 className={styles.title}>Компании</h2>
        <div className={styles.searchContainer}>
          <div
            className={`${styles.searchInput} ${isSearchFocusActive && styles.fullWidthSearchInput}`}>
            <input className={styles.search} type="text" onChange={onChangeHandler} value={searchValue}
                   placeholder={'Поиск'} onFocus={onFocusSearchHandler}
                   onBlur={onBlurSearchHandler}/>
            <img src={loop} alt="loop icon" className={styles.loopIcon}/>
            {isShowCrossIcon && searchValue && <img src={cross}
                                                    alt="cross icon"
                                                    className={styles.crossIcon}
                                                    onMouseDown={(e) => e.preventDefault()}
                                                    onClick={clearSearchInput}/>}
            {searchResults.length > 0 && isShowSearchList && <div className={styles.searchList}>
              {searchResults.map(item => {
                return <SearchListItem key={`${item?.ticker}-${item?.id}`} title={item?.name}
                                       ticker={item?.ticker} sector={item?.sector[0]?.name}/>
              })}
            </div>}
          </div>

          {sectors.length > 0 && <Dropdown
            className={`${styles.dropdown} ${selectedSector.id === 0 && styles.notValue} ${isSearchFocusActive && styles.notVisible}`}
            overlay={menu}
            trigger={['click']}
            placement="bottomLeft"
            autoAdjustOverflow={true}
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
            openClassName="dropdown-open"
          >
            <a onClick={(e) => e.preventDefault()}>
              <Space
                className={styles.space}
              >
                <div className={styles.dropdownSpaceContainer}>
                  {selectedSector.id !== 0 && <button className={styles.removeSelection}
                                                      onClick={(e) => {
                                                        e.stopPropagation()
                                                        removeSelection(e)
                                                      }}
                  >
                    <img src={crossIcon} alt="крестик" width={10}/>
                  </button>}
                  {selectedSector.name}
                </div>
                <img src={arrowDown} alt="arrow down"/>
              </Space>
            </a>
          </Dropdown>}
        </div>
        <div className={styles.filters}>
          {categories.map(category => {
            const categoryCount = categoriesCount[category.id] || 0;
            if(selectedCategory === category && !categoryCount) {
              dispatch(setSelectedCategory(null));
            }

            return (
              <div className={
                clsx(styles.filter,
                  {[styles.filterActive]: categoryCount && category === selectedCategory},
                  {[styles.filterDisabled]: !categoryCount}
                )}
                   onClick={() => categoryCount && onCategoryClick(category)}
                   key={category.id}
              >
                <p className={styles.filterName}>{category.name}</p>
                <p
                  className={styles.filterCount}>{categoryCount} {getPieceCount(categoryCount)}</p>
              </div>
            )
          })}
        </div>
        <div className={styles.itemsContainer}>
          {loading ?
            <div className={styles.preloaderContainer}>
              <Preloader/>
            </div> : filteredByCategoryCompanies.map((item) => {
              // оценка стоимости, рост, прошлые, финансовое состояние, дивиденды
              const snowFlake = [item.value, item.future, item.past, item.health, item.dividends];
              return <CompanyItem key={`${item.ticker}-${item.id}`} img={item.logo} title={item.name}
                                  ticker={item.ticker} sector={item.sector.name} snowFlake={snowFlake}/>
            })}
        </div>
      </div>
    </div>
  );
};


