import React, {useState} from 'react';
import {TariffsItemBenefit} from "./tariffs-item-benefit/tariffs-item-benefit";
import { v1 } from "uuid";
import {NavLink} from "react-router-dom";
import styles from './tariffs-item.module.css';

export const TariffsItem = ({subscription, setShowModal, setSubSelect, setSubNameSelect, setDefaultOptionMonth}) => {
  const periodBtnArr = [
    {id: v1(), index: 0, title: '1 мес', ym: '1m'},
    {id: v1(), index: 1, title: '6 мес', ym: '6m', discount: subscription.prices.sixMonth.discount},
    {id: v1(), index: 2, title: '1 год', ym: '1y', discount: subscription.prices.year.discount}
  ]

  const [activePeriodBtn, setActivePeriodBtn] = useState(periodBtnArr[0])
  const [activePopover, setActivePopover] = useState(null);

  const onClickBuyHandler = () => {
    if(window.ym) {
      window.ym(96742836, 'reachGoal', `clck-mn-${subscription.ym}-${activePeriodBtn.ym}`);
    }
    setDefaultOptionMonth(activePeriodBtn.index);
    setSubSelect(subscription.subTitle)
    setSubNameSelect(subscription.subName)
    setShowModal(true)
  }

  const handlePeriodClick = (btn, index) => {
    setActivePeriodBtn(btn);
    // setDefaultOptionMonth(index);
  }

  return (
    <div className={styles.subscriptionItem}>
      <div>
        <div className={styles.headBlock}>
          <h2 className={styles.itemTitle}>{subscription.title}</h2>
          <p className={styles.itemDesc}>{subscription.description}</p>
        </div>

        <div className={styles.periodSwitcherContainer}>
          <div className={styles.periodSwitcher}>
            {periodBtnArr.map((btn, index) => {
              return <button key={btn.id}
                             className={`${styles.periodBtn} ${activePeriodBtn.title === btn.title && styles.activePeriodBtn}`}
                             onClick={() => handlePeriodClick(btn)}>
                {btn.title}
                {btn.title !== '1 мес' && <span className={styles.discountLabel}>{btn.discount}%</span>}
              </button>
            })}
          </div>
        </div>

        <div className={styles.mainBlock}>
          <div className={styles.price}>
                        <span className={styles.priceUp}>
                            {activePeriodBtn.title === '1 мес' ? subscription.prices.oneMonth.price : activePeriodBtn.title === '6 мес' ? subscription.prices.sixMonth.price : subscription.prices.year.price
                            } ₽ <span className={styles.priceDown}>/ мес</span></span>

          </div>
          <div className={styles.benefits}>
            {subscription.benefits.map((benefit, index) => {
              return <TariffsItemBenefit key={v1()} benefit={benefit} index={index} activePopover={activePopover}
                                         setActivePopover={setActivePopover}/>
            })}
          </div>
        </div>
      </div>

      <div>
        <button className={styles.buyBtn} onClick={onClickBuyHandler}>Купить</button>
        <NavLink to={`/products/${subscription.subSlug}`}>
          <button className={styles.moreBtn}>Подробнее о подписке</button>
        </NavLink>
      </div>
    </div>
  );
};