import React, {useState} from 'react';
import classNames from "classnames";
import InfoSVG from '../../../../../images/tuiIconInfoCircleLarge.svg';
import ChartSVG from '../../../../../images/tuiIconChartLineLarge.svg';
import PredictionSVG from '../../../../../images/tuiIconChartLarge.svg';
// import FileSVG from '../../../../../images/tuiIconFileLarge.svg';
// import CalendarSVG from '../../../../../images/tuiIconCalendarLarge.svg';
import styles from './screener-navigate.module.css'

const ScreenerNavigate = ({setActiveTab}) => {
    const [active, setActive] = useState('Информация');
    const btnsArr = [
        {id: 1, name: 'Информация', imgSrc: InfoSVG, imgAlt: 'info'},
        // {id: 2, name: 'Финансовые показатели', imgSrc: ChartSVG, imgAlt: 'chart'},
        // {id: 3, name: 'Прогнозы', imgSrc: PredictionSVG, imgAlt: 'predictions'},
        // {id: 4, name: 'Анализ', imgSrc: ChartSVG, imgAlt: 'chart' },
        // {id: 5, name: 'Календарь', imgSrc: CalendarSVG, imgAlt: 'calendar' },
        // {id: 6, name: 'Документы', imgSrc: FileSVG, imgAlt: 'file' },
    ]

    const handleButtonClick = (name) => {
        setActive(name);
        setActiveTab(name)
    };

    return (
        <div className={styles.navigate}>
            {btnsArr.map((item) => {
                const isActive = active === item.name;
                return (
                    <button onClick={() => handleButtonClick(item.name)}
                            className={classNames(styles.btn, {[styles.active]: isActive})}
                            key={item.id}>
                        <img src={item.imgSrc} alt={item.imgAlt}/>
                        {item.name}
                    </button>
                )
            })}
        </div>
    );
};

export default ScreenerNavigate;
