import {useEffect, useState, useCallback, useRef,} from "react"
import {useSearchParams,} from "react-router-dom"
import { useMediaQuery } from "react-responsive"
import { useDispatch, useSelector } from "react-redux"
import arrowImgPopular from "../../images/sort-arrow-popular.svg"
import arrowDownGray from "../../images/arrow-down-sign-to-navigate-svg.svg"
import filterIcon from "../../images/filter-svg.svg"
import filterUseIcon from "../../images/filter-use-svg.svg"
import closeXSign from "../../images/close-x-sign-svg.svg"
import RelevantSection from "../relevant-section/RelevantSection"
import SectionCheckBoxPoints from "../check-box-section/SectionCheckBoxPoints"
import FilterSection from "../filters-section/FilterSection"
import SectionSectorCheckBoxPoints from "../check-box-section-sector/SectionSectorCheckBoxPoints"
import ChackBoxChoiseSection from "../check-box-choise-section/ChackBoxChoiseSection"
import SectionCompanyCheckBoxPoints from "../check-box-section-company/SectionCompanyCheckBoxPoints"
import { useActiveButtonCountryTagChoice } from "../../services/context/CountryFilterContext"
import { useActiveFilters } from "../../services/context/ActiveFiltersContext"
import SearchSectionFast from "../searchFast/searchFast"
import {
  setCategoryPoints,
  setCountryPoints,
  setSectorPoints,
  setClearCompanyPoints,
  setCompanyPoints,
  setAnalysisPoints,
  setSubscriptionPoints,
  setBondsPoints, setChangePortfoliosPoints,
} from "../../services/reducers/cards-filtred-slice"
import {
  setSelectCategoryPoint,
  setSelectCountryPoint,
  setSelectSectorPoint,
  setClearSelectCompanyPoint,
  seturlParams,
  setCheckBoxChoice,
} from "../../services/reducers/search-vision-points-slice"
import {
  selectOrderingVision,
  categoryPrefilterSetPoints,
  selectCategoryVision,
  countryPrefilterSetPoints,
  selectCountryVision,
  sectorPrefilterSetPoints,
  selectSectorVision,
  companyPrefilterSetPoints,
  companyCategoryVision,
  checkBoxChoiceVision,
  selectAnalysisVision,
  selectSubscriptionVision,
  selectBondsVision,
  selectPortfoliosVision,
  countryToApi,
  sectorToApi,
  categoryToApi
} from "../../services/selectors"
import styles from "./search.module.css"

const SearchSection = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 800px)" })

  const mounted = useRef(false)
  const filters = useActiveFilters()
  const countryPreference = useActiveButtonCountryTagChoice()
  const dispatch = useDispatch()
  const [searchParams, setSearchParams] = useSearchParams()

  const selectRelevant = useSelector(selectOrderingVision)
  const categoryPoints = useSelector(categoryPrefilterSetPoints)
  const selectCategoryPoint = useSelector(selectCategoryVision)

  const countryPoints = useSelector(countryPrefilterSetPoints)
  const selectCountryPoint = useSelector(selectCountryVision)

  const sectorPoints = useSelector(sectorPrefilterSetPoints)
  const selectSectorPoint = useSelector(selectSectorVision)

  const companyPoints = useSelector(companyPrefilterSetPoints)
  const selectCompanyPoint = useSelector(companyCategoryVision)

  const checkBoxChoice = useSelector(checkBoxChoiceVision)

  const selectAnalysisPoint = useSelector(selectAnalysisVision)
  const selectSubscriptionPoint = useSelector(selectSubscriptionVision)
  const selectBondsPoint = useSelector(selectBondsVision)
  const selectChangePortfolios = useSelector(selectPortfoliosVision);

  // // ===================== selector for API =============
  const countryPointsForApi = useSelector(countryToApi)
  const sectorPointsForApi = useSelector(sectorToApi)
  const categoryPointsForApi = useSelector(categoryToApi)

  const [mobileFilterSwich, setMobileFilterSwitch] = useState(false)
  const [activRelevantWind, setActivRelevantWind] = useState(false)
  const [activTypeMenu, setActivTypeMenu] = useState(false)
  const [activCountryMenu, setActivCountryMenu] = useState(false)
  const [activSectorMenu, setActivSectorMenu] = useState(false)
  const [activCompanyMenu, setActivCompanyMenu] = useState(false)

  // Эффект работы с DOM
  useEffect(() => {
    if (isMobile) {
      if (mobileFilterSwich) {
        document.body.style.overflow = "hidden"
      }
      if (!mobileFilterSwich) {
        document.body.style.overflow = ""
      }
    }
  }, [mobileFilterSwich])

  // Мобильный State
  const closeAllFilters = () => {
    setActivRelevantWind(false)
    setActivTypeMenu(false)
    setActivCountryMenu(false)
    setActivSectorMenu(false)
    setActivCompanyMenu(false)
    setMobileFilterSwitch(false)
  }

  const setFiltersPoint = useCallback(() => {
    if (!filters.mountedFiltersPoint.current || mounted.current) {
      filters.setCompanyForFilters(
        countryPointsForApi,
        sectorPointsForApi,
        categoryPointsForApi,
        selectAnalysisPoint,
        selectSubscriptionPoint,
        selectBondsPoint,
          selectChangePortfolios,
      )
    }
  }, [
    selectSectorPoint,
    selectCountryPoint,
    categoryPointsForApi,
    selectAnalysisPoint,
    selectSubscriptionPoint,
    selectBondsPoint,
    selectChangePortfolios,
    searchParams,
    countryPreference.popUpChoice,
  ])

  useEffect(() => {
    if (!mounted.current && !filters.hasUrl.current) {
      parseQuery()
    }
  }, [searchParams])

  useEffect(() => {
    parseCheckBox()
  }, [categoryPoints, countryPoints, sectorPoints, companyPoints])

  useEffect(() => {
    setUrl()
    setFiltersPoint()

    if (!mounted.current) {
      mounted.current = true
    }

    return () => {
      filters.mountedFiltersPoint.current = true
    }
  }, [
    selectSectorPoint,
    selectCountryPoint,
    categoryPointsForApi,
    selectAnalysisPoint,
    selectCompanyPoint,
    selectSubscriptionPoint,
    selectBondsPoint,
    selectChangePortfolios,
    countryPreference.popUpChoice,
  ])

  const stringUnderSectionMobile = (choices) => {
    const nameFromObj = choices.map((el) => el.name)

    if (!!choices[0].fullname) {
      return choices.map((el) => el.fullname).join(", ")
    }
    return nameFromObj.join(", ")
  }

  //Парсим чекбоксы из URL
  const parseCheckBox = useCallback(() => {
    const setParamsId = (params, points) => {
      const split = params.split(",").map((el) => Number(el)) // [1, 2, 7]
      const filter = points.filter((el) => split.some((id) => id === el.id))
      return filter.map((el) => ({
        id: el.id,
        name: el.fullname ? el.fullname : el.name,
      }))
    }

    const country = searchParams.get("country")
      ? setParamsId(searchParams.get("country"), countryPoints)
      : []

    const category = searchParams.get("category")
      ? setParamsId(searchParams.get("category"), categoryPoints)
      : []

    const selector = searchParams.get("selector")
      ? setParamsId(searchParams.get("selector"), sectorPoints)
      : []
    const company = searchParams.get("company")
      ? setParamsId(searchParams.get("company"), companyPoints)
      : []
    const checkbox = [...category, ...country, ...selector, ...company] // [{…}, {…}, {…}, {…}]
    dispatch(setCheckBoxChoice(checkbox))
  }, [categoryPoints, countryPoints, sectorPoints, companyPoints])

  // парсим наш url
  const parseQuery = useCallback(() => {
    const setParamsId = (params) => {
      return params.split(",").map((id) => ({ id: Number(id) }))
    }

    if (window.location.search) {
      const country = searchParams.get("country")
        ? setParamsId(searchParams.get("country"))
        : []

      const category = searchParams.get("category")
        ? setParamsId(searchParams.get("category"))
        : []

      const selector = searchParams.get("selector")
        ? setParamsId(searchParams.get("selector"))
        : []
      const company = searchParams.get("company")
        ? setParamsId(searchParams.get("company"))
        : []

      const unity = !!searchParams.get("unity") ? "&subscription=2,4,5" : ""
      const bonds = !!searchParams.get("bonds") ? "&bonds=true" : ""
      const portfolios = !!searchParams.get("change_in_portfolios") ? "&change_in_portfolios=true" : ""
      const analysis = !!searchParams.get("analysis") ? "&analysis=true" : ""
      dispatch(
        seturlParams({
          category,
          country,
          selector,
          company,
          unity,
          bonds,
          portfolios,
          analysis,
        })
      )
      dispatch(setCategoryPoints(category))
      dispatch(setCountryPoints(country))
      dispatch(setSectorPoints(selector))
      dispatch(setCompanyPoints(company))
      dispatch(setAnalysisPoints(analysis))
      dispatch(setSubscriptionPoints(unity))
      dispatch(setBondsPoints(bonds))
      dispatch(setChangePortfoliosPoints(portfolios))
      filters.hasUrl.current = true
    }
  }, [
    selectCategoryPoint,
    selectCountryPoint,
    selectSectorPoint,
    selectCompanyPoint,
    selectSubscriptionPoint,
    selectBondsPoint,
    selectChangePortfolios,
    selectAnalysisPoint,
  ])

  //создаем url
  const setUrl = useCallback(() => {
    const queryParameters = {}
    const pointsJoin = (points) => {
      return points.map((item) => item.id).join(",")
    }
    const categoryNames = pointsJoin(selectCategoryPoint)
    if (categoryNames) {
      queryParameters.category = categoryNames
    }

    const countryNames = pointsJoin(selectCountryPoint)
    if (countryNames) {
      queryParameters.country = countryNames
    }

    const selectorNames = pointsJoin(selectSectorPoint)
    if (selectorNames) {
      queryParameters.selector = selectorNames
    }

    const companyNames = pointsJoin(selectCompanyPoint)
    if (companyNames) {
      queryParameters.company = companyNames
    }

    if (selectSubscriptionPoint) {
      queryParameters.unity = 1
    }
    if (selectBondsPoint) {
      queryParameters.bonds = 1
    }
    if (selectChangePortfolios) {
      queryParameters.change_in_portfolios = true
    }

    if (selectAnalysisPoint) {
      queryParameters.analysis = 1
    }
    setSearchParams(queryParameters)
  }, [
    selectCategoryPoint,
    selectCountryPoint,
    selectSectorPoint,
    selectCompanyPoint,
    selectSubscriptionPoint,
    selectBondsPoint,
    selectChangePortfolios,
    selectAnalysisPoint,
  ])

  const singleOpener = useCallback((filterName) => {
    switch (filterName) {
      case "setActivTypeMenu":
        setActivTypeMenu((prev) => !prev)
        setActivCountryMenu(false)
        setActivSectorMenu(false)
        setActivCompanyMenu(false)

        break

      case "setActivCountryMenu":
        setActivCountryMenu((prev) => !prev)
        setActivTypeMenu(false)
        setActivSectorMenu(false)
        setActivCompanyMenu(false)

        break

      case "setActivSectorMenu":
        setActivSectorMenu((prev) => !prev)
        setActivTypeMenu(false)
        setActivCountryMenu(false)
        setActivCompanyMenu(false)
        break

      case "setActivCompanyMenu":
        setActivCompanyMenu((prev) => !prev)
        setActivSectorMenu(false)
        setActivTypeMenu(false)
        setActivCountryMenu(false)
    }
  }, [])

  return (
    <section className={styles.section}>
      <div className={styles.container}>
        <div className={styles.mainTxt}>Новости и аналитика</div>

        {!isMobile && (
          <SearchSectionFast
            selectRelevant={selectRelevant}
            setActivRelevantWind={setActivRelevantWind}
          />
        )}

        <div className={styles.sectionsWrapper}>
          <div className={styles.firstSection}>
            {/* Фильтры */}
            {!isMobile && (
              <div className={styles.filters}>
                {/* Кнопка - Тип */}
                <div className={styles.sectionFilterWrapper}>
                  <div
                    className={styles.typeBox}
                    onClick={() => {
                      singleOpener("setActivTypeMenu")
                    }}
                  >
                    <div className={styles.selectBoxWrapper}>
                      <div className={styles.txtFilter}>
                        Тип
                        {selectCategoryPoint.length !== 0 && (
                          <div className={styles.pointCount}>
                            {selectCategoryPoint.length}
                          </div>
                        )}
                      </div>
                      <img
                        alt="img"
                        src={arrowDownGray}
                        className={styles.arrowDownGray}
                      />

                    </div>
                  </div>

                  {/* Фильтры - Тип */}
                  <div className={styles.filtersSection}>
                    {activTypeMenu && (
                      <FilterSection
                        categoryPoints={categoryPoints}
                        selectCategoryPoint={selectCategoryPoint}
                        checkBoxChoice={checkBoxChoice}
                        selectCompanyPoint={selectCompanyPoint}
                      />
                    )}
                  </div>
                </div>

                {/* Кнопка - Страна */}
                <div className={styles.sectionFilterWrapper}>
                  <div
                    className={styles.typeBox}
                    onClick={() => {
                      singleOpener("setActivCountryMenu")
                    }}
                  >
                    <div className={styles.selectBoxWrapper}>
                      <div className={styles.txtFilter}>
                        <div className={styles.txt}>Страна</div>
                        {selectCountryPoint.length !== 0 && (
                          <div className={styles.pointCount}>
                            {selectCountryPoint.length}
                          </div>
                        )}
                      </div>

                      <img
                        alt="img"
                        src={arrowDownGray}
                        className={styles.arrowDownGray}
                      />
                    </div>
                  </div>

                  {/* Фильтры - Страна */}
                  <div
                    className={styles.filtersSection}
                  >
                    {activCountryMenu && (
                      <SectionCheckBoxPoints
                        countryPoints={countryPoints}
                        selectCountryPoint={selectCountryPoint}
                        checkBoxChoice={checkBoxChoice}
                        selectCompanyPoint={selectCompanyPoint}
                      />
                    )}
                  </div>
                </div>

                {/* Кнопка - Сектор */}
                <div className={styles.sectionFilterWrapper}>
                  <div
                    className={styles.typeBox}
                    onClick={() => {
                      singleOpener("setActivSectorMenu")
                    }}
                  >
                    <div className={styles.selectBoxWrapper}>
                      <div className={styles.txtFilter}>
                        <div className={styles.txt}>Сектор</div>
                        {selectSectorPoint.length !== 0 && (
                          <div className={styles.pointCount}>
                            {selectSectorPoint.length}
                          </div>
                        )}
                      </div>

                      <img
                        alt="img"
                        src={arrowDownGray}
                        className={styles.arrowDownGray}
                      />
                    </div>
                  </div>

                  {/* Фильтры - Сектор */}

                  <div
                    className={styles.filtersSection}
                  >
                    {activSectorMenu && (
                      <SectionSectorCheckBoxPoints
                        sectorPoints={sectorPoints}
                        checkBoxChoice={checkBoxChoice}
                        selectSectorPoint={selectSectorPoint}
                        selectCompanyPoint={selectCompanyPoint}
                      />
                    )}
                  </div>
                </div>

                {/* Кнопка - Компания */}
                <div className={styles.sectionFilterWrapper}>
                  <div
                    className={styles.typeBox}
                    onClick={() => {
                      singleOpener("setActivCompanyMenu")
                    }}
                  >
                    <div className={styles.selectBoxWrapper}>
                      <div className={styles.txtFilter}>
                        <div className={styles.txt}>Компания</div>
                        {selectCompanyPoint.length !== 0 && (
                          <div className={styles.pointCount}>
                            {selectCompanyPoint.length}
                          </div>
                        )}
                      </div>

                      <img
                        alt="img"
                        src={arrowDownGray}
                        className={styles.arrowDownGray}
                      />
                    </div>
                  </div>

                  {/* Фильтры - Компании */}
                  {activCompanyMenu && (
                    <div className={styles.filtersSection}>
                      {activCompanyMenu && (
                        <SectionCompanyCheckBoxPoints
                          companyPoints={companyPoints}
                          selectCompanyPoint={selectCompanyPoint}
                          checkBoxChoice={checkBoxChoice}
                          setActiveCompanyMenu={setActivCompanyMenu}
                        />
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}

            {/*  Мобильная версия */}

            {mobileFilterSwich && (
              <div className={styles.filters}>
                <div className={styles.mobileFilterTxt}>Фильтры</div>
                <div className={styles.filtersScrollWrapper}>
                  <div className={styles.sectionFilterWrapper}>
                    {selectCategoryPoint.length === 0 && (
                      <div
                        className={styles.typeBox}
                        onClick={() => {
                          setActivTypeMenu((prev) => !prev)
                        }}
                      >
                        <div className={styles.selectBoxWrapper}>
                          <div className={styles.txtFilter}>
                            <div className={styles.txt}>Тип</div>
                          </div>

                          <img
                            alt="img"
                            src={arrowDownGray}
                            className={styles.arrowDownGray}
                          />
                        </div>
                      </div>
                    )}

                    {selectCategoryPoint.length !== 0 && (
                      <div className={styles.typeBox}>
                        <div className={styles.mobileActiveBoxWrapper}>
                          <div
                            className={styles.noActiveContent}
                            onClick={() => {
                              setActivTypeMenu((prev) => !prev)
                            }}
                          >
                            <div className={styles.txtAct}>
                              <div className={styles.txtWrapper}>Тип</div>
                              <img
                                alt="img"
                                src={arrowDownGray}
                                className={styles.arrowDownGray}
                              />
                            </div>
                            <div className={styles.txtSmal}>
                              <div className={styles.stringUnderSection}>
                                {stringUnderSectionMobile(selectCategoryPoint)}
                              </div>
                            </div>
                          </div>
                          <div
                            className={styles.clearButton}
                            onClick={() => {
                              dispatch(setCategoryPoints([]))
                              dispatch(setSelectCategoryPoint([]))
                            }}
                          >
                            Сбросить
                          </div>
                        </div>
                      </div>
                    )}

                    <div className={styles.filtersSection}>
                      {activTypeMenu && (
                        <FilterSection
                          categoryPoints={categoryPoints}
                          setActivTypeMenu={setActivTypeMenu}
                          selectCategoryPoint={selectCategoryPoint}
                          checkBoxChoice={checkBoxChoice}
                          selectCompanyPoint={selectCompanyPoint}
                        />
                      )}
                    </div>
                  </div>

                  <div className={styles.sectionFilterWrapper}>
                    {selectCountryPoint.length === 0 && (
                      <div
                        className={styles.typeBox}
                        onClick={() => {
                          setActivCountryMenu((prev) => !prev)
                        }}
                      >
                        <div className={styles.selectBoxWrapper}>
                          <div className={styles.txtFilter}>
                            <div className={styles.txt}>Страна</div>
                          </div>

                          <img
                            alt="img"
                            src={arrowDownGray}
                            className={styles.arrowDownGray}
                          />
                        </div>
                      </div>
                    )}

                    {selectCountryPoint.length !== 0 && (
                      <div className={styles.typeBox}>
                        <div className={styles.mobileActiveBoxWrapper}>
                          <div
                            className={styles.noActiveContent}
                            onClick={() => {
                              setActivCountryMenu((prev) => !prev)
                            }}
                          >
                            <div className={styles.txtAct}>
                              <div className={styles.txtWrapper}>Страна</div>
                              <img
                                alt="img"
                                src={arrowDownGray}
                                className={styles.arrowDownGray}
                              />
                            </div>
                            <div className={styles.txtSmal}>
                              <div className={styles.stringUnderSection}>
                                {stringUnderSectionMobile(selectCountryPoint)}
                              </div>
                            </div>
                          </div>
                          <div
                            className={styles.clearButton}
                            onClick={() => {
                              dispatch(setCountryPoints([]))
                              dispatch(setSelectCountryPoint([]))
                              dispatch(setClearCompanyPoints())
                              dispatch(setClearSelectCompanyPoint())
                            }}
                          >
                            Сбросить
                          </div>
                        </div>
                      </div>
                    )}

                    <div className={styles.filtersSection}>
                      {activCountryMenu && (
                        <SectionCheckBoxPoints
                          countryPoints={countryPoints}
                          selectCountryPoint={selectCountryPoint}
                          checkBoxChoice={checkBoxChoice}
                          selectCompanyPoint={selectCompanyPoint}
                          setActivCountryMenu={setActivCountryMenu}
                        />
                      )}
                    </div>
                  </div>

                  <div className={styles.sectionFilterWrapper}>
                    {selectSectorPoint.length === 0 && (
                      <div
                        className={styles.typeBox}
                        onClick={() => {
                          setActivSectorMenu((prev) => !prev)
                        }}
                      >
                        <div className={styles.selectBoxWrapper}>
                          <div className={styles.txtFilter}>
                            <div className={styles.txt}>Сектор</div>
                          </div>

                          <img
                            alt="img"
                            src={arrowDownGray}
                            className={styles.arrowDownGray}
                          />
                        </div>
                      </div>
                    )}

                    {selectSectorPoint.length !== 0 && (
                      <div className={styles.typeBox}>
                        <div className={styles.mobileActiveBoxWrapper}>
                          <div
                            className={styles.noActiveContent}
                            onClick={() => {
                              setActivSectorMenu((prev) => !prev)
                            }}
                          >
                            <div className={styles.txtAct}>
                              <div className={styles.txtWrapper}>Сектор</div>
                              <img
                                alt="img"
                                src={arrowDownGray}
                                className={styles.arrowDownGray}
                              />
                            </div>
                            <div className={styles.txtSmal}>
                              <div className={styles.stringUnderSection}>
                                {stringUnderSectionMobile(selectSectorPoint)}
                              </div>
                            </div>
                          </div>
                          <div
                            className={styles.clearButton}
                            onClick={() => {
                              dispatch(setSectorPoints([]))
                              dispatch(setSelectSectorPoint([]))
                              dispatch(setClearCompanyPoints())
                              dispatch(setClearSelectCompanyPoint())
                            }}
                          >
                            Сбросить
                          </div>
                        </div>
                      </div>
                    )}

                    <div
                      className={styles.filtersSection}
                    >
                      {activSectorMenu && (
                        <SectionSectorCheckBoxPoints
                          sectorPoints={sectorPoints}
                          checkBoxChoice={checkBoxChoice}
                          selectSectorPoint={selectSectorPoint}
                          selectCompanyPoint={selectCompanyPoint}
                          setActivSectorMenu={setActivSectorMenu}
                        />
                      )}
                    </div>
                  </div>

                  <div className={styles.sectionFilterWrapper}>
                    {selectCompanyPoint.length === 0 && (
                      <div
                        className={styles.typeBox}
                        onClick={() => {
                          setActivCompanyMenu((prev) => !prev)
                        }}
                      >
                        <div className={styles.selectBoxWrapper}>
                          <div className={styles.txtFilter}>
                            <div className={styles.txt}>Компания</div>
                          </div>

                          <img
                            alt="img"
                            src={arrowDownGray}
                            className={styles.arrowDownGray}
                          />
                        </div>
                      </div>
                    )}

                    {selectCompanyPoint.length !== 0 && (
                      <div className={styles.typeBox}>
                        <div className={styles.mobileActiveBoxWrapper}>
                          <div
                            className={styles.noActiveContent}
                            onClick={() => {
                              setActivCompanyMenu((prev) => !prev)
                            }}
                          >
                            <div className={styles.txtAct}>
                              <div className={styles.txtWrapper}>Компания</div>
                              <img
                                alt="img"
                                src={arrowDownGray}
                                className={styles.arrowDownGray}
                              />
                            </div>
                            <div className={styles.txtSmal}>
                              <div className={styles.stringUnderSection}>
                                {stringUnderSectionMobile(selectCompanyPoint)}
                              </div>
                            </div>
                          </div>
                          <div
                            className={styles.clearButton}
                            onClick={() => {
                              dispatch(setClearCompanyPoints())
                              dispatch(setClearSelectCompanyPoint())
                            }}
                          >
                            Сбросить
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className={styles.filtersSection}>
                    {activCompanyMenu && (
                      <SectionCompanyCheckBoxPoints
                        setActiveCompanyMenu={setActivCompanyMenu}
                        companyPoints={companyPoints}
                        selectCompanyPoint={selectCompanyPoint}
                        checkBoxChoice={checkBoxChoice}
                      />
                    )}
                  </div>

                  <SearchSectionFast
                    selectRelevant={selectRelevant}
                    setActivRelevantWind={setActivRelevantWind}
                  />

                  <div className={styles.underSpaceToScroll} />
                </div>

                <div className={styles.aceptButtonWrapper}>
                  <div
                    className={styles.acceptButtonActive}
                    onClick={() => {
                      closeAllFilters()
                    }}
                  >
                    Отфильтровать
                  </div>
                </div>
                <div className={styles.closeImg}>
                  <img
                    alt="img"
                    src={closeXSign}
                    onClick={() => {
                      closeAllFilters()
                    }}
                  />
                </div>
              </div>
            )}

            {/* КНОПКА - Сначала популярные*/}
            <div className={styles.sectionFilterWrapperOrdering}>
              {isMobile && (
                <div className={styles.relevantSectionMobileWrapper}>
                  <div
                      className={styles.relevantSection}
                      onClick={() => {
                        setActivRelevantWind((prev) => !prev)
                      }}
                  >
                    <img
                        alt="img"
                        src={arrowImgPopular}
                        className={styles.arrowImgPopular}
                    />
                    <div className={styles.txtButtom}>{selectRelevant}</div>

                    <svg className={styles.arrowDownGreen} width="25" height="24" viewBox="0 0 25 24" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                      <path
                          d="M12.2246 15.775L18.7246 9.27498L17.6746 8.22498L12.2246 13.675L6.77461 8.22498L5.72461 9.27498L12.2246 15.775Z"
                          fill="#06813E"/>
                    </svg>

                  </div>
                </div>
              )}

              {/* Мобильная версия  */}
              {isMobile && (
                  <div>
                    <div
                        className={styles.before}
                        onClick={() => {
                          setMobileFilterSwitch((prev) => !prev)
                        }}
                    >
                    Фильтр
                  </div>
                  {checkBoxChoice.length === 0 &&
                  selectCategoryPoint.length === 0 ? (
                    <img
                      alt="img"
                      src={filterIcon}
                      className={styles.filterIcon}
                      onClick={() => {
                        setMobileFilterSwitch((prev) => !prev)
                      }}
                    />
                  ) : (
                    <img
                      alt="img"
                      src={filterUseIcon}
                      className={styles.filterIcon}
                      onClick={() => {
                        setMobileFilterSwitch((prev) => !prev)
                      }}
                    />
                  )}
                </div>
              )}
              {/* ОТКРЫТЫЕ ФИЛЬТРЫ - Сначала популярные позиционируется от */}
              <div className={styles.filtersSectionOrderingWrapper}>
                <div className={styles.filtersSectionOrdering}>
                  {activRelevantWind && (
                    <RelevantSection
                      setActivRelevantWind={setActivRelevantWind}
                      selectRelevant={selectRelevant}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className={styles.chackBoxChoise}>
            {!isMobile && (
              <ChackBoxChoiseSection
                checkBoxChoice={checkBoxChoice}
                selectCountryPoint={selectCountryPoint}
                selectSectorPoint={selectSectorPoint}
                selectCompanyPoint={selectCompanyPoint}
                selectCategoryPoint={selectCategoryPoint}
              />
            )}
          </div>

          {(activTypeMenu ||
            activCountryMenu ||
            activSectorMenu ||
            activCompanyMenu ||
            mobileFilterSwich ||
            activRelevantWind) && (
            <div
              className={styles.notActiveWindBut}
              onClick={() => {
                closeAllFilters()
              }}
            />
          )}
        </div>
      </div>
    </section>
  )
}
export default SearchSection
