import {useEffect, useState} from "react";
import MainSectionInfo from '../main-section-info/main-section-info';
import MainSectionAnalytics from '../main-section-analytics/main-section-analytics';
import MainSectionProduct from '../main-section-product/main-section-product';
import MainSectionQuestions from '../main-section-questions/main-section-questions';
import MainSectionEcosystem from '../main-section-ecosystem/main-section-ecosystem';
import SEO from '../../utils/SEO';
import SeoScriptMain from './seo-script';
import TenDaysPromocode from '../promocode-10days/promocode-tenDays';
import WindowPaySub from "../app-main-profile/window-pay-sub/window-pay-sub";
import ExteriorNotice from "../app-main-profile/popup-sections/pay-sub/exterior-notice";
import {Tariffs} from "../tariffs/tariffs";
import styles from './app-main.module.css';
// import MainSectionNews from '../main-section-news/main-section-news';
// import MainSectionLessons from '../main-section-lessons/main-section-lessons';

function AppMain() {
    const currentLink = new URL(window.location.href);
    const [showModal, setShowModal] = useState(!!currentLink.searchParams.get("subSelect"))
    const [showExteriorNotice, setShowExteriorNotice] = useState(false);
    const [subNameSelect, setSubNameSelect] = useState(currentLink.searchParams.get("subNameSelect"));
    const [subSelect, setSubSelect] = useState(currentLink.searchParams.get("subSelect"));
    const [defaultOpionMonth, setDefaultOpionMonth] = useState(+(currentLink.searchParams.get("optionMonth") || 0));

    useEffect(() => {
        const currentUrl = new URL(window.location.href);
        currentUrl.searchParams.delete("subSelect");
        currentUrl.searchParams.delete("subNameSelect");
        currentUrl.searchParams.delete("optionMonth");
        window.history.replaceState(null, null, currentUrl.toString());
    }, [])
//
    return (
        <main>
            <div className={styles.info}>
                <SEO
                    title="Invest Era: фундаментальная аналитика, инвест идеи, обучение"
                    description="Повышаем доходность на фондовом рынке, используя фундаментальный анализ. Разборы компаний, инвест идеи, обучение."
                    ogTitle="Invest Era: фундаментальная аналитика, инвест идеи, обучение"
                    ogDescription="Повышаем доходность на фондовом рынке, используя фундаментальный анализ. Разборы компаний, инвест идеи, обучение."
                    script={SeoScriptMain}
                />
                <MainSectionInfo/>
            </div>
            <div className={styles.box}>
                {showModal && (
                    <WindowPaySub
                        setShowExteriorNotice={setShowExteriorNotice}
                        subNameSelect={subNameSelect}
                        subSelect={subSelect}
                        setShowModal={setShowModal}
                        defaultOptionMonth={defaultOpionMonth}
                    />
                )}
                {showExteriorNotice && <ExteriorNotice setShowExteriorNotice={setShowExteriorNotice} />}
                <TenDaysPromocode/>
                {/*<MainSectionProduct/>*/}
                <Tariffs setShowModal={setShowModal}
                         setSubNameSelect={setSubNameSelect}
                         setSubSelect={setSubSelect}
                         setDefaultOptionMonth={setDefaultOpionMonth}
                />
                {/*<MainSectionNews />*/}
                <MainSectionAnalytics/>
                <MainSectionEcosystem/>
                {/*
        <MainSectionLessons />
        */}
                <MainSectionQuestions/>
            </div>
        </main>
    );
}

export default AppMain;
