import { useState, useRef, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import doorExit from '../../../images/door-exit-dark.svg';
import ISVG from '../../../images/I.svg';
import PSVG from '../../../images/P.svg';
import USVG from '../../../images/U.svg';
import CloseX from '../../../images/close-black-icon.svg';
import fullCircle from '../../../images/full-black-green-circle.svg';
import Preloader from '../../preloader/preloader';
import { useProfile } from '../../../services/context/ProfileContext';

import {
  FirstBlockUnityRussia,
  SecodnBlockUnityRussia,
  ThirdBlockUnityRussia,
  FourthBlockUnityRussia,
  FifthBlockUnityRussia,
} from './components/unity-russia-sections/index';
import {
  FirstBlockUnityWorld,
  SecodnBlockUnityWorld,
  ThirdBlockUnityWorld,
  FourthBlockUnityWorld,
  FifthBlockUnityWorld,
} from './components/unity-world-sections/index';
import {
  FirstBlockUnityAll,
  SecodnBlockUnityAll,
  ThirdBlockUnityAll,
  FourthBlockUnityAll,
  FifthBlockUnityAll,
} from './components/unity-all-sections/index';
import {
  FirstBlockIdeasAll,
  SecondBlockIdeasAll,
  ThirdBlockIdeasAll,
} from './components/ideas-all-sections/index';
import {
  FirstBlockIdeasRussia,
  SecondBlockIdeasRussia,
  ThirdBlockIdeasRussia,
} from './components/ideas-russia-sections';
import {
  FirstBlockIdeasWorld,
  SecondBlockIdeasWorld,
  ThirdBlockIdeasWorld,
} from './components/ideas-world-sections';
import {
  FirstBlockPremium,
  SecondBlockPremium,
  ThirdBlockPremium,
} from './components/premium-sections';

import styles from './noAuthProfile.module.css';
import stylesSubs from './modalSubs.module.css';
import Stocks from '../../stocks/stock-profile/Stocks';
import { BreadcrumbNavigation } from '../../breadcrumb-navigation/breadcrumbsNavigation';
import {useTheme} from "../../../services/hooks/useTheme";

const NoAuthProfile = () => {
  const [activeItem, setActiveItem] = useState('');
  const [activeName, setActiveName] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [openModalSubs, setOpenModalSubs] = useState(false);
  const contextValue = useProfile();

  function buySubscription(item) {
    setActiveItem(item.slug);
    setActiveName(item.name);
    setOpenModal(true);
  }

  function InfoSubscription(item) {
    setActiveItem(item.slug);
    setActiveName(item.name);
    setOpenModalSubs(true);
  }

  useEffect(() => {
    if (openModal || openModalSubs) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }, [openModal, openModalSubs]);

  useEffect(() => {
    contextValue.userSubscriptionData();
  }, []);

  return (
    <>
      {openModal && (
        <View activeItem={activeItem} setOpenModal={setOpenModal} activeName={activeName} />
      )}
      {openModalSubs && (
        <ViewModalSubs setOpenModalSubs={setOpenModalSubs} activeName={activeName} />
      )}
      <div className={styles.noAuthProfile}>
        <div className={styles.header}>
          <div className={styles.headerWrapper}>
            <BreadcrumbNavigation forBg='dark' links={[{label: "Главная", url: "/"}]} withMarginBottom/>
            <h1 className={styles.title}>
              Авторизируйтесь для <br /> управления подписками
            </h1>
            <NavLink to={'/authorization'} className={styles.profile}>
              <div className={styles.doorExitWrapper}>
                <img src={doorExit} className={styles.doorExit} alt="doorExit" />
              </div>
              Войти через почту{' '}
            </NavLink>
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.contentWrapper}>
            <div className={styles.subTitle}>Все подписки</div>
            <div className={styles.wrapper}>
              {contextValue.userSubInfo.length === 0 && (
                <div className={styles.preloader}>
                  <Preloader />
                </div>
              )}
              {contextValue.userSubInfo?.map((item) => (
                <div className={styles.itemCard} key={item.id}>
                  {/* <img className={styles.cardImg} src={item.img} alt="img" /> */}
                  <div className={styles.cardTitle}>{item.name}</div>

                  <ul className={styles.cardList}>
                    {item.tags.map((tag, i) => (
                      <li className={styles.cardListItem} key={i}>
                        {tag}
                      </li>
                    ))}
                  </ul>
                  <div>
                  <button onClick={() => buySubscription(item)} className={styles.cardBtn}>
                    Подключить от {item?.next_payment_amount} ₽ / мес{' '}
                  </button>
                  <button onClick={() => InfoSubscription(item)} className={styles.cardLink}>
                    Подробнее о подписке
                  </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NoAuthProfile;

const View = ({ activeItem, setOpenModal, activeName }) => {
  const [active, setActive] = useState('1');

  const modalRef = useRef(null);
  const contextValue = useProfile();

  const handleClickOutside = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      setOpenModal(false);
    }
  };

  useEffect(() => {
    contextValue.receptData(activeItem);
  }, [activeItem]);

  return (
    <div onClick={handleClickOutside} className={styles.modalWrapper}>
      <div className={styles.modal} ref={modalRef}>
        <div>
          <div className={styles.upWrapper}>
            <div className={styles.modalTitle}>Подключение подписки {activeName}</div>

            <div onClick={() => setOpenModal(false)} className={styles.modalClose}>
              <img src={CloseX} alt="close" />
            </div>
          </div>
          <hr className={styles.modalLine} />
          <Stocks />
          <div className={styles.modalSubtitle}>Выберите период подключения</div>

          <div className={styles.modalItemWrapper}>
            {contextValue.receptInfo &&
              contextValue.receptInfo?.map((item, _, arr) => (
                <div
                  key={item.id}
                  onClick={() => setActive(item.id)}
                  style={active === item.id ? { border: '2px solid rgb(6, 129, 62)' } : null}
                  className={styles.modalpriceItem}>
                  <div className={styles.modalpriceItemUp}>
                    <div
                      style={item.discount ? { display: 'block' } : { display: 'none' }}
                      className={styles.modalPercent}>
                      {item.discount}%
                    </div>
                    <div className={styles.modalCheck}>
                      <img
                        style={active === item.id ? { display: 'block' } : { display: 'none' }}
                        src={fullCircle}
                        alt="circle"
                      />
                    </div>
                    <div className={styles.modalMonth}>
                      {+item.month <= 1 ? `${item.month} месяц` : `${item.month} месяцев`}{' '}
                    </div>
                  </div>
                  <div className={styles.modalpriceItemDown}>
                    {item.roubles_per_month} ₽ /{' '}
                    <span className={styles.modalPriceText}>месяц</span>
                  </div>
                  <div
                    style={item.discount ? { display: 'block' } : { display: 'none' }}
                    className={styles.modalOtherText}>
                    При оплате за {item.month} месяцев *Далее {arr[0].roubles_per_month} ₽ / месяц
                  </div>
                </div>
              ))}
          </div>
          <div className={styles.modalDescr}>* Сумма будет списываться автоматичекси</div>
        </div>
        <div className={styles.modalDownContainer}>
          <div className={styles.modalDownContainerText}>
            Авторизируйтесь, чтобы приобрести подписку или использовать промокод
          </div>
          <NavLink to={'/authorization'} className={styles.ModalProfile}>
            <div className={styles.doorExitWrapper}>
              <img src={doorExit} className={styles.doorExit} alt="doorExit" />
            </div>
            Войти через почту{' '}
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export const ViewModalSubs = ({ activeName, setOpenModalSubs }) => {
  const modalRef = useRef(null);
  const unityRussia = (
    <div className={stylesSubs.flexWrap}>
      <FirstBlockUnityRussia />
      <SecodnBlockUnityRussia />
      <ThirdBlockUnityRussia />
      <FourthBlockUnityRussia />
    </div>
  );
  const unityWorld = (
    <div className={stylesSubs.flexWrap}>
      <FirstBlockUnityWorld />
      <SecodnBlockUnityWorld />
      <ThirdBlockUnityWorld />
      <FourthBlockUnityWorld />
    </div>
  );
  const unityAll = (
    <div className={stylesSubs.flexWrap}>
      <FirstBlockUnityAll />
      <SecodnBlockUnityAll />
      <ThirdBlockUnityAll />
      <FourthBlockUnityAll />
    </div>
  );
  const ideasAll = (
    <div className={stylesSubs.flexWrap}>
      <FirstBlockIdeasAll />
      <SecondBlockIdeasAll />
      <ThirdBlockIdeasAll />
    </div>
  );
  const ideasRussia = (
    <div className={stylesSubs.flexWrap}>
      <FirstBlockIdeasRussia />
      <SecondBlockIdeasRussia />
      <ThirdBlockIdeasRussia />
    </div>
  );
  const ideasWorld = (
    <div className={stylesSubs.flexWrap}>
      <FirstBlockIdeasWorld />
      <SecondBlockIdeasWorld />
      <ThirdBlockIdeasWorld />
    </div>
  );
  const premium = (
    <div className={stylesSubs.flexWrap}>
      <FirstBlockPremium />
      <SecondBlockPremium />
      <ThirdBlockPremium />
    </div>
  );

  const handleClickOutside = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      setOpenModalSubs(false);
    }
  };

  return (
    <div onClick={handleClickOutside} className={stylesSubs.modalWrapper}>
      <div className={stylesSubs.modalSubs} ref={modalRef}>
        <div onClick={() => setOpenModalSubs(false)} className={stylesSubs.modalClose}>
          <img src={CloseX} alt="close" />
        </div>
        <h2 className={stylesSubs.title}>Подписка {activeName}</h2>
        <h4 className={stylesSubs.subtitle}>
          Научим самостоятельно инвестировать и поможем повысить доходность
        </h4>
        <div className={stylesSubs.wrapperElement}>
          {activeName === 'Unity Россия' ? unityRussia : null}
          {activeName === 'Unity World' ? unityWorld : null}
          {activeName === 'Unity All' ? unityAll : null}
          {activeName === 'Ideas All' ? ideasAll : null}
          {activeName === 'Ideas Россия' ? ideasRussia : null}
          {activeName === 'Ideas World' ? ideasWorld : null}
          {activeName === 'Premium' ? premium : null}
        </div>
      </div>
    </div>
  );
};
