import {useEffect, useState} from "react";
import {getMapMarket} from "../../../utils/api";
import {transformData} from "../utilsForMarketMap";

export function useTreeMap1Data({isInitialized, selectedIndex, selectedRange, selectedSector}) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isInitialized) {
      setLoading(true)
      const index = selectedIndex.id === 'iMOEX' || selectedIndex.id === 'iMOEX+IE' ? 'all' : selectedIndex.id
      const iMOEX = selectedIndex.id === 'iMOEX' || selectedIndex.id === 'iMOEX+IE'
      const iMOEXplusIE = selectedIndex.id === 'iMOEX+IE'

      getMapMarket(selectedRange.id, iMOEXplusIE, iMOEX, index, selectedSector.id).then(async (res) => {
        if (res.ok) {
          const data = await res.json()
          setData(transformData(data.sectors))
          setLoading(false)
        } else {
          setLoading(false)
          console.log(res)
        }
      })
    }

  }, [selectedRange, selectedSector, selectedIndex, isInitialized]);

  return {data, loading};
}