import React from 'react';
import styles from "./window-pay.module.css";
import {useMediaQuery} from "react-responsive";
import {useProfile} from "../../../services/context/ProfileContext";

export const FooterPayContent = ({loading, setLoading, optionMonth}) => {
  const isMobile = useMediaQuery({ query: '(max-width: 760px)' });
  const contextValue = useProfile();

  return (
    <>
      <div className={styles.footerContent}>
        {!isMobile && contextValue.receptInfo && optionMonth ? (
          contextValue?.promoCodeData?.promo_code_id === 12 ? (
            <div className={styles.footerTitleWrapper}>
              <div style={{color: '#047337'}} className={styles.titleHeader}>
                {/*К оплате: {`${contextValue.receptInfo[optionMonth].roubles} ₽`}*/}
                К оплате: 10 ₽
              </div>
              <div className={styles.titleBox}>10 ₽ / 10 дней</div>
            </div>
          ) : contextValue.totalRubles === contextValue.receptInfo[optionMonth].roubles ||
          contextValue.totalRubles === '' ? (
            <div className={styles.footerTitleWrapper}>
              <div style={{color: '#047337'}} className={styles.titleHeader}>
                К оплате: {`${contextValue.receptInfo[optionMonth].roubles} ₽`}
              </div>
              <div className={styles.titleBox}>
                {`${
                  contextValue.bonusMonth === ''
                    ? Math.ceil(
                      contextValue.receptInfo[optionMonth].roubles /
                      contextValue.receptInfo[optionMonth].month
                    )
                    : Math.ceil(
                      contextValue.totalRubles /
                      (contextValue.receptInfo[optionMonth].month +
                        contextValue.bonusMonth),
                    )
                } ₽`}
                / месяц
              </div>
            </div>
          ) : (
            <div className={styles.footerTitleWrapper}>
              <div style={{color: '#047337'}} className={styles.titleHeader}>
                {`К оплате: ${contextValue.totalRubles} ₽`}
                <div style={{'padding-left': '8px'}} className={styles.titleHeaderCross}>
                  {`${contextValue.receptInfo[optionMonth].roubles} ₽`}
                </div>
              </div>
              <div className={styles.footerSobPriceWrapper}>
                <div className={styles.titleBox}>
                  {`${
                    contextValue.totalRubles / contextValue.receptInfo[optionMonth].month
                  } ₽`}
                  / месяц
                </div>
                <div className={styles.titleBoxCross}>
                  {`${
                    contextValue.receptInfo[optionMonth].roubles /
                    contextValue.receptInfo[optionMonth].month
                  } ₽`}
                  / месяц
                </div>
              </div>
            </div>
          )
        ) : null}

        {isMobile && contextValue.receptInfo && optionMonth ? (
          contextValue?.promoCodeData?.promo_code_id === 12 ? (
            <div
              className={styles.selectPayButton}
              onClick={() => {
                setLoading((prev) => !prev);
              }}>
              <div className={styles.buttonMonthPayTitleMob}>10 ₽ / 10 дней</div>
              <div className={styles.buttonPayTitleMob}>
                {/*К оплате: {contextValue.receptInfo[optionMonth].roubles}₽*/}
                К оплате: 10 ₽
              </div>
            </div>
          ) : contextValue.totalRubles === contextValue.receptInfo[optionMonth].roubles ||
          contextValue.totalRubles === '' ? (
            <div
              className={styles.selectPayButton}
              onClick={() => {
                setLoading((prev) => !prev);
              }}>
              {/* <div className={styles.buttonMonthPayTitleMob}>
                {contextValue.bonusMonth === ''
                  ? Math.ceil(
                    contextValue.receptInfo[optionMonth].roubles /
                    contextValue.receptInfo[optionMonth].month,
                  )
                  : Math.ceil(
                    contextValue.totalRubles /
                    (contextValue.receptInfo[optionMonth].month +
                      contextValue.bonusMonth),
                  )}{' '}
                ₽ / мес
              </div> */}
              <div className={styles.buttonPayTitleMob}>
                К оплате: {contextValue.receptInfo[optionMonth].roubles}₽
              </div>
            </div>
          ) : (
            <div
              className={styles.selectPayButton}
              onClick={() => {
                setLoading((prev) => !prev);
              }}>
              <div className={styles.buttonMonthPayTitleMob}>
                {contextValue.totalRubles / contextValue.receptInfo[optionMonth].month} ₽ /
                мес
              </div>
              <div className={styles.buttonPayTitleMob}>
                К оплате: {contextValue.receptInfo[optionMonth].roubles}₽
              </div>
            </div>
          )
        ) : (
          <div
            className={styles.selectPayButton}
            onClick={() => {
              setLoading((prev) => !prev);
            }}>
            Оплатить
          </div>
        )}
      </div>
      <div className={styles.politicWrapper}>
        <div className={styles.politicTxt}>
          Нажимая на «Оплатить», я соглашаюсь с условиями{' '}
          <a href={'https://invest-era.ru/info/politika-konfidentsialnosti'}>
            Политики конфиденциальности
          </a>{' '}
          и{' '}
          <a
            href={
              'https://invest-era.ru/info/dogovor-publichnoj-oferti-na-okazanie-informatsionno-konsultatsionnikh-uslug'
            }>
            Публичной оферты
          </a>
        </div>
      </div>
    </>
  );
};