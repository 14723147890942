import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {activationWithCode, getUserInterestAPI, login, setYmId} from "../../../services/actions/user";
import {isClickEnter} from "../../../services/reducers/modal-slice";
import {setAuthChecked} from "../../../services/reducers/user-slice"
import {useInput} from "./hookUseInput"
import styles from "../login-page/login-page.module.css";
import Preloader from "../../app-authorization/preloader-authorization/preloader-auth";

function ActivationPage({returnUrl}) {
    const email = useSelector(state => state.user.email);
    const dispatch = useDispatch();
    const navigation = useNavigate()
    const {bind, val} = useInput("")
    const [attempts, setAttempts] = useState(10);
    const [errorMessage, setErrorMessage] = useState("");
    const [seconds, setSeconds] = useState(120);
    const [timerActive, setTimerActive] = useState(true);
    const [loading, setLoading] = useState(false);

    const resetTimer = () => {
        dispatch(login(email))
        setSeconds(120);
        setTimerActive(true);
    };

    const formatTime = () => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    };

    const formateSubscription = (sub) => {

        const subVal = sub.map((el) => {
            if (el.subscription === "Ideas All") {
                return [el, {subscription: "Ideas World"}, {subscription: "Ideas Россия"}]
            } else if (el.subscription === "Unity All") {
                return [el, {subscription: "Unity World"}, {subscription: "Unity Россия"}]
            } else if (el.subscription === "Premium") {
                return [el, {subscription: "Unity World"}, {subscription: "Unity Россия"}, {subscription: "Unity All"}]
            } else {
                return el
            }
        })

        const flatSubVal = subVal.flat();

        const uniqueSubscriptions = flatSubVal.filter((item, index, self) => {
            return index === self.findIndex((el) => el.subscription === item.subscription);
        });

        return uniqueSubscriptions
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') event.preventDefault();
    };

    const onClick = () => {
        dispatch(isClickEnter(false))
    }


    useEffect(() => {
        let interval = null;
        if (timerActive && seconds > 0) {
            interval = setInterval(() => {
                setSeconds(seconds => seconds - 1);
            }, 1000);
        } else if (!timerActive || seconds === 0) {
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [timerActive, seconds]);

    useEffect(() => {
        const userAgent = window.navigator.userAgent.toLowerCase();
        const isFirefox = /mozilla/.test(userAgent) || /firefox/.test(userAgent); // Вычисляет используется ли mozilla

        if (val.length === 6) {
            const deviceId = isFirefox ? '' : window.amplitude.getDeviceId();
            setLoading(true)

            activationWithCode(val, email, deviceId)
                .then((res) => {
                    return res.json()
                })
                .then((res) => {
                    if (res.error) {
                        throw new Error(res.error)
                    }
                    localStorage.setItem("token", res.token)

                    const storageObj = JSON.stringify(formateSubscription(res.subscription))
                    localStorage.setItem("subscription", storageObj)
                    dispatch(setAuthChecked(true))
                    getUserInterestAPI()
                    if (!!window.ym) {
                        // setYmId(parseInt(localStorage.getItem("_ym_uid").slice(1, -1)))
                        let ymId = localStorage.getItem("_ym_uid");
                        let ymIdForStr = ymId.replace(/"/g, '');
                        setYmId(ymIdForStr);
                    }
                    setErrorMessage("");

                    // Отправляем событие "authorized" в Amplitude (если используется не mozilla)
                    !isFirefox && window.amplitude.logEvent('authorized');

                    if (localStorage.getItem("location")) {
                        navigation(localStorage.getItem("location"))
                    } else {
                        navigation(returnUrl)
                    }
                    return res
                })
                .catch((e) => {
                    if (e.message === 'Вы временно заблокированы' || e.message === 'Вы временно заблокированы на сутки'
                    ) {
                        setErrorMessage(e.message + '. Пожалуйста, попробуйте позже.')
                    } else if (e.message === "Код авторизации истек") {
                        setErrorMessage(e.message + '. Пожалуйста, попробуйте заново.')
                    } else {
                        if (attempts > 1) {
                            setAttempts(attempts - 1);
                            setErrorMessage(`Неверный код. Осталось попыток: ${attempts - 1}`);
                        } else if (attempts === 1) {
                            setErrorMessage("Все попытки исчерпаны. Пожалуйста, попробуйте позже.");
                        }
                    }
                })
                .finally(()=>{
                    setLoading(false)
                })
        }
    }, [val])

    return (
        <section className={styles.section}>
            {loading && <Preloader/>}
            <div className={styles.titleBox}>

                <div className={styles.h3}>Введите код</div>
                <div className={styles.subTitleWrapper}>
                    <span className={styles.titleSubTxt}>Отправили код подтверждения на почту <b>{email}</b></span>
                    <span className={styles.titleSubTxt}>Если письмо не пришло — проверьте папку спам</span>
                </div>

            </div>
            <div className={styles.inputBoxWrapper}>

                <form className={styles.inputBox}>
                    <input
                        inputMode="numeric"
                        {...bind}
                        className={`${styles.input} ${errorMessage.length !== 0 && styles.errorInput}`}
                        placeholder={'Код'}
                        onKeyDown={handleKeyDown}
                        required/>

                    <span className={styles.errorText}>{errorMessage}</span>
                </form>

                <span className={styles.text}>{timerActive && seconds > 0 ?
                    `Отправим код повторно через ${formatTime()} секунд` :
                    <button className={styles.buttonReset} onClick={resetTimer}>
                        <span className={styles.textBtn}>Отправить новый код</span>
                    </button>}
                    </span>

                <span style={{"color": "#323A47"}} className={styles.textBtn}
                      onClick={onClick}>Другой адрес почты</span>

            </div>

        </section>
    )
}

export default ActivationPage