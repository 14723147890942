import styles from "./PopUpSection.module.css"
import checkBoxEmpty from "../../images/check-box-circle-empty.svg"
import checkBoxFull from "../../images/check-box-circle-full.svg"
import helpImg from "../../images/helper-image.png"
import helpImgMob from "../../images/helper-image-mob.png"
import { useCallback, useState } from "react"
import { useActiveButtonCountryTagChoice } from "../../services/context/CountryFilterContext"
import closeXSign from "../../images/close-x-sign-svg.svg"
import leftLine from "../../images/popup-left-down-liner.svg"
import rightLine from "../../images/popup-right-down-liner.svg"
import { useDispatch } from "react-redux"
import { setClearAllFiltersCard } from "../../services/reducers/cards-filtred-slice"
import { setClearAllVisionSection } from "../../services/reducers/search-vision-points-slice"
import { useMediaQuery } from "react-responsive"
import { userInterestAPI } from "../../services/actions/user"
import {useTheme} from "../../services/hooks/useTheme";
const PopUpSection = () => {
  const dispatch = useDispatch()
  const activeButtonCountryTagChoice = useActiveButtonCountryTagChoice()
  const [countryChoice, setCountryChoice] = useState(localStorage.getItem("country_tag"))
  const [isCountrySelected, setIsCountrySelected] = useState(false)
  const {theme} = useTheme();
  const isMobile = useMediaQuery({ query: "(max-width: 760px)" })
  const intObj = {
    "?country_tag=-1": "international",
    "?country_tag=1": "Russia",
  }
  const setApiInterests = (selectValue) => {
    return selectValue === "" ? "null" : intObj[selectValue]
  }
  const setupParams = useCallback(
    (selectValue) => {
      localStorage.setItem("country_tag", selectValue)
      activeButtonCountryTagChoice.mountedFilters.current = false
      dispatch(setClearAllFiltersCard())
      dispatch(setClearAllVisionSection())
      activeButtonCountryTagChoice.toggleCountry(selectValue)
      userInterestAPI(setApiInterests(selectValue))
    },
    [countryChoice, countryChoice]
  )

  // console.log(selectValue, "!!!")

  const handleClick = useCallback((event) => {
    if (event.target === event.currentTarget) {
      // Обработчик вызывается только если событие произошло на родительском элементе
      activeButtonCountryTagChoice.toggleVisible(false)
      if (!countryChoice) {setupParams("")}

    }
  }, [])

  const handleCountryChoise = country => e => {
    setCountryChoice(country);
    localStorage.setItem("country_tag", country);
  }


  return (
    <div className={styles.containerWrapper} onClick={handleClick}>
      {!isCountrySelected && <div className={`${styles.container} ${theme}`}>
        <div className={styles.columnBody}>
          <div className={styles.closeImg}>
            <img
              src={closeXSign}
              onClick={handleClick}
            />
          </div>
          <div className={styles.topPart}>
            <div className={styles.title}>Какие рынки Вам интересны?</div>
            <div className={styles.checkBoxWrapper}>
              <div
                className={styles.checkbox}
                onClick={handleCountryChoise("?country_tag=1")}
              >
                {countryChoice === "?country_tag=1" ? (
                  <img src={checkBoxFull} className={styles.circle} />
                ) : (
                  <img src={checkBoxEmpty} className={styles.circle} />
                )}
                Российские
              </div>
              <div
                className={styles.checkbox}
                onClick={handleCountryChoise("?country_tag=-1")}
              >
                {countryChoice === "?country_tag=-1" ? (
                  <img src={checkBoxFull} className={styles.circle} />
                ) : (
                  <img src={checkBoxEmpty} className={styles.circle} />
                )}
                Иностранные
              </div>
              <div
                className={styles.checkbox}
                onClick={handleCountryChoise("")}
              >
                {countryChoice === "" ? (
                  <img src={checkBoxFull} className={styles.circle} />
                ) : (
                  <img src={checkBoxEmpty} className={styles.circle} />
                )}
                Мне интересно всё
              </div>
            </div>
          </div>

          <div className={styles.dottomPart}>
            <img className={styles.pointLine} alt="img" src={leftLine} />
            {countryChoice !== null ? (
              <div
                className={styles.buttonWrapper}
                onClick={() => {
                  setIsCountrySelected(true)
                  setupParams(countryChoice)
                }}
              >
                Выбрать
              </div>
            ) : (
              <div className={styles.buttonWrapperNoActive}>Выбрать</div>
            )}
          </div>
        </div>
      </div>}

      {isCountrySelected && (
        <div className={styles.finalContainer}>
          <div className={styles.columnBody}>
            <div className={styles.closeImg}>
              <img
                src={closeXSign}
                onClick={() => {
                  activeButtonCountryTagChoice.toggleVisible(false)
                }}
              />
            </div>
            <div className={styles.topPart}>
              <div className={styles.title}>
                Вы всегда можете изменить свой выбор в шапке сайта
              </div>
              <div className={styles.imgWrapper}>
                {!isMobile ? <img src={helpImg} /> : <img src={helpImgMob} />}
              </div>
            </div>

            <div className={styles.dottomPart}>
              <img className={styles.pointLine} alt="img" src={rightLine} />

              <div
                className={styles.buttonWrapper}
                onClick={() => {
                  activeButtonCountryTagChoice.toggleVisible(false)
                }}
              >
                Хорошо
              </div>
            </div>
          </div>
        </div>
      )}

    </div>
  )
}
export default PopUpSection
