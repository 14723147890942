import { configureStore } from '@reduxjs/toolkit';

import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import modalReducer from './reducers/modal-slice';
import userReducer from './reducers/user-slice';
import singleCardReducer from './reducers/single-card-slice';
import preloaderReducer from './reducers/preloader-slice';
import cardsReducer from './reducers/cards-slice';
import cardsFiltredReduser from './reducers/cards-filtred-slice';
import cardsFilteredForGalleryReducer from './reducers/cards-filtred-for-gallery-slice';
import cardsFiltredForCheckboxPointsSlice from './reducers/cards-filtred-for-checkbox-points-slice';
import cardsMainNewReduser from './reducers/cards-main-new-slice';
import userDataReduser from './reducers/subscription-data-slice';
import cardsMainAnalyticsReduser from './reducers/cards-main-analytics-slice';
import buttonDisabledReducer from './reducers/button-disabled-slice';
import errorReducer from './reducers/error-slice';
import checkboxesReducer from './reducers/checkboxes-slice';
import inputTextReducer from './reducers/input-text-slice';
import openedCheckboxesReducer from './reducers/opened-checkboxes-slice';
import pagesReducer from './reducers/pages-slice';
import selectedItemReducer from './reducers/selectedItem-slice';
import recommendedPostsReducer from './reducers/recommendedPosts-slice';
import countDataReducer from './reducers/count-data-slice';
import valueDataFilterReducer from './reducers/value-data-filter-slice';
import nextUrlReducer from './reducers/next-url-slice';
import tablesReducer from './reducers/tables-slice';
import searchSectionVisionReducer from './reducers/search-vision-points-slice';
import countryInfo from './reducers/infoCountry/countryInfo';
import timerSlice from './reducers/timer'
import {screenerReducer} from "./reducers/screener-slice";
import {blogReducer} from "./reducers/blog-slice";
import {shareListReducer} from "./reducers/share-list-slice";

// const persistConfig = {
//     key: 'root',
//     storage,
//     whitelist: ['cards']
// };
//
// const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: {
    modal: modalReducer,
    user: userReducer,
    singleCard: singleCardReducer,
    preloader: preloaderReducer,
    cards: cardsReducer,
    cardsMainNew: cardsMainNewReduser,
    userData: userDataReduser,
    cardsFiltred: cardsFiltredReduser,
    cardsFiltredForGallery: cardsFilteredForGalleryReducer,
    searchSectionVision: searchSectionVisionReducer,
    checkBoxPoints: cardsFiltredForCheckboxPointsSlice,
    cardsMainAnalytics: cardsMainAnalyticsReduser,
    buttonDisabled: buttonDisabledReducer,
    error: errorReducer,
    checkboxes: checkboxesReducer,
    inputText: inputTextReducer,
    openedCheckboxes: openedCheckboxesReducer,
    pages: pagesReducer,
    selectedItems: selectedItemReducer,
    recommendedPosts: recommendedPostsReducer,
    countData: countDataReducer,
    valueDataFilter: valueDataFilterReducer,
    nextUrl: nextUrlReducer,
    tables: tablesReducer,
    countryInfo: countryInfo,
    timerSlice: timerSlice,
    screener: screenerReducer,
    blog: blogReducer,
    shareList: shareListReducer
  },
});
