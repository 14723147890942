import { Popover } from 'antd';
import React, { useState } from 'react';

/**
 * MobilePopover component that wraps Popover from Ant Design.
 *
 * @param {React.ReactNode} children - The content to be displayed within the popover.
 * @param {import('antd').PopoverProps} otherProps - The rest of the props are inherited from the Ant Design Popover component.
 */
export const MobilePopover = ({ children, ...otherProps }) => {
  const [visible, setVisible] = useState(false);

  const handleVisibleChange = (newVisible) => {
    setVisible(newVisible);
  };

  return (
    <Popover
      content={children}
      trigger="click"
      open={visible}
      onOpenChange={handleVisibleChange} // используем правильный обработчик
      arrow={false}
      {...otherProps}
    >
      <div onClick={() => setVisible(!visible)}>
        {children}
      </div>
    </Popover>
  );
};
