import React from 'react';
import { Link } from 'react-router-dom';
import GreenMoney from '../../images/greenMoneyBg.png';

import styles from './promocode-tenDays.module.css';
import {ScrollToHash} from "../scroll-to-hash/scroll-to-hash";

const TenDaysPromocode = () => {
  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        window.ym(96742836, 'reachGoal', 'main-start');
        console.log('Copied to clipboard!');
      })
      .catch((err) => {
        console.error('Failed to copy: ', err);
      });
  };

  return (
    <div className={styles.promocode} id="promo">
      <ScrollToHash />
      <div className={styles.container}>
        <img className={styles.bg} src={GreenMoney} alt="bg" />
        <div className={styles.left}>
          <div className={styles.descHead}>Пробная подписка </div>
          <div className={styles.title}>10 дней за 10 рублей</div>
{/*          <div className={styles.descFoot}>
            Сформируйте портфель<br/>выше рынка с нами
          </div>*/}
        </div>
        <div className={styles.right}>
          <div className={styles.text}>
            По промокоду START для новых пользователей*
          </div>
          <Link to="/profile" onClick={() => copyToClipboard('START')} className={styles.btn}>
            Оформить
          </Link>
          <a href="" rel="noreferrer noopener"></a>
          <div className={styles.info}>
            *По истечении 10 дней, продление подписки происходит автоматически, в соответствии с ценой, указанной на сайте invest-era.ru.
          </div>
        </div>
      </div>
    </div>
  );
};

export default TenDaysPromocode;
