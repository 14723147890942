import {memo, useEffect, useState} from 'react';
import {allSubscriptions, premiumSubscription, russianSubscriptions, worldSubscriptions} from "./Plans";
import {TariffsItem} from "./tariffs-item/tariffs-item";
import {ScrollToHash} from "../scroll-to-hash/scroll-to-hash";
import styles from './tariffs.module.css';
import {useResize} from "../../services/hooks/useResize";
import {Autoplay, EffectCoverflow} from "swiper/modules";
import {Swiper, SwiperSlide} from "swiper/react";
import Graph4Img from "../../images/spec-usa-graph.png";
import Graph2Img from "../../images/usa2-unity-graph.png";
import Graph3Img from "../../images/usa3-unity-graph.png";
import Graph5Img from "../../images/spec-rus-graph.png";
import Graph6Img from "../../images/credit-rus-graph.png";
import Graph1Img from "../../images/usa1-unity-graph.png";

export const Tariffs = memo(({setShowModal, setSubSelect, setSubNameSelect, setDefaultOptionMonth}) => {
    const [activeCountryBtn, setActiveCountryBtn] = useState('Российский рынок')
    const [activeData, setActiveData] = useState(russianSubscriptions)
    const screenWidth = useResize()

    const countryBtnArr = [
        {id: '214325435', title: 'Российский рынок'},
        {id: '345036495', title: 'Рынок США'},
        {id: '609134676', title: 'Все рынки'}
    ]

    useEffect(() => {
        if (activeCountryBtn === 'Российский рынок') setActiveData(russianSubscriptions)
        if (activeCountryBtn === 'Рынок США') setActiveData(worldSubscriptions)
        if (activeCountryBtn === 'Все рынки') setActiveData(allSubscriptions)
    }, [activeCountryBtn]);

    return (
        <div className={styles.tariffsWrapper} id="tariffs">
            <ScrollToHash/>

            <div className={styles.tariffs}>

                <div className={styles.titleContainer}>
                    <h1 className={styles.title}>Подписки</h1>
                    <p className={styles.titleDesc}>Выбери свою подписку и начни инвестировать </p>
                </div>

                <div className={styles.countrySwitcherContainer}>
                    <div className={styles.countrySwitcher}>
                        {countryBtnArr.map(btn => {
                            return <button key={btn.id}
                                           className={`${styles.countryBtn} ${activeCountryBtn === btn.title && styles.activeCountryBtn}`}
                                           onClick={() => setActiveCountryBtn(btn.title)}>{btn.title}</button>
                        })}
                    </div>
                </div>

                {screenWidth > 1500 ? (<div className={styles.tariffsItems}>
                    {[...activeData, ...premiumSubscription].map((subscription, i) => {
                        return <TariffsItem key={i + Math.random()} subscription={subscription}
                                            setShowModal={setShowModal}
                                            setSubSelect={setSubSelect} setSubNameSelect={setSubNameSelect}
                                            setDefaultOptionMonth={setDefaultOptionMonth}/>
                    })}
                </div>)
                :
                    <Swiper
                        className={styles.tariffsItems}
                        slidesPerView={screenWidth > 1150 ? 2.5 : screenWidth > 1100 ? 2.2 : screenWidth > 900 ? 1.9 : screenWidth > 800 ? 1.6 : screenWidth > 550 ? 1.4 : screenWidth > 375 ? 1.18 : 1.15}
                        spaceBetween={screenWidth > 1150 ? 100 : screenWidth > 1100 ? 50 : screenWidth > 375 ? 30 : 25}
                    >
                        {[...activeData, ...premiumSubscription].map((subscription, i) => {
                            return <SwiperSlide >
                                <TariffsItem key={i + Math.random()} subscription={subscription}
                                             setShowModal={setShowModal}
                                             setSubSelect={setSubSelect} setSubNameSelect={setSubNameSelect}
                                             setDefaultOptionMonth={setDefaultOptionMonth}/>
                            </SwiperSlide>
                        })}

                    </Swiper>
                }

                <div className={styles.banner}>
                    <p className={styles.bannerTitle}>{screenWidth > 1100 && 'Акция '}10 дней за&nbsp;10 рублей по&nbsp;промокоду START</p>
                </div>

            </div>
        </div>
    );
});