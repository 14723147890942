import {useEffect, useMemo} from "react"
import { useDispatch, useSelector } from "react-redux"
import { NavLink, useNavigate, useParams } from "react-router-dom"
import Gradient from "../../images/background-green-ellipse.svg"
import LoginPage from "../pages/login-page/login-page"
import ActivationPage from "../pages/activation-page/activation-page"
import ReauthenticationAlert from "../pages/reauthentication-alert/reauthentication-alert"
import { isClickEnter } from "../../services/reducers/modal-slice"
import styles from "./app-authorization-page.module.css"
import {BreadcrumbNavigation} from "../breadcrumb-navigation/breadcrumbsNavigation";

function AuthorizationPage() {

  // document.body.style.backgroundColor = '#172339';
  const dispatch = useDispatch()
  const navigation = useNavigate()
  const token = localStorage.getItem("token")

  const returnUrl = useMemo(() => {
    const currentLink = new URL(window.location.href);
    const subSelect = currentLink.searchParams.get("subSelect");
    const optionMonth = currentLink.searchParams.get("optionMonth");
    const subNameSelect = currentLink.searchParams.get("subNameSelect");

    let urlBuilder = new URL(window.location.origin);

    if(subSelect && optionMonth) {
      urlBuilder.searchParams.set("subSelect", subSelect);
      urlBuilder.searchParams.set("optionMonth", optionMonth);
      urlBuilder.searchParams.set("subNameSelect", subNameSelect);
    } else {
      urlBuilder.pathname = "profile";
    }

    return urlBuilder.pathname + urlBuilder.search;
  }, []);

  useEffect(() => {
    if (!!token) {navigation(returnUrl)}
  },[token])

  const clickEnter = useSelector((store) => store.modal.clickEnter)
  // const isOpenModal = useSelector((store) => store.modal.isOpen)
  const isUserDelete = useSelector((store) => store.modal.userDelete)
  useEffect(() => {
    dispatch(isClickEnter(false))
    // localStorage.setItem("location", "AuthorizationPage")

  },[]);

  const navigate = useNavigate();

  useEffect(() => {
    const handleKeyPress = (e) => {
      if(e.key === "Escape") {
        navigate(returnUrl);
      }
    };
    document.body.addEventListener("keyup", handleKeyPress);
    return () => document.body.removeEventListener("keyup", handleKeyPress);
  });

  useEffect(() => {
    const handleClick = (e) => {
      if (!e.target.classList.contains(styles.containerWrapper)) return;

      navigate(returnUrl);
    };

    const timerId = setTimeout(() => {
      if (window.location.pathname === "/authorization") {
        document.body.addEventListener("click", handleClick);
      }
    }, 0);

    return () => {
      clearTimeout(timerId);
      document.body.removeEventListener("click", handleClick);
    };
  }, []);


  return (
    <>
    <section className={styles.section} >
        <div className={styles.backgroundWrapper} >
          <img src={Gradient} className={styles.background}/>
        </div>
        <div className={styles.containerWrapper}>


          <div className={styles.container}>
            <div className={styles.underButton}>
              <BreadcrumbNavigation forBg="dark" links={[{url: returnUrl, label: "Назад" }]}/>
            </div>


            {(isUserDelete && <ReauthenticationAlert/>) || (clickEnter && <ActivationPage returnUrl={returnUrl}/>) || (<LoginPage returnUtl={returnUrl}/>)}


            </div>
        </div>
    </section>
    </>
  )
}

export default AuthorizationPage

