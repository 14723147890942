import React, {memo, useEffect, useState} from "react";
import {
    Bar,
    BarChart,
    CartesianGrid, ComposedChart, Cross,
    Customized,
    Legend,
    Line, LineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis
} from 'recharts';
import {axisRightValueFormatter, axisValueFormatter, transformData, transformPredictData} from "../utilsForFinanceTab";
import {CustomLegend} from "./custom-legend/custom-legend";
import {CustomTooltip} from "./custom-tooltip/custom-tooltip";
// import styles from './finance-bar-chart.module.css'

export const FinanceBarChart = memo(({
                                    tableRowTitles,
                                    selectedRows,
                                    dataBlock,
                                    ticker,
                                    dataForCompare,
                                    tableRowTitlesSecond,
                                    selectedCompany,
                                    handleLegendClick,
                                    period,
                                    values,
                                    keyBlock
                                }) => {
    const [mergedData, setMergedData] = useState([])
    let data = []
    let dataCompare = []

    if(keyBlock !== 'predictions') {
        data = selectedRows && transformData(dataBlock, selectedRows, period)
        dataCompare = selectedRows && transformData(dataForCompare, selectedRows, period)
    } else {
        data = selectedRows && transformPredictData(dataBlock, selectedRows)
    }

    const mergeDataWithComparison = (data, dataCompare) => {
        return data.map(item => {
            // Найти соответствующий элемент в dataCompare по имени
            const correspondingItem = dataCompare.find(d => d.name === item.name);

            // Если найден соответствующий элемент, добавляем его свойства с суффиксом Compare
            if (correspondingItem) {
                const comparedData = Object.keys(correspondingItem).reduce((acc, key) => {
                    if (key !== 'name') {
                        acc[`${key}Compare`] = correspondingItem[key];
                    }
                    return acc;
                }, {});

                /// Возвращаем объединенный объект
                return {
                    ...item,
                    ...comparedData
                };
            }

            // Если соответствующего элемента в dataCompare нет, возвращаем исходный объект
            return item;
        });
    };

    useEffect(() => {
        if (data) setMergedData(mergeDataWithComparison(data, dataCompare))
    }, []);

    return (
        <div style={{width: '100%'}}>

            <ResponsiveContainer height={420}>
                {values === 'rub' ?
                    // <BarChart
                    <ComposedChart
                    width={500}
                    height={300}
                    data={mergedData}
                    margin={{
                        top: 20,
                        right: 30,
                        left: 20,
                        bottom: 5
                    }}
                    stackOffset={'sign'}
                >
                    <CartesianGrid strokeDasharray="3 3"/>
                    <XAxis dataKey="name" style={{fontSize: '14px'}}/>
                    <YAxis
                        yAxisId="left"
                        orientation="left"
                        tickFormatter={(value) => axisValueFormatter(value, keyBlock)}
                        style={{fontSize: '14px'}}
                    />
                        <YAxis yAxisId="right" orientation="right" tickFormatter={(value) => axisRightValueFormatter(value, keyBlock)} style={{fontSize: '14px'}}/>
                    <Tooltip content={<CustomTooltip ticker={ticker} selectedCompany={selectedCompany}/>}/>
                    <Legend wrapperStyle={{paddingTop: '16px'}}
                            content={<CustomLegend handleLegendClick={handleLegendClick} keyBlock={keyBlock}/>}/>

                    {selectedRows && selectedRows.map((parameter) => {
                        const titleInfo = tableRowTitles.find((row) => row.title === parameter);
                        const titleInfoSecond = tableRowTitlesSecond && tableRowTitlesSecond.find((row) => row.title === `${parameter}Compare`)

                        if (titleInfo || titleInfoSecond) {
                                return (
                                    <React.Fragment key={parameter}>
                                      {(!parameter.includes('roa') && !parameter.includes('roe') && !parameter.includes('payout') && !parameter.includes('Margin')) &&
                                        // {(!parameter.includes('roa') && !parameter.includes('roe') && !parameter.includes('payout') && !parameter.includes('Margin') && parameter.includes('P/E') && parameter.includes('Net Debt/EBITDA') && parameter.includes('EV/EBITDA') && (parameter.includes('EBITDA') && keyBlock !== 'predictions')) &&
                                            <Bar
                                            yAxisId={'left'}
                                            key={`${parameter}-data-${Math.random()}`}
                                            dataKey={parameter}
                                            fill={titleInfo.fill}
                                            stackId={titleInfo.stackId}
                                        />}
                                      {(parameter.includes('roa') || parameter.includes('roe') || parameter.includes('payout') || parameter.includes('Margin')) &&
                                        // {(parameter.includes('roa') || parameter.includes('roe') || parameter.includes('payout') || parameter.includes('Margin') || parameter.includes('P/E') || parameter.includes('Net Debt/EBITDA') || parameter.includes('EV/EBITDA') || (parameter.includes('EBITDA') && keyBlock === 'predictions')) &&
                                            <Line yAxisId={'right'}
                                              key={`${parameter}-${Math.random()}`}
                                              type="monotone"
                                              dataKey={parameter}
                                              stroke={titleInfo.fill}
                                              stackId={titleInfo.stackId} />}
                                        {titleInfoSecond &&
                                            <Bar
                                                yAxisId={'left'}
                                                key={`${parameter}-dataCompare-${Math.random()}`}
                                                dataKey={`${parameter}Compare`} // Данные из dataCompare тоже будут отображаться
                                                fill={titleInfoSecond.fill}
                                                stackId={titleInfoSecond.stackId} // Используем тот же stackId для объединения
                                            />}
                                    </React.Fragment>
                                );
                        }

                        return null;
                    })}

                    </ComposedChart>
                    :
                    <LineChart
                        width={500}
                        height={300}
                        data={mergedData}
                        margin={{
                            top: 20,
                            right: 30,
                            left: 20,
                            bottom: 5
                        }}
                        stackOffset={'sign'}
                    >
                        <CartesianGrid strokeDasharray="3 3"/>
                        <XAxis tickMargin={10} dataKey="name" style={{fontSize: '14px'}}/>
                        <YAxis
                            yAxisId="left"
                            orientation="left"
                            tickFormatter={(value) => axisValueFormatter(value)}
                            style={{fontSize: '14px'}}
                            tickMargin={10}
                        />
                         <YAxis yAxisId="right" orientation="right" stroke="#82ca9d" style={{fontSize: '14px'}}/>
                        <Tooltip content={<CustomTooltip ticker={ticker} selectedCompany={selectedCompany}/>}/>
                        <Legend wrapperStyle={{paddingTop: '16px'}}
                                content={<CustomLegend handleLegendClick={handleLegendClick}/>}/>
                        {selectedRows && selectedRows.map((parameter) => {
                            const titleInfo = tableRowTitles.find((row) => row.title === parameter);
                            const titleInfoSecond = tableRowTitlesSecond && tableRowTitlesSecond.find((row) => row.title === `${parameter}Compare`)

                            if (titleInfo || titleInfoSecond) {
                                if (parameter !== 'roa' && parameter !== 'roe' && parameter !== 'payout') {
                                    return (
                                        <React.Fragment key={parameter}>
                                            <Line
                                                type="monotone"
                                                yAxisId={'left'}
                                                key={`${parameter}-data-${Math.random()}`}
                                                dataKey={parameter}
                                                stroke={titleInfo.fill}
                                                stackId={titleInfo.stackId}
                                            />
                                            {titleInfoSecond &&
                                                <Line
                                                    type="monotone"
                                                    yAxisId={'left'}
                                                    key={`${parameter}-dataCompare-${Math.random()}`}
                                                    dataKey={`${parameter}Compare`} // Данные из dataCompare тоже будут отображаться
                                                    stroke={titleInfoSecond.fill}
                                                    stackId={titleInfoSecond.stackId} // Используем тот же stackId для объединения
                                                />}
                                        </React.Fragment>
                                    );
                                } else {
                                    return (
                                        <Line
                                            yAxisId={'right'}
                                            key={`${parameter}-${Math.random()}`}
                                            dataKey={parameter}
                                            fill={titleInfo.fill}
                                            shape={<TriangleBar/>}
                                            stackId={titleInfo.stackId}
                                        />
                                    );
                                }
                            }

                            return null;
                        })}

                    </LineChart>
                }
            </ResponsiveContainer>
        </div>
    );
})

const getPath = (x, y, width, height) => {
    return `M${x},${y + height}C${x + width / 3},${y + height} ${x + width / 2},${y + height / 3}
  ${x + width / 2}, ${y}
  C${x + width / 2},${y + height / 3} ${x + (2 * width) / 3},${y + height} ${x + width}, ${y + height}
  Z`;
};

const TriangleBar =(props) => {
    const {fill, x, y, width, height} = props;

    return <path d={getPath(x, y, width, height)} stroke="none" fill={fill}/>;
};