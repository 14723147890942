import React from "react";
import {useInView} from "react-intersection-observer";
import {PolarGrid, PolarRadiusAxis, Radar, RadarChart} from "recharts";
import {getColorByValue} from "../../../../../../utils/getColorByValue";

export const LazyRadarChart = React.memo(({ chartData, snowFlakeSum }) => {

  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <div ref={ref}>
      {inView ? (
        <RadarChart data={chartData} width={85} height={85}>
          <PolarGrid />
          <PolarRadiusAxis tickCount={7} tick={false} axisLine={false} domain={[0, 6]} />
          <Radar
            name="Компания"
            dataKey="A"
            stroke={getColorByValue(snowFlakeSum)}
            fill={getColorByValue(snowFlakeSum)}
            fillOpacity={0.1}
            isAnimationActive={false}
          />
        </RadarChart>
      ) : null}
    </div>
  );
}, (prevProps, nextProps) => {
  for (let i = 0; i < prevProps.chartData.length; i++) {
    if (prevProps.chartData[i] !== nextProps.chartData[i]) return false;
  }
  return true;
});
