import styles from "./footer-auth-content.module.css";
import doorExit from '../../../../images/door-exit.svg';
import {NavLink} from "react-router-dom";

export const FooterAuthContent = ({subSelect, optionMonth, subNameSelect}) => {
  return (
    <div className={styles.footerAuthContent}>
      <p className={styles.authToBuy}>Авторизуйтесь чтобы приобрести подписку или использовать промокод</p>
      <NavLink to={`/authorization?subSelect=${subSelect}&optionMonth=${optionMonth}&subNameSelect=${encodeURIComponent(subNameSelect)}`}>
        <button className={styles.authButton}>
          <img className={styles.doorExit} src={doorExit} alt="door exit"/>
          Войти через почту
        </button>
      </NavLink>
    </div>
  );
};
