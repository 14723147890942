import styles from './tariffs-item/tariffs-item-benefit/tariffs-item-benefit.module.css'

export const russianSubscriptions = [
    {
        id: '423982950943095285690',
        title: 'Unity',
        subName: 'Unity Россия',
        subTitle: 'unity-rossiya',
        subSlug: 'unity-russia',
        ym: 'unt_rus',
        description: 'Для тех, кто хочет самостоятельно научиться инвестировать. Поможем повысить доходность',


        prices: {
            oneMonth: {price: '1 250', discount: ''},
            sixMonth: {price: '1 000', discount: 20},
            year: {price: '900', discount: 28},
        },
        benefits: [
            {
                "label": "Модельный портфель",
                "tooltip": "Копируйте портфель полностью или собирайте на его основе свой собственный. Доходность за 2 года +70.5%"
            },
            {
                "label": "Чат с экспертами",
                "tooltip": "Задавайте вопросы и получайте быстрый ответ от наших экспертов. Вопросы по покупке/продаже активов, акциям, валюте, облигациям, ПИФ/ETF и многие другие"
            },
            {
                "label": "Аналитика по 130 компаниям",
                "tooltip": "Фундаментальные разборы, краткие комментарии по ключевым новостям и событиям. В конце каждого – четкий вывод, на основе которого можно принять решение о покупке/продаже позиции"
            },
            {
                "label": "Видеообзоры рынка",
                "tooltip": "Еженедельные видео, где обсуждаем ситуацию на рынке, макроэкономику, валюту и отдельные компании"
            },
            {
                "label": "Обучающий курс",
                "tooltip": "60 тем от азов до продвинутого уровня. Подробно, простым языком и с примерами. Поддержка в чате экспертов"
            },
            {
                "label": "Аналитические таблицы", "tooltip": <span>
            <p className={styles.tooltipListDesc}>Точки входа и таргеты по 130 акциям.</p>
            <p className={styles.tooltipListDesc}>Прогнозы по прибылям, EBITDA, дивидендам.</p>
            <p className={styles.tooltipListDesc}>Форвардные мультипликаторы.</p>
            <p className={styles.tooltipListDesc}>Дивидендный календарь с прогнозными выплатами.</p>
        </span>
            }
        ]
    },
    {
        id: '6456238405103457834',
        title: 'Ideas',
        subName: 'Ideas Россия',
        subTitle: 'ideas-rossiya',
        subSlug: 'ideas-russia',
        ym: 'ids_rus',
        description: 'Для тех, кто хочет следовать за сделками и получить результат. Следите за сигналами и обгоняйте индекс',
        prices: {
            oneMonth: {price: '2 650', discount: ''},
            sixMonth: {price: '2 150', discount: 18},
            year: {price: '1 959', discount: 26},
        },
        benefits: [
            {
                "label": "Долгосрочный портфель",
                "tooltip": "Ставка на лучшие акции среди 1-2 эшелона, отбираем компании, которые могут значительно обогнать рынок. Каждая акция выбирается на основе фундаментального анализа и финансовой модели. Тесно общаемся с эмитентами, что повышает качество прогнозов по прибылям и дивидендам"
            },
            {
                "label": "Спекулятивный портфель",
                "tooltip": "Поиск недооцененных компаний с высоким краткосрочным потенциалом роста. Регулярная ребалансировка позволяет держать только перспективные акции и максимизировать доходность. Используется и технический, и фундаментальный анализ. Стратегия работает вне зависимости от рыночного цикла, используются шорты и выход в кэш. (доходность +74% за 2 года)"
            },
            {
                "label": "Портфель облигаций",
                "tooltip": "Выбор качественных облигаций с высокой доходностью к погашению. Доходностьвыше, чем у любого фонда на облигации. Зарабатываем как на купонах, так и на росте тела облигации. Мониторим новости и корпоративные события по компаниям, чтобы выйти из позиции до возникновения проблем. Широкая диверсификация из 20+ эмитентов. Доходность к погашению 24.5%"
            },
            {
                "label": "Поможем с Вашим портфелем",
                "tooltip": "При подключении к сервису помогаем привести Ваш портфель к нашему, после чего можно просто повторять сделки"
            },
            {
                "label": "Экономия времени",
                "tooltip": "В среднем сделок в неделю: спекулятивный портфель (7), долгосрочный (2), облигации (0.5). Быстрая реакция на любое событие. У компании проблемы и нужно быстро продать / вышла позитивная новость и пора покупать – получите уведомление"
            },
            {
                "label": "Техническое и фундаментальное обоснование",
                "tooltip": "Каждая акция выбирается на основе фундаментального анализа и финансовой модели. Сочетание с техническим анализом позволяет значительно обгонять рынок"
            }
        ]
    }
]

export const worldSubscriptions = [
    {
        id: '3845083946239466345',
        title: 'Unity',
        subName: 'Unity World',
        subTitle: 'unity-world',
        subSlug: 'unity-world',
        ym: 'unt_wrld',
        description: 'Для тех, кто хочет самостоятельно научиться инвестировать. Поможем повысить доходность',
        prices: {
            oneMonth: {price: '1 600', discount: ''},
            sixMonth: {price: '1 334', discount: 16},
            year: {price: '1 167', discount: 27},
        },
        benefits: [
            {
                "label": "Модельные портфели", "tooltip": "Копируйте портфель полностью или собирайте на его основе свой собственный. 2 портфеля для рынка США (дивидендный и оптимальный), а также китайский портфель. Все 3 обгоняют бенчмарки."
            },
            {
                "label": "Чат с экспертами",
                "tooltip": "Задавайте вопросы и получайте быстрый ответ от наших экспертов. Вопросы по покупке/продаже активов, акциям, ETF, макроэкономике и многие другие"
            },
            {
                "label": "Аналитика по 400 компаниям",
                "tooltip": "Фундаментальные разборы, краткие комментарии по ключевым новостям и событиям. В конце каждого – четкий вывод, на основе которого можно принять решение о покупке/продаже позиции"
            },
            {
                "label": "Видеообзоры рынка",
                "tooltip": "Еженедельные видео, где обсуждаем ситуацию на рынке, макроэкономику и отдельные компании"
            },
            {
                "label": "Обучающий курс",
                "tooltip": "60 тем от азов до продвинутого уровня. Подробно, простым языком и с примерами. Если не разобрались в теме – спросите наших экспертов в чате"
            },
            {
                "label": "Аналитические таблицы", "tooltip": <span>
            <p className={styles.tooltipListDesc}>Точки входа и таргеты по 400 акциям.</p>
            <p className={styles.tooltipListDesc}>Мультипликаторы, EPS, дивиденды.</p>
            <p className={styles.tooltipListDesc}>Форвардные мультипликаторы.</p>
            <p className={styles.tooltipListDesc}>Прогнозы по компаниям, которые будут включены/исключены из S&P500.</p>
        </span>
            }
        ]
    },
    {
        id: '34508603457087094374',
        title: 'Ideas',
        subName: 'Ideas World',
        subTitle: 'ideas-world',
        subSlug: 'ideas-world',
        ym: 'ids_wrld',
        description: 'Для тех, кто хочет следовать за сделками и получить результат. Следите за сигналами и обгоняйте индекс',
        prices: {
            oneMonth: {price: '2 850', discount: ''},
            sixMonth: {price: '2 250', discount: 21},
            year: {price: '2 084', discount: 26},
        },
        benefits: [
            {
                "label": "Спекулятивный портфель",
                "tooltip": "Поиск недооцененных компаний с высоким краткосрочным потенциалом роста, используются плечевые ETF. Каждая акция выбирается на основе фундаментального анализа и финансовой модели. Стратегия работает вне зависимости от рыночного цикла, во время медвежьих циклов используются шорты или выход в кэш. Доходность +34% за 2 года"
            },
            {
                "label": "Дивидендный портфель",
                "tooltip": "Компании, сочетающие высокие дивиденды, устойчивость выплат и потенциал развития бизнеса. Див. доходность вдвое выше, чем у индекса дивидендных аристократов (4.2% против 2.1%). Покупаем компании на локальных просадках: фиксируем высокую див. доходность + получаем потенциальный отскок котировок. Доходность +29% за 2 года"
            },
            {
                "label": "Оптимальный портфель",
                "tooltip": "Поиск агрессивных акций с упором на small/mid cap. Они показывают наилучший результат во время бычьего рынка и имеют сейчас сильное отставание от S&P500, что дает портфелю большой потенциал для роста. Используются и перепроданные value-компании. Это долгосрочный портфель без шортов и спекулятивных сделок. Доходность +13% за 1.5 года"
            },
            {
                "label": "Китайский портфель",
                "tooltip": "Используем акции как с американским листингом, так и с гонконгской биржи. Ставка в первую очередь на value-компании. Поиск лучших акций на китайском рынке, которые могут стабильно обгонять индекс. Доходность +38% за 2 года"
            },
            {
                "label": "Поможем с Вашим портфелем",
                "tooltip": "При подключении к сервису помогаем привести Ваш портфель к нашему, после чего можно просто повторять сделки"
            },
            {
                "label": "Экономим время",
                "tooltip": "В среднем сделок в неделю: спекулятивный портфель (8), оптимальный (2), дивидендный (2), китайский (1). Быстрая реакция на любое событие. У компании проблемы и нужно быстро продать/вышла позитивная новость и пора покупать – получите уведомление"
            }
        ]
    }
]

export const allSubscriptions = [
    {
        id: '125723045873094687',
        title: 'Unity',
        subName: 'Unity All',
        subTitle: 'unity-all',
        subSlug: 'unity-all',
        ym: 'unt_all',
        description: 'Для тех, кто хочет самостоятельно научиться инвестировать. Поможем повысить доходность',
        prices: {
            oneMonth: {price: '1 990', discount: ''},
            sixMonth: {price: '1 667', discount: 16},
            year: {price: '1 500', discount: 24},
        },
        benefits: [
            {
                "label": "Модельные портфели", "tooltip": "Копируйте портфель полностью или собирайте на его основе свой собственный. 2 портфеля для рынка США (дивидендный и оптимальный), 1 Российский а также китайский портфель. Все 4 обгоняют бенчмарки."
            },
            {
                "label": "Чат с экспертами",
                "tooltip": "Задавайте вопросы и получайте быстрый ответ от наших экспертов. Вопросы по покупке/продаже активов, акциям, ETF, макроэкономике и многие другие."
            },
            {
                "label": "Аналитика по 500 компаниям",
                "tooltip": "Фундаментальные разборы, краткие комментарии по ключевым новостям и событиям РФ, США и Китая. В конце каждого – четкий вывод, на основе которого можно принять решение о покупке/продаже позиции"
            },
            {
                "label": "Видеообзоры рынка",
                "tooltip": "Еженедельные видео, где обсуждаем ситуацию на рынке, макроэкономику и отдельные компании"
            },
            {
                "label": "Обучающий курс",
                "tooltip": "60 тем от азов до продвинутого уровня. Подробно, простым языком и с примерами. Поддержка в чате экспертов"
            },
            {
                "label": "Аналитические таблицы", "tooltip": <span>
            <p className={styles.tooltipListDesc}>Точки входа и таргеты по 500 акциям.</p>
            <p className={styles.tooltipListDesc}>Прогнозы по прибылям, EBITDA, дивидендам.</p>
            <p className={styles.tooltipListDesc}>Форвардные мультипликаторы.</p>
            <p className={styles.tooltipListDesc}>Дивидендный календарь с прогнозными выплатами.</p>
        </span>
            }
        ]
    },
    {
        id: '07628069728543237656',
        title: 'Ideas',
        subName: 'Ideas All',
        subTitle: 'ideas-all',
        subSlug: 'ideas-all',
        ym: 'ids_all',
        description: 'Для тех, кто хочет следовать за сделками и получить результат. Следите за сигналами и обгоняйте индекс',
        prices: {
            oneMonth: {price: '3 990', discount: ''},
            sixMonth: {price: '3 167', discount: 20},
            year: {price: '2 917', discount: 26},
        },
        benefits: [
            {
                "label": "Спекулятивный портфель США",
                "tooltip": "Поиск недооцененных компаний с высоким краткосрочным потенциалом роста, используются плечевые ETF. Каждая акция выбирается на основе фундаментального анализа и финансовой модели. Стратегия работает вне зависимости от рыночного цикла, во время медвежьих циклов используются шорты или выход в кэш. Доходность +34% за 2 года"
            },
            {
                "label": "Спекулятивный портфель РФ",
                "tooltip": "Поиск недооцененных компаний с высоким краткосрочным потенциалом роста. Регулярная ребалансировка позволяет держать только перспективные акции и максимизировать доходность. Используется и технический, и фундаментальный анализ. Стратегия работает вне зависимости от рыночного цикла, используются шорты и выход в кэш. (доходность +74% за 2 года)"
            },
            {
                "label": "Долгосрочный портфель РФ",
                "tooltip": "Ставка на лучшие акции среди 1-2 эшелона, отбираем компании, которые могут значительно обогнать рынок. Каждая акция выбирается на основе фундаментального анализа и финансовой модели. Тесно общаемся с эмитентами, что повышает качество прогнозов по прибылям и дивидендам (доходность +70.5% за 2 года)"
            },
            {
                "label": "Портфель облигаций РФ",
                "tooltip": "Выбор качественных облигаций с высокой доходностью к погашению. Доходность выше, чем у любого фонда на облигации. Зарабатываем как на купонах, так и на росте тела облигации. Мониторим новости и корпоративные события по компаниям, чтобы выйти из позиции до возникновения проблем. Широкая диверсификация из 20+ эмитентов. Доходность к погашению 24.5%"
            },
            {
                "label": "Долгосрочные портфели США",
                "tooltip": "Дивидендный портфель - компании, сочетающие высокие дивиденды, устойчивость выплат и потенциал развития бизнеса. Оптимальный портфель - поиск агрессивных акций с упором на small/mid cap. Оба портфеля обгоняют целевые индексы"
            },
            {
                "label": "Китайский портфель",
                "tooltip": "Используем акции как с американским листингом, так и с гонконгской биржи. Ставка в первую очередь на value-компании. Поиск лучших акций на китайском рынке, которые могут стабильно обгонять индекс. Доходность +38% за 2 года"
            }
        ]
    }
]

export const premiumSubscription = [
    {
        id: '4528984357428435',
        title: 'Premium',
        subName: 'Premium',
        subTitle: 'premium',
        subSlug: 'premium',
        ym: 'prm',
        description: 'Для тех, кому требуется индивидуальный портфель. Выделим персонального менеджера',
        prices: {
            oneMonth: {price: '40 000', discount: ''},
            sixMonth: {price: '10 000', discount: 75},
            year: {price: '8 334', discount: 79},
        },
        benefits: [
            {
                "label": "Портфель под Ваши цели",
                "tooltip": "Подбираем портфель под Ваш персональный риск-профиль, горизонт инвестирования и цели. Поможем привести в порядок Ваш старый портфель. Не берем деньги в управление, счетом Вы управляете самостоятельно. Мы даем только план"
            },
            {
                "label": "Персональный менеджер",
                "tooltip": "Готов в любой момент индивидуально ответить на Ваши вопросы"
            },
            {
                "label": "Помощь в управлении портфелем",
                "tooltip": "Персональный менеджер помогает с точками входа и делает ребалансировки в портфеле"
            },
            {
                "label": "Все функции подписки Unity Все рынки",
                "tooltip": "Все функции базовой подписки будут Вам доступны"
            }
        ]
    }
]