import React from "react";
import clsx from "classnames";
import arrowLeftForWhiteBg from "../../images/arrow-left-card.png";
import arrowLeftForDarkBg from "../../images/arrow-left-navigate-button.svg";
import {NavLink} from "react-router-dom";
import styles from "./breadcrumbsNavigation.module.css";

/**
 * BreadcrumbNavigation component that displays navigation links.
 *
 * @param {{label: string, url: string}[]} links
 * @param {"light" | "dark"} forBg - The background theme for the breadcrumb, either "light" or "dark".
 * @param {string} [className] - Optional additional CSS class for the breadcrumb container.
 * @param {boolean} [withMarginBottom=false] - If true, adds margin-bottom to the breadcrumb container.
 *
 * @returns {JSX.Element} The breadcrumb navigation component.
 */
export const BreadcrumbNavigation = ({links, forBg = "dark", className, withMarginBottom = true}) => {
  const arrowLeft = forBg === "light" ? arrowLeftForWhiteBg : arrowLeftForDarkBg;
  const linkClass = clsx(styles.link, {[styles.linkForDarkBg]: forBg === "dark"});
  const wrapperClass = clsx(styles.navigationBox, className, {[styles.marginBottom]: withMarginBottom});

  return (
    <div className={wrapperClass}>
      {links.map((link, index) => (
        <NavLink key={index} className={linkClass} to={link.url}>
          <img alt="arrow to left" src={arrowLeft}/>
          {link.label}
        </NavLink>
      ))}
    </div>
  );
};
