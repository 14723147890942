import React, {useEffect, useState, useRef} from 'react';
import SEO from "../../../../../utils/SEO";
import {useParams} from "react-router-dom";
import axios from "axios";
import Preloader from "../../../../preloader/preloader";
import {useTheme} from "../../../../../services/hooks/useTheme";
import { BreadcrumbNavigation } from '../../../../breadcrumb-navigation/breadcrumbsNavigation';
import styles from './BlogForLink.module.css'

const BlogForLink = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const {slug} = useParams();
    const {theme} = useTheme();
    const containerRef = useRef(null);


    useEffect(() => {
        const getData = async () => {
            try {
                setLoading(true);
                const response = await axios.get(`/api/blog/${slug}`);
                setData(response.data);
                setLoading(false);
            } catch (e) {
                console.log(e?.response?.status);
                setLoading(false);
            }
        }
        if(slug) {
            getData();
        }
    }, [slug]);

    //эффекст смены цвета html который приходит с сервера
    useEffect(() => {
        if (containerRef.current) {
            const color = theme === 'app_light_theme' ? '#172339' : '#f5f5f5';

            // Изменение цвета текста для всех элементов внутри контейнера
            const allTextElements = containerRef.current.querySelectorAll('*');
            allTextElements.forEach(element => {
                element.style.color = color;
            });
        }
    }, [theme]);


    return (
        <>
            <SEO
                title={data?.['title_seo']}
                description={data?.description}
            />

            <div className={styles.container}>
                <div className={styles.blogItem}>
                    <BreadcrumbNavigation links={[{label: "Главная", url: "/"}, {label: "Полезно инвестору", url: "/polezno-investoru"}]} forBg="light"/>

                    <div className={styles.wrapper}>
                        <div className={styles.allCategories}>
                            {loading ? (
                                <div className={styles.preloader}>
                                    <Preloader/>
                                </div>
                            ) : (
                                <div className={styles.item}>
                                    <div className={styles.time}>Обновлено {data?.['pub_date_formated']}</div>
                                    <h1 className={styles.title}>{data?.name}</h1>
                                    <div
                                        ref={containerRef}
                                        className={styles.desc}
                                        dangerouslySetInnerHTML={{ __html: data?.text }}></div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>

        </>

    );
};

export default BlogForLink;
