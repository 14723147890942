import {NavLink} from "react-router-dom";
import styles from './main-section-questions.module.css'
import girl from "../../images/girl.svg"
function MainSectionQuestions() {
    return (
        <section className={styles.section}>
            <div className={styles.container}>

                <div className={styles.imagePhone}></div>

                <div className={styles.box}>
                    <h2 className={styles.title}>Задайте вопрос нашему эксперту в Telegram и он подскажет правильный шаг</h2>
                    <div className={styles.boxLink}>

                        <NavLink to={'https://t.me/ie_contact'} target={'_blank'} className={styles.link}>
                            Связаться с нами
                        </NavLink>

                        {/*<NavLink to={''} className={styles.link}>
                            <div className={styles.whatsapp}></div>
                        </NavLink>
                        <NavLink to={''} className={styles.link}>
                            <div className={styles.telegram}></div>
                        </NavLink>*/}
                    </div>
                </div>

                <img src={girl} className={styles.image}/>
            </div>


        </section>
    )
}

export default MainSectionQuestions