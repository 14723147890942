import {useEffect, useState} from 'react';
import {Route, Routes, useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {HelmetProvider} from 'react-helmet-async';
import AppMainAnn from '../app-main-ann/app-main-ann';
import LayoutMain from '../layout-main/layout-main';
import AppMain from '../app-main/app-main';
import Authorization from '../app-authorization/app-authorization-page';
import AboutCard from '../pages/about-card/about-card';
import Education from '../pages/education/education';
import LessonsPage from '../pages/lessons-page/lessons-page';
import Lesson from '../pages/lesson/lesson';
import Tables from '../pages/tables/tablesColumn';
import PageInfo from '../pages/page-info/page-info';
import Profile from '../app-main-profile/app-main-profile';
import {ActiveButtonProvider} from '../../services/context/ActiveButtonContext';
import {ActiveCountryTagProvider} from '../../services/context/CountryFilterContext';
import {ActiveFiltersProvider} from '../../services/context/ActiveFiltersContext';
import {ProfileProvider} from '../../services/context/ProfileContext';
import {PageInfoProvider} from '../../services/context/PageInfoContext';
import PageInfoUrl from '../../components/pages/page-info/page-info-url/page-url-info';
import ProductSub from '../../components/pages/products/product-sub-url/product-sub-url';
import NoAccess from '../no-access-page/no-access-page';
import Error500 from '../app-error-pages/error-500/error-500';
import Error403 from '../app-error-pages//error-403/error-403';
import {WebinarProvider} from '../../services/context/WebinarContext';
import Webinars from '../pages/webinars/webinars';
import {isClickEnter, isUserDelete} from '../../services/reducers/modal-slice';
import AboutUs from '../about-us/about-us';
// import { MainPageProvider } from '../../services/context/MainPageContext';
import TableNew from '../pages/table-new/table-new';
import {EducationProvider} from '../../services/context/EducationContext';
import {TwoLevelMenuOther} from '../two-level-menu/two-level-menu';
import NoAuthProfile from '../app-main-profile/no-auth-profile/no-auth-profile';
import {PopUpTimeOutTables} from '../pop-up-timeout/pop-up-timeout-tables/PopUpTimeOutTables';
import {PopUpTimeOutAnalytics} from '../pop-up-timeout/pop-up-timeout-analitycs/PopUpTimeOutAnalytics';
import {PopUpTimeOutEmail} from '../pop-up-timeout/pop-up-timeout-email/PopUpTimeOutEmail';
import {MarketMap} from '../market-map/market-map';
import ScreenerPage from '../pages/screener-page/screener-page';
import BlogPage from "../pages/blog-page/BlogPage";
import {Glossary} from "../pages/glossary/glossary";
import {ScreenerMain} from "../pages/screener-page/ui/screener-main/screener-main";
import styles from './app.module.css';
import BlogForLink from "../pages/blog-page/ui/BlogForLink/BlogForLink";
import PageForAddInfo from "../pages/page-for-addInfo/PageForAddInfo";

// import {responseStatus, getCardsMainNew, getCardsMainAnalytics} from "../../utils/api"
// import { setObject } from "../../services/reducers/cards-slice"
// import CountryPageRussia from '../pages/CountryPage/countryPageRussia/CountryPageRussia';

function ExternalRedirect() {
  window.location.replace(window.location.hostname === "localhost" || window.location.hostname === "stage.invest-era.ru" ?
    "https://stage.invest-era.ru/not-found" :
    "https://invest-era.ru/not-found");
}

function App() {
  const dispatch = useDispatch();
  const location = useLocation();
  const isAuthChecked = useSelector((store) => store.user.isAuthChecked);
  const [showTimeoutPopupFirst, setShowTimeoutPopupFirst] = useState(false);
  const [showTimeoutPopupSecond, setShowTimeoutPopupSecond] = useState(false);
  const [showTimeoutPopupThird, setShowTimeoutPopupThird] = useState(false);

  /*  const getData = () => {
    return fetch(`api/analytics-and-news/`)
      .then(responseStatus)
      .then((res) => {
        dispatch(setObject(res.results))
      })
  }

  useEffect(() => {
    getData()
    dispatch(getCardsMainNew())
    dispatch(getCardsMainAnalytics())
  }, [])
  */

  useEffect(() => {
    if (!!window.ym) {
      window.ym(96742836, 'hit', location.pathname+location.search);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (location.pathname !== '/authorization') {
      dispatch(isUserDelete(false));
      dispatch(isClickEnter(false));
    }
    if (
      localStorage.getItem('token') &&
      location.pathname !== '/authorization' &&
      location.pathname !== '/profile' &&
      location.pathname !== '/not-found'
    ) {
      localStorage.setItem('location', location.pathname);
    }
  }, [location.pathname]);

  //отключил на время
  useEffect(() => {
    let timer;

    // Проверка на поискового бота
    const userAgent = navigator.userAgent.toLowerCase();
    const isSearchBot = userAgent.includes('yandex') || userAgent.includes('google') || userAgent.includes('yadirectfetcher');

    if (isSearchBot) return; // Если это поисковый бот, ничего не делаем
    if (localStorage.getItem('noPopUp')) return;
    if (isAuthChecked) return;

    if (
      !isAuthChecked &&
      location.pathname !== '/authorization' &&
      location.pathname !== '/profile'
    ) {
      const firstVisit = localStorage.getItem('firstVisit');
      const secondVisit = localStorage.getItem('secondVisit');
      const thirdVisit = localStorage.getItem('thirdVisit');

      if (!firstVisit && !secondVisit && !thirdVisit) {
        timer = setTimeout(() => {
          setShowTimeoutPopupFirst(true);
          localStorage.setItem('firstVisit', 'firstVisit');
        }, 15000);
      } else if (firstVisit && !secondVisit && !thirdVisit) {
        timer = setTimeout(() => {
          setShowTimeoutPopupSecond(true);
          localStorage.setItem('secondVisit', 'secondVisit');
        }, 15000);
      } else if (firstVisit && secondVisit && !thirdVisit) {
        timer = setTimeout(() => {
          setShowTimeoutPopupThird(true);
          localStorage.setItem('thirdVisit', 'thirdVisit');
        }, 15000);
      }
      if (thirdVisit) {
        setShowTimeoutPopupFirst(false);
        setShowTimeoutPopupSecond(false);
        setShowTimeoutPopupThird(false);
      }
    }

    return () => clearTimeout(timer);
  }, [isAuthChecked, location.pathname]);

  useEffect(() => {
    if (!!window.ym) {
      if (showTimeoutPopupFirst) {
        window.ym(96742836, 'reachGoal', 'popup-our-analysis');
      }
      if (showTimeoutPopupSecond) {
        window.ym(96742836, 'reachGoal', 'popup-promo-start');
      }
      if (showTimeoutPopupThird) {
        window.ym(96742836, 'reachGoal', 'popup-email');
      }
    }
  }, [showTimeoutPopupFirst, showTimeoutPopupSecond, showTimeoutPopupThird]);

  useEffect(() => {
    if (showTimeoutPopupFirst || showTimeoutPopupSecond || showTimeoutPopupThird) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }, [showTimeoutPopupFirst, showTimeoutPopupSecond, showTimeoutPopupThird]);

  return (
    <HelmetProvider>
      {showTimeoutPopupFirst && (
        <PopUpTimeOutAnalytics setShowTimeoutPopupFirst={setShowTimeoutPopupFirst} />
      )}
      {showTimeoutPopupSecond && (
        <PopUpTimeOutTables setShowTimeoutPopupSecond={setShowTimeoutPopupSecond} />
      )}
      <ActiveFiltersProvider>
        {showTimeoutPopupThird && (
          <PopUpTimeOutEmail setShowTimeoutPopupThird={setShowTimeoutPopupThird} />
        )}
      </ActiveFiltersProvider>
      <ActiveCountryTagProvider className={styles.app}>
        <Routes>
          <Route path="/" element={<LayoutMain />}>
            <Route path="/authorization" element={<Authorization />} />

{/*            <Route element={<MainPageProvider />}>
              <Route index element={<AppMain />} />
            </Route>*/}

            <Route element={<WebinarProvider />}>
              <Route path="/webinars" element={<Webinars />} />
            </Route>

            <Route element={<ActiveFiltersProvider />}>
              <Route path="/analytics-and-news/" element={<AppMainAnn />} />
              <Route path="/analytics-and-news/:slug" element={<AboutCard />} />
              <Route path="/about-us" element={<AboutUs />} />
            </Route>

            <Route element={<EducationProvider />}>
              <Route path="/education" element={<Education />} />
              <Route path="/education/:slug" element={<LessonsPage />} />
              <Route path="/lessons/:slug" element={<Lesson />} />
            </Route>

            <Route element={<ProfileProvider />}>
              <Route index element={<AppMain />} />
              <Route path="/profile" element={isAuthChecked ? <Profile /> : <NoAuthProfile />} />
              <Route path="/products/:slug" element={<ProductSub />} />
            </Route>

            <Route element={<ActiveButtonProvider />}>
              <Route path="/" element={<TwoLevelMenuOther />} />
              <Route path="/tables/:slug" element={<Tables />} />
              {/* <Route path="/tables/:sub" element={<CountryPageRussia />} /> */}
              <Route path="/tables/:subs/:sub/:slug" element={<TableNew />} />
              <Route path="/polezno-investoru/" element={<BlogPage />} />
              <Route path="/polezno-investoru/:slug" element={<BlogForLink />} />
              {/*<Route path="/polezno-investoru/:sub/:slug" element={} />*/}
            </Route>

            <Route element={<PageInfoProvider />}>
              <Route path="/info" element={<PageInfo />} />
              <Route path="/info/:slug" element={<PageInfoUrl />} />
            </Route>

            <Route path="/market-map" element={<MarketMap />} />
            <Route path="/glossary" element={<Glossary />} />

            <Route path="/screener/:slug" element={<ScreenerPage />} />
            <Route path="/screener" element={<ScreenerMain />} />

            <Route path='/nv342390n7n903n90mv39082380v23v48' element={<PageForAddInfo/>} />

            <Route path="/noaccess" element={<NoAccess />} />
            <Route path="/error-500" element={<Error500 />} />
            <Route path="/error-403" element={<Error403 />} />
            {/*<Route path="/not-found" element={<Error404 />} />*/}
            <Route path="*" element={<ExternalRedirect />} />
          </Route>
        </Routes>
      </ActiveCountryTagProvider>
    </HelmetProvider>
  );
}

export default App;

