import React, {useEffect, useState} from 'react';
import {Link, useParams} from "react-router-dom";
import {useSelector, useDispatch} from "react-redux";
import Preloader from "../../../../preloader/preloader";
import {blogActions} from "../../../../../services/reducers/blog-slice";
import {getDataForMarksBlogPage} from "../../../../../services/reducers/blog-slice";
import { useInView } from "react-intersection-observer";

import styles from './allCategories.module.css'
import CanMightBeActualAnalytics from "../../../../can-might-be-actual-analytics/can-might-be-actual-analytics";
import {useActiveButton} from "../../../../../services/context/ActiveButtonContext";


const AllCategories = ({marks}) => {
    const {sub} = useParams();
    const filteredObject = useSelector((store) => store.cardsMainAnalytics.mainAnalyticsCards);
    const {blogDataForMark, loading, blogDataForMarkCount, isFetching, currentPage} = useSelector(state => state.blog);
    const [limit] = useState(4);
    const val = useActiveButton();
    const dispatch = useDispatch();
    const { ref, inView } = useInView({
        threshold: 0.5,
    });

    const transformDate = (date) => {
        const newDate = new Date(date);

        const year = newDate.getUTCFullYear();
        const month = String(newDate.getUTCMonth() + 1).padStart(2, '0');
        const day = String(newDate.getUTCDate()).padStart(2, '0');

        return `${year}-${month}-${day}`;
    }

    useEffect(() => {
        if (inView) {
            dispatch(blogActions.setCurrentPage(currentPage + 1));
        }
    }, [inView]);

    useEffect(() => {
        if (!marks?.name) return;
        if( blogDataForMark.length == blogDataForMarkCount) return;
        dispatch(getDataForMarksBlogPage({marks: marks?.name, currentPage, limit}));
        dispatch(blogActions.toggleIsFetching(true));
    }, [currentPage, marks, limit, dispatch])


    useEffect(() => {
        val.getCardsMainAnalyticsContext();
    }, [val]);


    return (

        <div className={styles.wrapper}>
            <div className={styles.allCategories}>
                {loading && !isFetching ? (
                    <div className={styles.preloader}>
                        <Preloader />
                    </div>
                ): (
                    blogDataForMark?.map((item, i) => {
                        const isLastElement = i === blogDataForMark.length - 1;
                        return (
                            <Link to={`/polezno-investoru/${item?.slug}`} className={styles.item} key={i} ref={isLastElement ? ref : null}>
                                <div className={styles.time}>{transformDate(item?.[ "pub_date"])}</div>
                                <h2 className={styles.title}>{item?.name}</h2>
                                {/*<div className={styles.desc}>{item?.name}</div>*/}
                            </Link>
                        )
                    })
                )}
                {loading && isFetching && (
                        <div className={styles.preloader}>
                            <Preloader />
                        </div> )}
            </div>
            <div className={styles.right}>
                <CanMightBeActualAnalytics posts={filteredObject}/>
            </div>
        </div>


    );
};

export default AllCategories;
