import React from "react";
import styles from './custom-legend.module.css'
import {indicatorPredictValue, indicatorValue} from "../../utilsForFinanceTab";

export const CustomLegend = ({payload, handleLegendClick, keyBlock}) => {

    return (
        <div className={styles.legend}>
            {payload.map((entry, index) => {
                    // const isCompare = entry.value.includes('Compare')
                    return <div key={`item-${index}`} className={styles.legendItem} onClick={()=>handleLegendClick(entry.dataKey)}>
                        <span className={styles.legendSquare} style={{backgroundColor: entry.color}}></span>
                        {keyBlock !== 'predictions' ? indicatorValue(entry.value) : indicatorPredictValue(entry.value)}
                    </div>
                }
            )}
        </div>
    );
};