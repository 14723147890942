import {useCallback, useMemo, useState} from 'react';
import {useSelector} from "react-redux";
import { useMediaQuery } from 'react-responsive';
import successImg from '../../../images/subscription-succes-img.svg';
import CloseX from '../../../images/close-black-icon.svg';
import emptyCircle from '../../../images/empty-circle.svg';
import fullCircle from '../../../images/full-black-green-circle.svg';
import Stocks from '../../stocks/stock-profile/Stocks';
import { useProfile } from '../../../services/context/ProfileContext';
import { useInput } from './hookUseInput';
import Preloader from './window-pay-preloader/preloader-pay';
import PreloaderComponent from '../../../components/preloader/preloader';
import {FooterPayContent} from "./footer-pay-content";
import {FooterAuthContent} from "./footer-auth-content/footer-auth-content";
import styles from './window-pay.module.css';

const WindowPaySub = ({ setShowExteriorNotice, setShowModal, subSelect, subNameSelect, defaultOptionMonth }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 760px)' });
  const contextValue = useProfile();
  const { bind, val } = useInput('');
  const [optionMonth, setOptionMonth] = useState(defaultOptionMonth || 0);
  const [promoCodeTuggle, setPromoCodeTuggle] = useState(false);
  const [optionCard, setOptionCard] = useState(1);
  const [loading, setLoading] = useState(false);
  const [forApiPromoCode, setForApiPromoCode] = useState(false);
  const [isInputFocus, setIsInputFocus] = useState(false)

  const isAuthChecked = useSelector((store) => store.user.isAuthChecked);

  useMemo(() => {
      contextValue.receptData(subSelect);
  }, []);

  const objForApi = (id, rur, usd) => {
    const obj = {
      promo_code: val,
      product_id: id,
      price_rur: rur,
      price_usd: usd,
    };
    return obj;
  };

  const objForPayment = useCallback(() => {
    const objPayment = {
      total_price: contextValue.receptInfo[optionMonth].roubles,
      payment_type_id: 1,
      product_id: contextValue.receptInfo[optionMonth].id,
    };
    if (contextValue.totalRubles !== '') {
      objPayment.total_price = contextValue.totalRubles;
      objPayment.promo_code_id = contextValue.promoCodeData.promo_code_id;
    }

    if (contextValue.receptInfo) {
      return objPayment;
    }
  }, [forApiPromoCode, contextValue.totalRubles]);

  useMemo(() => {
    try {
      forApiPromoCode && contextValue.paymentTogglePost(objForPayment());
    } catch (e) {
    }

  }, [loading]);

  useMemo(() => {
    if (contextValue.receptInfo) {
      setForApiPromoCode(
        objForApi(
          contextValue.receptInfo[optionMonth].id,
          contextValue.receptInfo[optionMonth].roubles,
          contextValue.receptInfo[optionMonth].dollars,
        ),
      );
    }
  }, [contextValue.receptInfo, optionMonth, val]);

  useMemo(() => {

          forApiPromoCode && contextValue.promoCodeInitialApi(forApiPromoCode);

    // {forApiPromoCode && objForPayment(contextValue.receptInfo[optionMonth].id)}
  }, [promoCodeTuggle]);

  useMemo(() => {
    setPromoCodeTuggle((prev) => !prev);
    contextValue.setTotalRubles('');
    contextValue.setBonusMonth('');
    // {forApiPromoCode && objForPayment(contextValue.receptInfo[optionMonth].id)}
  }, [optionMonth]);

  useMemo(() => {
    contextValue.setTotalRubles('');
    contextValue.setBonusMonth('');
    contextValue.setPromoCodeData(false);
  }, [val]);

  // document.getElementById('parentsClick').addEventListener('click', function(event) {
  //   if (event.target.id === 'parentsClick') {
  //     // Только если событие произошло на родительском элементе
  //     setShowModal(prev => !prev);
  //   }
  // });

  const handleClick = (event) => {
    if(!isInputFocus) {
      if (event.target === event.currentTarget) {
      // Обработчик вызывается только если событие произошло на родительском элементе
      setShowModal((prev) => !prev);
    }
    }

  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      val.length !== 0 && setPromoCodeTuggle((prev) => !prev);
    }
  };

  const onChangeHandler = (e) => {
    setIsInputFocus(true)
  }

  const onBlurHandler = (e) => {
    e.preventDefault()
    setIsInputFocus(false)
  }
///
  return (
    <div className={styles.containerWrapper} onClick={handleClick} id={'parentsClick'}>
      {contextValue.receptInfo && (
        <div className={styles.container}>
          <div className={styles.headerContent}>
            <div className={styles.titleHeader}>
              {!isMobile && `Подключение подписки ${subNameSelect}`}

              {isMobile && `Подключение  ${subNameSelect}`}
            </div>
            <img alt="img" src={CloseX} style={{ cursor: 'pointer' }} onClick={handleClick} />
          </div>

          {contextValue.isReceptInfoLoading ? <div className={styles.preloader}><PreloaderComponent /></div> :
              <>
          {loading ? (
            <Preloader />
          ) : (
            <div className={styles.loadSection}>
              <div className={styles.mainBody}>
                <div className={styles.mainContentWrapper}>
                  <div className={styles.mainContent}>
                    <Stocks />
                    <div className={styles.titleBox}>Выберите период подключения</div>

                    <div className={styles.monthBoxesWrapper}>
                      {contextValue.receptInfo.map((el, index, arr) => (
                        <div
                          key={index}
                          className={
                            optionMonth === index ? styles.monthBoxActive : styles.monthBox
                          }
                          onClick={() => {
                            setOptionMonth(index);
                            objForApi(el.id, el.roubles, el.dollars);
                          }}>
                          {el.discount && (
                            <div className={styles.discount}>{`${el.discount}`}%</div>
                          )}

                          <div className={styles.topTiteleBox}>
                            <div className={styles.monthTitleWrapper}>
                              {optionMonth === index ? (
                                <img alt="img" src={fullCircle} className={styles.emptyCircleImg} />
                              ) : (
                                <img
                                  alt="img"
                                  src={emptyCircle}
                                  className={styles.emptyCircleImg}
                                />
                              )}

                              <div className={styles.monthTitle}>
                                {`${el.month}`} {!isMobile && (el.month > 1 ? 'месяцев' : 'месяц')}{' '}
                                {isMobile && `мес`}
                              </div>
                            </div>

                            <div className={styles.subTitleWrapper}>
                              <div className={styles.titleHeader}>
                                {`${el.roubles_per_month} ₽`}
                              </div>
                              <div className={isMobile ? styles.titleBoxMob : styles.titleBox}>
                                {!isMobile && `/месяц`}
                                {isMobile && `/мес`}
                              </div>
                            </div>
                          </div>

                          {el.roubles_per_month && index !== 0 && (
                            <div className={styles.subTitle}>
                              При оплате за {el.month} месяцев *Далее{' '}
                              {`${arr[0].roubles_per_month} ₽`} в месяц
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                    {optionMonth !== 0 && (
                      <div className={styles.subTitle14}>
                        *Сумма будет списываться автоматически
                      </div>
                    )}
                  </div>
                </div>
                {!isMobile && (
                  <div
                    className={
                      contextValue.promoCodeData && typeof contextValue.promoCodeData !== 'object'
                        ? styles.promoCodeContainerWarning
                        : styles.promoCodeContainer
                    }>
                    <div className={isMobile ? styles.titleBoxMob : styles.titleBox}>Промокод</div>
                    <div className={styles.inputContainerWrapper}>
                      <div className={styles.inputContainer}>
                        <div className={styles.inputWrapper}>
                          <input
                            {...bind}
                            onKeyDown={handleKeyDown}
                            placeholder="Введите промокод"
                            onFocus={onChangeHandler}
                            onBlur={onBlurHandler}
                            className={
                              contextValue.promoCodeData &&
                              typeof contextValue.promoCodeData !== 'object'
                                ? styles.inputWarning
                                : styles.input
                          }
                          />
                          {contextValue.totalRubles !== '' && (
                            <img alt="img" src={successImg} className={styles.successImg} />
                          )}
                        </div>

                        <div
                          className={
                            val.length !== 0 ? styles.inputButtonActive : styles.inputButton
                          }
                          onClick={() => {
                            val.length !== 0 && setPromoCodeTuggle((prev) => !prev);
                          }}>
                          Применить
                        </div>
                      </div>

                      {contextValue.promoCodeData &&
                        typeof contextValue.promoCodeData !== 'object' && (
                          <div className={styles.subTitle}>{contextValue.promoCodeData}</div>
                        )}
                      {contextValue.totalRubles !== '' && (
                        <div className={styles.subTitle}>Промокод применен</div>
                      )}
                    </div>
                  </div>
                )}

                {isMobile && (
                  <div
                    className={
                      contextValue.promoCodeData && typeof contextValue.promoCodeData !== 'object'
                        ? styles.promoCodeContainerWarning
                        : styles.promoCodeContainer
                    }>
                    <div className={isMobile ? styles.titleBoxMob : styles.titleBox}>Промокод</div>
                    <div className={styles.inputContainerWrapper}>
                      <div className={styles.inputContainer}>
                        <div className={styles.inputWrapper}>
                          <input
                            {...bind}
                            placeholder="Введите промокод"
                            className={
                              contextValue.promoCodeData &&
                              typeof contextValue.promoCodeData !== 'object'
                                ? styles.inputWarning
                                : styles.input
                            }
                          />
                          {contextValue.totalRubles !== '' && (
                            <img alt="img" src={successImg} className={styles.successImg} />
                          )}

                          {/* {contextValue.totalRubles === '' && val.length !== 0 && (
                            <div
                              className={styles.inputButtonMob}
                              onClick={() => {
                                val.length !== 0 && setPromoCodeTuggle((prev) => !prev);
                              }}>
                              Применить
                            </div>
                          )} */}
                        </div>
                      </div>

                      {contextValue.promoCodeData &&
                        typeof contextValue.promoCodeData !== 'object' && (
                          <div className={styles.subTitle}>{contextValue.promoCodeData}</div>
                        )}
                      {contextValue.totalRubles !== '' && (
                        <div className={styles.subTitle}>Промокод применен</div>
                      )}
                    </div>
                  </div>
                )}

                {/*
          <div className={styles.priceInfoWrapper}>
          */}
                <div
                  className={
                    (!isMobile && styles.priceInfoWrapper) ||
                    (isMobile &&
                      (contextValue.promoCodeData && typeof contextValue.promoCodeData !== 'object'
                        ? styles.priceInfoWrapperMobWarning
                        : styles.priceInfoWrapperMob))
                  }>
                  <div className={styles.titleBox}>Стоимость</div>
                  <div className={styles.priceInfo}>
                    <div style={{ 'marginBottom': '4px' }} className={styles.priceInfoTxtWrapper}>
                      <div className={styles.monthTitle}>Подписка {subNameSelect}</div>
                      <div className={styles.monthTitle}>
                        {/*{contextValue.receptInfo && optionMonth && `${contextValue.receptInfo[optionMonth].roubles} ₽`}*/}
                        {contextValue.receptInfo && `${contextValue.receptInfo[optionMonth].roubles} ₽`}
                      </div>
                    </div>
                    <div className={styles.priceInfoTxtWrapper}>
                      <div className={styles.graySubTitle}>
                        {optionMonth === 0 && `${contextValue.receptInfo[optionMonth].month} месяц`}
                        {optionMonth > 0 && `${contextValue.receptInfo[optionMonth].month} месяцев`}
                      </div>

                      <div className={styles.graySubTitle}>
                        {/*{contextValue.receptInfo && optionMonth && `${contextValue.receptInfo[optionMonth].roubles_per_month} ₽`} / месяц*/}
                        {contextValue.receptInfo && `${contextValue.receptInfo[optionMonth].roubles_per_month} ₽`} / месяц
                      </div>
                    </div>

                    {contextValue.bonusMonth === '' && contextValue.totalRubles !== '' && (
                      <div
                        style={{ 'paddingBottom': '13px' }}
                        className={styles.priceInfoTxtWrapper}>
                        <div className={styles.monthTitle}>Скидка по промокоду</div>
                        <div className={styles.monthTitle}>
                          {contextValue.totalRubles !== '' &&
                            contextValue.receptInfo[optionMonth].roubles -
                              contextValue.totalRubles}{' '}
                          ₽
                        </div>
                      </div>
                    )}

                    {contextValue.bonusMonth !== '' && (
                      <div
                        style={{ 'paddingBottom': '13px' }}
                        className={styles.priceInfoTxtWrapper}>
                        <div className={styles.monthTitle}>
                          {contextValue.bonusMonth === 1 &&
                            `+ ${contextValue.bonusMonth} месяц по промокоду`}
                          {contextValue.bonusMonth > 1 &&
                            `+ ${contextValue.bonusMonth} месяца по промокоду`}
                        </div>
                        <div className={styles.monthTitle}>0 ₽</div>
                      </div>
                    )}
                  </div>
                  <div className={styles.priceInfoTxtWrapper}>
                    {contextValue?.promoCodeData?.promo_code_id === 12 ? (
                      <div className={styles.titleHeader}>Итого за 10 дней</div>
                    ) : contextValue.bonusMonth === '' ? (
                      <div className={styles.titleHeader}>
                        {optionMonth === 0 &&
                          `Итого за ${contextValue.receptInfo[optionMonth].month} месяц`}

                        {optionMonth > 0 &&
                          `Итого за ${contextValue.receptInfo[optionMonth].month} месяцев`}
                      </div>
                    ) : (
                      <div className={styles.titleHeader}>
                        {contextValue.receptInfo && optionMonth &&
                        <>
                        {contextValue.receptInfo[optionMonth].month + contextValue.bonusMonth < 5
                          ? `Итого за ${
                              contextValue.receptInfo[optionMonth].month + contextValue.bonusMonth
                            } месяцa`
                          : `Итого за ${
                              contextValue.receptInfo[optionMonth].month + contextValue.bonusMonth
                            } месяцев`}
                        </>}
                      </div>
                    )}

                    {/*{contextValue.receptInfo && optionMonth &&*/}
                    {contextValue.receptInfo &&
                    <>
                    {contextValue.totalRubles === contextValue.receptInfo[optionMonth].roubles ||
                    contextValue.totalRubles === '' ? (
                      <div className={styles.titleHeader}>
                        {`${contextValue.receptInfo[optionMonth].roubles} ₽`}
                      </div>
                    ) : (
                      <div
                        className={
                          (!isMobile && styles.titleHeader) ||
                          (isMobile && styles.titleHeaderColumnMob)
                        }>
                        {contextValue.totalRubles} ₽
                        <div className={styles.titleHeaderCross}>
                          {`${contextValue.receptInfo[optionMonth].roubles} ₽`}
                        </div>
                      </div>
                    )}</>}
                  </div>
                </div>

                <div className={styles.methodPayWrapper}>
                  <div className={styles.titleBox}>Способ оплаты</div>
                  <div className={styles.cardSelectWrapper}>
                    <div
                      onClick={() => {
                        setOptionCard(1);
                      }}
                      className={
                        optionCard === 1 ? styles.cardSelectBoxActive : styles.cardSelectBox
                      }>
                      <div className={styles.cardTxtWrapper}>
                        {optionCard === 1 ? (
                          <img
                            alt="fullCircle"
                            src={fullCircle}
                            className={styles.emptyCircleImg}
                          />
                        ) : (
                          <img
                            alt="emptyCircle"
                            src={emptyCircle}
                            className={styles.emptyCircleImg}
                          />
                        )}
                        <div className={styles.monthTitle}>Российской картой</div>
                      </div>
                    </div>

                    <div
                      onClick={() => {
                        setShowExteriorNotice(true);
                      }}
                      className={
                        optionCard === 2 ? styles.cardSelectBoxActive : styles.cardSelectBox
                      }>
                      <div className={styles.cardTxtWrapper}>
                        {optionCard === 2 ? (
                          <img
                            alt="fullCircle"
                            src={fullCircle}
                            className={styles.emptyCircleImg}
                          />
                        ) : (
                          <img
                            alt="emptyCircle"
                            src={emptyCircle}
                            className={styles.emptyCircleImg}
                          />
                        )}
                        <div className={styles.monthTitle}>Зарубежной картой</div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.supportWrapper}>
                    <div className={styles.monthTitle}>
                      Есть вопросы? Обратитесь в{' '}
                      <a href={'https://t.me/ie_contact'} target="_blank">
                        техподдержку
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {isAuthChecked && <FooterPayContent loading={loading} setLoading={setLoading} optionMonth={optionMonth}/>}
              {!isAuthChecked && <FooterAuthContent optionMonth={optionMonth} subSelect={subSelect} subNameSelect={subNameSelect} />}
            </div>
          )}
              </>}
        </div>
      )}
    </div>
  );
};
export default WindowPaySub;
