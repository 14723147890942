import React from "react";
import {getBackgroundColor} from "../utilsForMarketMap";
import styles from './custom-tooltip.module.css'
import {getMultipliersColor} from "../utilsForMultipliersMap";

export const CustomMultipliersTooltip = ({active, payload, selectedMultiplier }) => {

  if (active && payload && payload.length) {
    const data = payload[0].payload;
    const dataWithoutHoveredItem = data.root.children.filter(el => el.name !== data.name)

    return (
      <div className={styles.customTooltip}>
        <p className={styles.title}>{data.root.name}</p>

        <div className={styles.hoveredItem}
             style={{
               backgroundColor: getMultipliersColor(selectedMultiplier.id, data?.multipliers?.[selectedMultiplier.id]),
               borderRadius: dataWithoutHoveredItem.length ? '' : '8px'
             }}>
          <div className={styles.hoveredItemInfo}>
            <p>{data.ticker}</p>
            <div>
              <p>{data?.multipliers?.[selectedMultiplier.id]}</p>
            </div>
          </div>
          <div className={styles.fullName}>
            {data.name}
          </div>
        </div>

        {dataWithoutHoveredItem.map((el, index) => {
          return <div key={index} className={styles.item}>
            <p>{el.ticker}</p>
            <div>
              <p>{el?.multipliers?.[selectedMultiplier.id]}</p>
            </div>
          </div>
        })}

      </div>
    );
  }

  return null;
};