export function getMultipliersColor(selectedMultiplierId, value) {
  const colorIdToColor = {
    0: "#A5A6A9",
    1: '#2ACF76',
    2: '#229F5C',
    3: '#0C3720',
    4: '#CF373A',
    5: "#681517",
  };

  const multiplierToColor = {
    "pe_2024": () => {
      if (value < 0) return 5;
      if (value > 15) return 4;
      if (value >= 10 && value <= 15) return 3;
      if (value >= 5 && value < 10) return 2;
      return 1;
    },
    "ebitda_2024": () => {
      if (value > 14) return 5;
      if (value >= 10 && value <= 14) return 4;
      if (value >= 7 && value < 10) return 3;
      if (value >= 4 && value < 7) return 2;
      return 1;
    },
    "dividends_2024": () => {
      if (value > 15) return 1;
      if (value >= 11 && value <= 15) return 2;
      if (value >= 7 && value < 11) return 3;
      if (value >= 0 && value < 7) return 2;
      return 1;
    },
    "net_debt_ebitda_2024": () => {
      if (value < 0) return 1;
      if (value >= 0 && value < 1) return 2;
      if (value >= 1 && value <= 2.5) return 3;
      if (value > 2.5 && value <= 4) return 4;
      return 5;
    },
    "free_float": () => {
      if (value > 45) return 1;
      if (value >= 30 && value <= 45) return 2;
      if (value >= 20 && value < 30) return 3;
      if (value >= 10 && value < 20) return 4;
      return 5;
    },
    "net_debt": () => {
      return value < 0 ? 3 : 5;
    },
    "profit_growth_2024_2023": () => {
      if (value >= 20) return 1;
      if (value >= 10 && value < 20) return 2;
      if (value >= 0 && value < 10) return 3;
      if (value >= -15 && value < 0) return 4;
      return 5;
    },
    "ebitda_growth_2024_2023": () => {
      if (value >= 20) return 1;
      if (value >= 10 && value < 20) return 2;
      if (value >= 0 && value < 10) return 3;
      if (value >= -15 && value < 0) return 4;
      return 5;
    },
    "historical_pe": () => {
      if (value < 0) return 5;
      if (value > 15) return 4;
      if (value >= 10 && value <= 15) return 3;
      if (value >= 5 && value < 10) return 2;
      return 1;
    },
    "historical_ev_ebitda": () => {
      if (value > 14) return 5;
      if (value >= 10 && value <= 14) return 4;
      if (value >= 7 && value < 10) return 3;
      if (value >= 4 && value < 7) return 2;
      return 1;
    },
    "discount_to_avg_ev_ebitda_2024": () => {
      if (value > 20) return 5;
      if (value >= 10 && value <= 20) return 4;
      if (value >= 0 && value < 10) return 3;
      if (value >= -10 && value < 0) return 2;
      return 1;
    },
    "discount_to_avg_pe_2024": () => {
      if (value > 20) return 5;
      if (value >= 10 && value <= 20) return 4;
      if (value >= 0 && value < 10) return 3;
      if (value >= -10 && value < 0) return 2;
      return 1;
    }
  };

  if(value === "N/A" || !value) return colorIdToColor[0];

  const colorId = multiplierToColor[selectedMultiplierId] ? multiplierToColor[selectedMultiplierId]() : 0;
  return colorIdToColor[colorId];
}
