import {v1} from "uuid";

// Вычисляет цвет квадратика компании в зависимости от изменения цены
export const getBackgroundColor = (priceChange) => {
    if (priceChange < -40) return '#521213'
    if (priceChange < -40 && priceChange >= -45) return '#681517'
    if (priceChange < -35 && priceChange >= -40) return '#701819'
    if (priceChange < -30 && priceChange >= -35) return '#771B1D'
    if (priceChange < -20 && priceChange >= -30) return '#861E20'
    if (priceChange < -20 && priceChange >= -25) return '#882123'
    if (priceChange < -15 && priceChange >= -20) return '#8C2224'
    if (priceChange < -10 && priceChange >= -15) return '#A3272A'
    if (priceChange < -5 && priceChange >= -10) return '#CF373A'
    if (priceChange < 0 && priceChange >= -5) return '#FF464A'
    if (priceChange === 0) return '#A5A6A9'
    if (priceChange > 0 && priceChange <= 5) return '#0C3720'
    if (priceChange > 5 && priceChange <= 10) return '#0C3E23'
    if (priceChange > 10 && priceChange <= 15) return '#145934'
    if (priceChange > 15 && priceChange <= 20) return '#156138'
    if (priceChange > 20 && priceChange <= 25) return '#3A614F'
    if (priceChange > 25 && priceChange <= 30) return '#35764E'
    if (priceChange > 30 && priceChange <= 35) return '#06813E'
    if (priceChange > 35 && priceChange <= 40) return '#229F5C'
    if (priceChange > 40) return '#2ACF76'
}

// Добавляет символ символ мягкого переноса строки для заголовков секторов
export const getSectorNameWithTransposition = (name) => {
    if (name === 'Энергетический') return `Энер\u00ADге\u00ADти\u00ADче\u00ADский`
    if (name === 'Финансовый') return `Фи\u00ADнан\u00ADсо\u00ADвый`
    if (name === 'Добывающий') return `До\u00ADбы\u00ADваю\u00ADщий`
    if (name === 'Коммуникации и развлечения') return `Ком\u00ADму\u00ADни\u00ADка\u00ADции и раз\u00ADвлеч\u00ADен\u00ADия`
    if (name === 'REIT') return `RE\u00ADIT`
    if (name === 'Потребительский защитный') return `По\u00ADтре\u00ADби\u00ADте\u00ADль\u00ADский за\u00ADщит\u00ADный`
    if (name === 'Коммунальный') return `Ком\u00ADму\u00ADна\u00ADль\u00ADный`
    if (name === 'Здравоохранение') return `Здра\u00ADво\u00ADох\u00ADра\u00ADне\u00ADние`
    if (name === 'Промышленный') return `Про\u00ADмы\u00ADшлен\u00ADный`
    if (name === 'Потребительский цикличный') return `По\u00ADтре\u00ADби\u00ADте\u00ADль\u00ADский ци\u00ADклич\u00ADный`
}

// Форматирует название сектора в число для гет (квери) параметров
export const formatWordValue = (value) => {
    switch (value) {
        case "Энергетический":
            return 24

        case "Финансовый":
            return 22

        case "Добывающий":
            return 15

        case "Коммуникации и развлечения":
            return 18

        case "REIT":
            return 14

        case "IT":
            return 26

        case "ETF":
            return 25

        case "Потребительский защитный":
            return 20

        case "Коммунальный":
            return 17

        case "Здравоохранение":
            return 16

        case "Промышленный":
            return 21

        case "Потребительский цикличный":
            return 19

        default:
            return value
    }
}

// Форматирует число сектора из гет (квери) параметров в название для фильтра сектора
export const formatNumberValue = (value) => {
    switch (value) {
        case '24':
            return "Энергетический";

        case '22':
            return "Финансовый";

        case '15':
            return "Добывающий";

        case '18':
            return "Коммуникации и развлечения";

        case '14':
            return "REIT";

        case '26':
            return "IT";

        case '25':
            return "ETF";

        case '20':
            return "Потребительский защитный";

        case '17':
            return "Коммунальный";

        case '16':
            return "Здравоохранение";

        case '21':
            return "Промышленный";

        case '19':
            return "Потребительский цикличный";

        default:
            return value;
    }
}

// Расчёт размера шрифта для тикеров в зависимости от размера экрана, квадратика компании и кол-ва компаний в секторе
export const conditionFontSizeTicker = (value, screenWidth, childrenLength, index) => {
    if (childrenLength < 9 && index === 0) {
        return '15px'
    } else {
        if (screenWidth > 940) {
            if (value >= 10) return '28px'
            if (value < 10 && value >= 6) return '17px'
            if (value === 5) return '10px'
            if (value < 5) return '8px'
        } else {
            if (value >= 10) return '15px'
            if (value < 10 && value >= 6) return '12px'
            if (value === 5) return '9px'
            if (value < 5) return '6px'
        }
    }
}

// Расчёт line-height для тикеров в зависимости от размера экрана, квадратика компании и кол-ва компаний в секторе
export const conditionLineHeightTicker = (value, screenWidth, childrenLength, index) => {
    if (childrenLength < 9 && index === 0) {
        return '15px'
    } else {
        if (screenWidth > 940) {
            if (value >= 10) return '33px'
            if (value < 10 && value >= 6) return '19px'
            if (value === 5) return '11px'
            if (value < 5) return '9px'
        } else {
            if (value >= 10) return '17px'
            if (value < 10 && value >= 6) return '14px'
            if (value === 5) return '9px'
            if (value < 5) return '7px'
        }
    }
}

// Расчёт размера шрифта для изменения цены в зависимости от размера экрана, квадратика компании и кол-ва компаний в секторе
export const conditionFontSizePercents = (value, screenWidth, childrenLength, index) => {
    if (childrenLength < 9 && index === 0) {
        return '15px'
    } else {
    if (screenWidth > 940) {
        if (value >= 10) return '18px'
        if (value < 10 && value >= 6) return '10px'
        if (value <= 5) return '6px'
    } else {
        if (value >= 10) return '12px'
        if (value < 10 && value >= 6) return '8px'
        if (value <= 5) return '6px'
    }
    }
}

// Расчёт line-height для изменения цены в зависимости от размера экрана, квадратика компании и кол-ва компаний в секторе
export const conditionLineHeightPercents = (value, screenWidth, childrenLength, index) => {
    if (childrenLength < 9 && index === 0) {
        return '15px'
    } else {
    if (screenWidth > 940) {
        if (value === 10) return '20px'
        if (value < 10 && value >= 6) return '11px'
        if (value <= 5) return '7px'
    } else {
        if (value === 10) return '12px'
        if (value < 10 && value >= 6) return '8px'
        if (value <= 5) return '5px'
    }
    }
}

// Расчёт размера шрифта для заголовка сектора в зависимости от размера экрана и квадратика компании
export const conditionFontSizeHeader = (width, height, screenWidth) => {
    if (screenWidth > 940) {
        if (width <= 350 || height <= 350) {
            return '25px'
        } else if (width <= 50 || height <= 50) {
            return '15px'
        } else {
            return '35px'
        }
    } else {
        if (width <= 350 || height <= 350) {
            return '15px'
        } else if (width <= 50 || height <= 50) {
            return '10px'
        } else {
            return '25px'
        }
    }
}

// Расчёт размера квадратиков компании в зависимости от приоритета
export const conditionSize = (priority) => {
    if (priority === 0) return 2
    if (priority === 1) return 30
    if (priority === 2) return 20
    if (priority === 3) return 15
    if (priority === 4) return 10
    if (priority === 5) return 7
    if (priority === 6) return 5
    if (priority === 7) return 4
    if (priority === 8) return 3
    if (priority === 9) return 2
    if (priority === 10) return 2
}

// Трансформирует данные с бэка в формат, подходящий для treemap от recharts
export const transformData = (data) => {
    return data.filter(sector => sector.companies && sector.companies.length > 0).map(sector => ({
        name: sector.sector_name,
        children: sector.companies.map(company => ({
            name: company.company_name,
            ticker: company.company_ticker,
            size: conditionSize(company.company_moex_priority),
            price: company.price === null ? 0 : company.price,
            priceChange: company.price_change ? company.price_change : 0,
            sectorName: sector.sector_name,
        }))
    }));
};

export const transformMultipliersData = (data) => {
    return data.filter(sector => sector.companies && sector.companies.length > 0).map(sector => ({
        name: sector.sector_name,
        children: sector.companies.map(company => ({
            name: company.company_name,
            ticker: company.company_ticker,
            size: conditionSize(company.company_moex_priority),
            multipliers: company.multipliers,
            sectorName: sector.sector_name,
            includeImoex: company.include_imoex === "true",
            ieChoise: company.ie_choice === "true",
            moexList: company.moex_list,
            id: v1(),
        }))
    }));
};

// Расчёт высоты графика в зависимости от кол-ва компаний в секторе
export const heightForTreemap = (data) => {
    if (data.length === 1) {
        if (data[0].children.length < 10) {
            return 250
        } else {
            return 528
        }
    } else if (data.length > 1 && data.length < 10) {
        return 1000
    } else {
        return 1500
    }
}

// Расчёт высоты графика в зависимости от кол-ва компаний в секторе
export const heightForMobileTreemap = (data) => {
    if (data.children.length < 10) {
        return 150
    } else {
        return 350
    }
}