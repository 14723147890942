import {NavLink} from 'react-router-dom';
import styles from './news-card.module.css';

export const NewsCard = ({item, oneEl}) => {

    return (
        <div className={oneEl.length === 1 ? styles.justOneCard : styles.card}>
            <div className={styles.wrapper}>
                <NavLink to={`/analytics-and-news/${item.slug}`} className={styles.link}>
                    <div className={styles.container}>
                        <div className={styles.box}>
                            <div className={styles.textBox}>
                                <div className={styles.headBlock}>
                                    <span className={styles.data}>{item.pub_date_formated}</span>
                                    <ul className={styles.ul}>
                                        {item.analysis && (<li className={styles.li}>

                                                <span className={styles.sectorTextParse} key={item.name}>
                Разбор
              </span>

                                        </li>)}
                                        {item.subscription ? (
                                            <span
                                                className={styles.subscription}>{`С подпиской ${item.subscription}`}</span>
                                        ) : null}
                                        <li className={styles.li}>
                                            {item.country_tag.length === 1
                                                ? item.country_tag.map((item) => (
                                                    <span className={styles.countryText} key={item.name}>
                    {item.name}
                  </span>
                                                ))
                                                : null}
                                        </li>
                                        <li className={styles.li}>
                                            {item.sub_sector.map(
                                                (item) =>
                                                    item.name.length > 0 && (
                                                        <span className={styles.sectorText} key={item.name}>
                    {item.name}
                  </span>
                                                    ),
                                            )}
                                        </li>
                                        {item.bonds && (
                                            <li className={styles.li}>
              <span className={styles.sectorText} key={item.name}>
                Облигации
              </span>
                                            </li>
                                        )}
                                    </ul>
                                </div>

                                <h3 className={styles.h3}>{item.name}</h3>
                            </div>
                        </div>
                    </div>
                </NavLink>
            </div>
        </div>
    );
}