import {useCallback, useMemo} from 'react';
import {NavLink} from 'react-router-dom';
import {useSelector} from "react-redux";
import logo from "../../images/logo-logo.svg";
import styles from './can-might-be-interesting.module.css';

function CanMightBeInteresting({posts, numberOfHiddenPost, blogData}) {
  const randomIndex = Math.floor(Math.random() * ((blogData?.length - 1) - 1) + 1);
  const anRecom = useCallback(() => {

    if (!!window.ym) {
      window.ym(96742836, 'reachGoal', 'an-recom');
    }
  }, []);

  const handleBannerLinkClick = useCallback(() => {
    window.ym(96742836, 'reachGoal', 'click-promo-picture-an');
  }, []);

  const isSubscribed = useMemo(() => {
    const subscription = JSON.parse(localStorage.getItem('subscription'));
    return subscription?.length > 0;
  }, []);

  const transformDate = (date) => {
    const months = {
      0: 'января',
      1: 'февраля',
      2: 'марта',
      3: 'апреля',
      4: 'мая',
      5: 'июня',
      6: 'июля',
      7: 'августа',
      8: 'сентября',
      9: 'октября',
      10: 'ноября',
      11: 'декабря'
    };

    const newDate = new Date(date);

    const day = newDate.getUTCDate();
    const month = months[newDate.getUTCMonth()];
    const year = newDate.getUTCFullYear();
    const time = newDate.toLocaleTimeString('ru-RU', {
      hour: '2-digit',
      minute: '2-digit',
    });

    return `${day} ${month} ${year} в ${time}`;
  }

  return (
    <div className={styles.canMightBeInteresting}>
      <h3 className={styles.cmbiTitle}>Может быть интересно</h3>
      {!isSubscribed && (
        <div className={styles.investBanner}>
          <div className={styles.investBannerHeader}>
            <img className={styles.investBannerHeaderLogo} src={logo} alt="logo"/>
            <p className={styles.investBannerHeaderText}>Invest-Era</p>
          </div>
          <p className={styles.investBannerTagline}>Мы экономим вам время</p>
          <ul className={styles.investBannerList}>
            <li className={styles.investBannerListItem}>
              <p>научим зарабатывать на фондовом рынке</p>
            </li>
            <li className={styles.investBannerListItem}>
              <p>наши портфели обгоняют индексы</p>
            </li>
          </ul>
          <NavLink to="/#promo" onClick={handleBannerLinkClick}>
            <button className={styles.investBannerButton}>Пробная подписка</button>
          </NavLink>
        </div>
      )}

      {posts.filter((_, i) => i < (numberOfHiddenPost ? numberOfHiddenPost : 100)).map((element) => (
        <NavLink
          onClick={anRecom}
          to={element.hasOwnProperty('special_mark_data') ? `/polezno-investoru/${element.slug}` : `/analytics-and-news/${element.slug}`}
          className={styles.linkBox}
          key={element.name}>
          <div className={styles.canMightBeInterestingCard}>
            <div style={element.subscription ? {} : {display: 'none'}} className={styles.cardBox}>
              {element.subscription === null ? null : (
                <span className={styles.subscription}>{`С подпиской ${element.subscription}`}</span>
              )}
            </div>
            <span className={styles.titleBox}>{element.name}</span>
            <span className={styles.pubDateBox}>
                            {element.url ? element.pub_date_formated : element.pub_date_formated}
                        </span>
          </div>
        </NavLink>
      ))}
      {posts?.length !== 0 && blogData && blogData[randomIndex] && (
        <NavLink
          onClick={anRecom}
          to={`/polezno-investoru/${blogData[randomIndex]['slug']}`}
          className={styles.linkBox}
          key={blogData[randomIndex].name}>
          <div className={styles.canMightBeInterestingCard}>
            <span className={styles.titleBox}>{blogData[randomIndex].name}</span>
            <span className={styles.pubDateBox}>
                            {transformDate(blogData[randomIndex]['pub_date'])}
                        </span>
          </div>
        </NavLink>
      )}
    </div>
  );
}

export default CanMightBeInteresting;
