import {NavLink, useLocation} from "react-router-dom";
import premiumCrown from '../../../../images/premium-crown-dark.svg'
import styles from './our-products-for-about-card.module.css'
import {useCallback} from "react";

export const OurProductsForAboutCard = () => {
  const handleProductClick = useCallback(() => {
    if(window.ym) {
      window.ym(96742836, 'reachGoal', 'click-our-products-an');
    }
  }, []);

  const handlePromoClick = useCallback(() => {
    if(window.ym) {
      window.ym(96742836, 'reachGoal', 'click-10rub-subs-an');
    }

  }, [])

  return (
    <div className={styles.productsContainer}>
      <h3 className={styles.productsHeader}>Наши продукты</h3>

      <div className={styles.productsWrapper}>

        <div className={styles.productContainer}>
          <NavLink to="/#tariffs" onClick={handleProductClick}>
            <div className={styles.productInfo}>
              <h4 className={styles.productHeader}>Unity</h4>
              <p className={styles.productDescription}>Кому подойдет – хотите самостоятельно научиться инвестировать</p>
            </div>
          </NavLink>
        </div>


        <div className={styles.productContainer}>
          <NavLink to="/#tariffs" onClick={handleProductClick}>
            <div className={styles.productInfo}>
              <h4 className={styles.productHeader}>Ideas</h4>
              <p className={styles.productDescription}>Хотите следовать сделкам и получить результат</p>
            </div>
          </NavLink>
        </div>

        <div className={styles.productContainer}>
          <NavLink to="/#tariffs" onClick={handleProductClick}>
            <div className={styles.productInfo}>
              <h4 className={styles.productHeader}>
                Premium
                <img src={premiumCrown} alt="premium icon" width={24} height={22}/>
              </h4>
              <p className={styles.productDescription}>Премиальный сервис, индивидуальный портфель,
                личный&nbsp;менеджер</p>
            </div>
          </NavLink>
        </div>
      </div>
      <NavLink to="/#promo" onClick={handlePromoClick}>
        <button className={styles.subscribeButton}>Оформить подписку от 10 рублей</button>
      </NavLink>
    </div>
  );
};