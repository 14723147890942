import {useEffect, useRef, useState} from "react";
import {select} from "d3";
import {zoom as d3Zoom} from "d3-zoom";

export function useZoom(loading, data, screenWidth) {
  const svgRef = useRef(null);
  const zoomRef = useRef(null);
  const [currentZoom, setCurrentZoom] = useState(1);

  useEffect(() => {
    if (!loading && data.length > 0 && screenWidth > 1440) {
      const svg = select(svgRef.current);
      const container = svg.node().parentNode;
      const {height} = container.getBoundingClientRect();

      const zoomBehavior = d3Zoom()
        .scaleExtent([1, 2.5])
        .translateExtent([[0, 0], [svgRef.current.clientWidth, height]])
        .on('zoom', (event) => {

          const {transform} = event;
          setCurrentZoom(transform.k);
          const tWidth = svgRef.current.clientWidth * transform.k;
          const tHeight = height * transform.k;

          const minX = Math.min(0, svgRef.current.clientWidth - tWidth);
          const maxX = 0;
          const x = Math.min(Math.max(transform.x, minX), maxX);

          const minY = Math.min(0, height - tHeight);
          const maxY = 0;
          const y = Math.min(Math.max(transform.y, minY), maxY);

          svg.select('g')
            .attr('transform', `translate(${x},${y}) scale(${transform.k})`);

        });

      zoomRef.current = zoomBehavior;
      svg.call(zoomBehavior);
    }

  }, [loading, data, screenWidth]);

  return {svgRef, currentZoom}
}