import styles from "./company-item.module.css";
import React from "react";

export const ChartPopup = ({snowflake}) => {
  const [value, future, past, health, dividends] = snowflake;
  return (
    <div className={styles.chartPopup}>
      <div>Прошлые показатели</div>
      <div>{past}/5</div>
      <div>Финансовое состояние</div>
      <div>{health}/5</div>
      <div>Дивиденды</div>
      <div>{dividends}/5</div>
      <div>Оценка стоимости</div>
      <div>{value}/5</div>
      <div>Рост</div>
      <div>{future}/5</div>
    </div>
  )
};