import React, {useState, useEffect} from 'react';
import {Link, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {blogActions, getNavigateBlogPage} from "../../../../../services/reducers/blog-slice";

import styles from './blog-navigate.module.css'


const BlogNavigate = ({setActiveTab, activeTab}) => {
    const {sub} = useParams();
    const {blogDataForNavigate} = useSelector(state => state.blog);
    const dispatch = useDispatch();

    const getActiveTab = (active) => {
        setActiveTab({
            name: active?.name,
            slug: active?.slug
        });
        dispatch(blogActions.clearState());
    }

    const pathAll = {
        name: 'Все',
        slug: 'all',
    }


    useEffect(() => {
        dispatch(getNavigateBlogPage());
    }, [dispatch]);

    return (
        <div className={styles.blogLis}>
            {blogDataForNavigate?.['special_marks']?.length === 0 ? null : (
                <>
                    <button
                        onClick={() => getActiveTab(pathAll)}
                        className={`${styles.blogLink} ${activeTab?.slug === 'all' ? styles.activeItem : ''}`}
                    >
                        Все
                    </button>
                    {blogDataForNavigate?.['special_marks']?.map((item) => {
                        const activeClass = item?.slug === activeTab?.slug ? styles.activeItem : '';
                        return (
                            <button
                                key={item?.id}
                                className={`${styles.blogLink} ${activeClass}`}
                                onClick={() => getActiveTab(item)}
                            >
                                {item?.name}
                            </button>
                        )
                    })}
                </>
            )
            }
        </div>
    );
};

export default BlogNavigate;
