import React, {useEffect, useRef, useState} from 'react';
import {useSelector} from "react-redux";
import {NavLink, useNavigate} from "react-router-dom";
import 'swiper/css';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Autoplay, EffectCoverflow, Scrollbar} from 'swiper/modules';
import ArrowRight from '../../../../../images/product-swiper-arrow-black-right.svg';
import ArrowLeft from '../../../../../images/product-swiper-arrow-black-left.svg';
import ArrowLeftGrey from '../../../../../images/Arrow-left-grey.svg';
import ArrowRightGrey from '../../../../../images/Arrow-right-grey.svg';
import ScreenerTabAnalysis from "../screener-tab-analysis/screener-tab-analysis";
import { AnalyticsForScreener, NewsForScreenerForScreener} from "../../../../../utils/api";
import Preloader from "../../../../preloader/preloader";
import styles from './screener-tab-information.module.css';
import {useResize} from "../../../../../services/hooks/useResize";
// import GraphUSA from '../../../../../images/graphUSA.png';
// import GraphUSA1 from '../../../../../images/graphsUSA1.png';

const ScreenerTabInformation = () => {
    const {information} = useSelector(state => state.screener);
    // const swiperRef = useRef(null);
    const swiperRefNews = useRef(null);
    const navigate = useNavigate()
    const swiperRefAnalytics = useRef(null);
    const swiperRefCompany = useRef(null);
    const screenWidth = useResize()
    const [sliderPositionNews, setSliderPositionNews] = useState('start');
    const [sliderPositionAnalytics, setSliderPositionAnalytics] = useState('start');
    const [sliderPositionCompany, setSliderPositionCompany] = useState('start');
    const [isLoadingAnalyticsData, setIsLoadingAnalyticsData] = useState(false)
    const [isLoadingNewsData, setIsLoadingNewsData] = useState(false)
    const [newsData, setNewsData] = useState([])
    const [analyticsData, setAnalyticsData] = useState([])
    // const [sliderPosition, setSliderPosition] = useState('start'); // 'start', 'center', 'end'

    const onClickNavigate = (slug) => {
        navigate(`/analytics-and-news/${slug}`)
    }

    const onClickNavigateToAN = (route, companyId) => {
        navigate(`/analytics-and-news/?category=${route}&company=${companyId}`)
    }

/*    const handleSwiper = (swiper) => {
        setSliderPosition(swiper.activeIndex < 1 ? 'start' : swiper.activeIndex >= swiper.slides.length - 4.5 ? 'end' : 'center');
    };*/

    const handleSwiperNews = (swiper) => {
        setSliderPositionNews(swiper.activeIndex < 1 ? 'start' : swiper.activeIndex >= swiper.slides.length - 4.5 ? 'end' : 'center');
    };

    const handleSwiperAnalytics = (swiper) => {
        setSliderPositionAnalytics(swiper.activeIndex < 1 ? 'start' : swiper.activeIndex >= swiper.slides.length - 4.5 ? 'end' : 'center');
    };

    const handleSwiperCompany = (swiper) => {
        setSliderPositionCompany(swiper.activeIndex < 1 ? 'start' : swiper.activeIndex >= swiper.slides.length - 4.5 ? 'end' : 'center');
    };

    const trimUrl = (url) => {
        if (url) {
            // Удаляем "http://" или "https://" в начале строки
            let trimmedUrl = url.replace(/^https?:\/\//, '');

            // Удаляем "/" в конце строки, если он есть
            trimmedUrl = trimmedUrl.replace(/\/$/, '');

            return trimmedUrl;
        } else {
            return url
        }
    };

    useEffect(() => {
        setIsLoadingAnalyticsData(true)
        setIsLoadingNewsData(true)
        if (information && information.main_info) {
            NewsForScreenerForScreener(information.main_info.company_id).then((res) => {
                setNewsData(res.results)
                setIsLoadingNewsData(false)
            })
            AnalyticsForScreener(information.main_info.company_id).then((res) => {
                setAnalyticsData(res.results)
                setIsLoadingAnalyticsData(false)
            })
        }
    }, [information])

    return (
        <>
            <div className={styles.wrapper}>
                <div className={styles.left}>
                    <h2 className={styles.title}>О компании</h2>

                    <div className={styles.companyInfo}>
                        <div className={styles.companyInfoDesc}>
                            <div className={styles.companyInfoDescUp}>CEO</div>
                            <div className={styles.companyInfoDescDown}>
                                {information?.['meta_info']?.ceo}
                            </div>
                        </div>
                        <div className={styles.companySite}>
                            <div className={styles.companySiteTitle}>Сайт</div>
                            <a className={styles.companySiteLink} href={information?.['meta_info']?.site} target={'_blank'}>
                                {trimUrl(information?.['meta_info']?.site)}
                            </a>
                        </div>
                    </div>

                    <div className={styles.desc}>
                        {information?.['meta_info']?.description}
                    </div>
                </div>
            </div>

            {/* {information?.main_info && <ScreenerTabAnalysis information={information}/>} */}

            {/*      <div className={`${styles.wrapper} ${styles.slider}`}>
        <div className={styles.sliderHeader}>
          <h2 className={styles.title}>Анализ</h2>
          <div className={styles.arrowNavigate}>
            <button
                className={`${styles.btnSwiper}`}
                onClick={() => swiperRef.current.slidePrev()}>
              <img src={sliderPosition === 'start' ? ArrowLeftGrey : ArrowLeft} alt="arrow"/>
            </button>
            <button
                className={styles.btnSwiper}
                onClick={() => swiperRef.current.slideNext()}>
              <img src={sliderPosition === 'end' ? ArrowRightGrey : ArrowRight} alt="arrow"/>
            </button>
          </div>
        </div>
        <div className={styles.swiperWrapper}>
          <Swiper
              onSwiper={(swiper) => {
                swiperRef.current = swiper;
                handleSwiper(swiper)
              }}
              onSlideChange={(swiper) => handleSwiper(swiper)}
              spaceBetween={20}
              slidesPerView={2.5}
              modules={[Autoplay, EffectCoverflow, Scrollbar]}
              className={styles.swiper}
              style={{'--swiper-theme-color': '#06813E'}}
              loop={false}
              rewind={false}>
            <SwiperSlide>
              <img src={GraphUSA} className={styles.swiperImg} alt="graph" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={GraphUSA1} className={styles.swiperImg} alt="graph" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={GraphUSA} className={styles.swiperImg} alt="graph" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={GraphUSA1} className={styles.swiperImg} alt="graph" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={GraphUSA} className={styles.swiperImg} alt="graph" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={GraphUSA1} className={styles.swiperImg} alt="graph" />
            </SwiperSlide>
          </Swiper>
        </div>
        <div className={styles.showMore}>Смотреть все</div>
      </div>*/}

            <div className={`${styles.wrapper} ${styles.slider}`}>
                <div className={styles.sliderHeader}>
                    <h2 className={styles.title}>Аналитика</h2>
                    <div className={styles.arrowNavigate}>
                        <button
                            className={`${styles.btnSwiper}`}
                            onClick={() => swiperRefNews.current.slidePrev()}>
                            <img src={sliderPositionNews === 'start' ? ArrowLeftGrey : ArrowLeft} alt="arrow"/>
                        </button>
                        <button
                            className={styles.btnSwiper}
                            onClick={() => swiperRefNews.current.slideNext()}>
                            <img src={sliderPositionNews === 'end' ? ArrowRightGrey : ArrowRight} alt="arrow"/>
                        </button>
                    </div>
                </div>
                <div className={styles.swiperWrapper}>
                    {isLoadingAnalyticsData ? <Preloader/> : <Swiper
                        onSwiper={(swiper) => {
                            swiperRefNews.current = swiper;
                            handleSwiperNews(swiper)
                        }}
                        onSlideChange={(swiper) => handleSwiperNews(swiper)}
                        spaceBetween={20}
                        slidesPerView={3}
                        modules={[Autoplay, EffectCoverflow, Scrollbar]}
                        className={styles.swiper}
                        style={{'--swiper-theme-color': '#06813E'}}
                        loop={false}
                        rewind={false}>
                        {analyticsData.filter((el, index) => index < 10).map((post) => {
                            return <SwiperSlide key={post.id} className={styles.swiperSlide}>
                                <NavLink to={`/analytics-and-news/${post.slug}`} target={'_blank'} rel={'noopener noreferrer'}>
                                <div className={styles.newsWrapper}>
                                    {post.country_tag && post.country_tag.length && post.country_tag[0]?.name ? <span className={styles.newsCountryTag}>{post.country_tag[0].name}</span>: '' }
                                    <div className={styles.newsDate}>{post.pub_date_formated}</div>
                                    <div className={styles.newsTextContainer}>{post.description}</div>
                                </div>
                                </NavLink>
                            </SwiperSlide>
                        })}

                    </Swiper>}
                </div>
                <div className={styles.showMore}
                     onClick={() => onClickNavigateToAN('2', information.main_info.company_id)}>Смотреть все
                </div>
            </div>

            <div className={`${styles.wrapper} ${styles.slider}`}>
                <div className={styles.sliderHeader}>
                    <h2 className={styles.title}>Новости</h2>
                    <div className={styles.arrowNavigate}>
                        <button
                            className={`${styles.btnSwiper}`}
                            onClick={() => swiperRefAnalytics.current.slidePrev()}>
                            <img src={sliderPositionAnalytics === 'start' ? ArrowLeftGrey : ArrowLeft} alt="arrow"/>
                        </button>
                        <button
                            className={styles.btnSwiper}
                            onClick={() => swiperRefAnalytics.current.slideNext()}>
                            <img src={sliderPositionAnalytics === 'end' ? ArrowRightGrey : ArrowRight} alt="arrow"/>
                        </button>
                    </div>
                </div>
                <div className={styles.swiperWrapper}>
                    {isLoadingNewsData ? <Preloader/> : <Swiper
                        onSwiper={(swiper) => {
                            swiperRefAnalytics.current = swiper;
                            handleSwiperAnalytics(swiper)
                        }}
                        onSlideChange={(swiper) => handleSwiperAnalytics(swiper)}
                        spaceBetween={20}
                        slidesPerView={3}
                        modules={[Autoplay, EffectCoverflow, Scrollbar]}
                        className={styles.swiper}
                        style={{'--swiper-theme-color': '#06813E'}}
                        loop={false}
                        rewind={false}>
                        {newsData.filter((el, index) => index < 10).map((post) => {
                            return <SwiperSlide key={post.id} className={styles.swiperSlide}>
                                <NavLink to={`/analytics-and-news/${post.slug}`} target={'_blank'} rel={'noopener noreferrer'}>
                                <div className={styles.newsWrapper}>

                                    {post.country_tag && post.country_tag.length && post.country_tag[0]?.name ? <span className={styles.newsCountryTag}>{post.country_tag[0].name}</span> : ''}
                                    <div className={styles.newsDate}>{post.pub_date_formated}</div>
                                    <div className={styles.newsTextContainer}>
                                        {post.name}
                                    </div>
                                </div>
                                </NavLink>
                            </SwiperSlide>
                        })}
                    </Swiper>}
                </div>
                <div className={styles.showMore}
                     onClick={() => onClickNavigateToAN('1', information.main_info.company_id)}>Смотреть все
                </div>
            </div>

            <div className={`${styles.wrapper} ${styles.company}`}>
                <div className={styles.sliderHeader}>
                    <h2 className={styles.title}>Похожие компании</h2>
                    <div className={styles.arrowNavigate}>
                        <button
                            className={`${styles.btnSwiper}`}
                            onClick={() => swiperRefCompany.current.slidePrev()}>
                            <img src={sliderPositionCompany === 'start' ? ArrowLeftGrey : ArrowLeft} alt="arrow"/>
                        </button>
                        <button
                            className={styles.btnSwiper}
                            onClick={() => swiperRefCompany.current.slideNext()}>
                            <img src={sliderPositionCompany === 'end' ? ArrowRightGrey : ArrowRight} alt="arrow"/>
                        </button>
                    </div>
                </div>
                <div className={styles.swiperWrapper}>
                    <Swiper
                        onSwiper={(swiper) => {
                            swiperRefCompany.current = swiper;
                            handleSwiperCompany(swiper);
                        }}
                        onSlideChange={(swiper) => handleSwiperCompany(swiper)}
                        spaceBetween={screenWidth >= 768 ? 20 : screenWidth < 768 && screenWidth > 400 ? 15 : 10}
                        slidesPerView={screenWidth >= 768 ? 4.5 : screenWidth < 768 && screenWidth > 400 ? 3 : 2}
                        modules={[Autoplay, EffectCoverflow, Scrollbar]}
                        className={styles.swiper}
                        style={{'--swiper-theme-color': '#06813E'}}
                        loop={false}
                        rewind={false}>

                        {information?.['same_companies']?.map((item, i) => {
                            return (
                                <SwiperSlide key={i}>
                                    <NavLink to={`/screener/${item?.ticker}`} className={styles.companyWrapper} target={'_blank'} rel={'noopener noreferrer'}>
                                        <div className={styles.companyImg}>
                                            <img src={item?.logo} alt="logo"/>
                                        </div>
                                        <div className={styles.companySliderInfo}>
                                            <div className={styles.companyTicker}>{item?.ticker}</div>
                                        </div>
                                    </NavLink>
                                </SwiperSlide>
                            )
                        })}
                    </Swiper>
                </div>
                <div className={styles.showMore}>Смотреть все</div>
            </div>
        </>
    );
};

export default ScreenerTabInformation;
