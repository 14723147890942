  import {useEffect, useState} from "react";
import {getMarketMultipliersMap} from "../../../utils/api";
import {transformMultipliersData} from "../utilsForMarketMap";

export function useTreeMap2Data({isInitialized}) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isInitialized) {
      setLoading(true)

      getMarketMultipliersMap()
        .then(json => {
          setData(transformMultipliersData(json.sectors));
        })
        .finally(() => {
          setLoading(false)
        });
    }
  }, [isInitialized]);

  return {data, loading};
}