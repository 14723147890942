import {setIsLoading} from "../services/reducers/preloader-slice"
import {setCardsMainNew} from "../services/reducers/cards-main-new-slice"
import {
    setLesson,
    setLessonsCards,
    setThemesCards,
} from "../services/reducers/cards-slice"

export const responseStatus = async (res) => {
    if (res.ok) {
        const contentType = res.headers.get("content-type")
        if (contentType && contentType.indexOf("application/json") !== -1) {
            return res.json()
        } else {
            throw new TypeError("Ошибка JSON")
        }
    }
    return Promise.reject(`Ошибка ${res.status}`)
}

export const responseStatusThen = (res) => {
    if (res.ok) {
        const contentType = res.headers.get("content-type")
        if (contentType && contentType.indexOf("application/json") !== -1) {
            return res.json()
        } else {
            throw new TypeError("Ошибка JSON")
        }
    }
    return Promise.reject(`Ошибка ${res.status}`)
}

export const AnalyticsAndNewsDataApi = () => {
    // const token = localStorage.getItem("token")
    const headers = {
        "Content-Type": "application/json",
        "Cache-Control": "no-cache",
    }
    // if (token) {
    //   headers.Authorization = `Token ${token}`
    // }
    return fetch(`api/analytics-and-news/`, {
        method: "GET",
        headers: headers,
    }).then(responseStatus)
}

export const getCard3 = (slug, errorToken) => {
    const token = localStorage.getItem("token")

    const headers = {
        "Content-Type": "application/json",
        "Cache-Control": "no-cache",
    }
    if (!!token && errorToken) {
        headers.Authorization = `Token ${token}`
    }
    return fetch(`api/analytics-and-news/${slug}/`, {
        method: "GET",
        headers: headers,
    })
}

export const getRecommendedPosts = (slug) => {
    const token = localStorage.getItem("token")
    const headers = {
        "Content-Type": "application/json",
        "Cache-Control": "no-cache",
    }
    if (token) {
        headers.Authorization = `Token ${token}`
    }
    return fetch(`api/analytics-and-news/${slug}/get_recommended_posts`, {
        method: "GET",
        headers: headers,
    }).then(responseStatus)
}

// ?page_category=2&sector=24&sector=21&country_tag=2&country_tag=1&country_tag=3

//&page= обязательное поле по дефолту 1
//&page_category= не обязательное 1 новости 2 аналитика пусто 2 сделать без чек бокса
//&country_tag= не обязательное поле но порядок конкатинации должен меняться
//в завистимости от выбранных чек боксов (возможно стоит запихнуть просто в объект и он там дальше сам)
//&sector=21 не обязательное поле, сделать пока без чек бокса так как апи ещё к этому не готова, либо чек бокс будет создавать
//новый объект который будет набиваться
//&company_tag= обязательное значение берется из company_tag: [{id:_}], с условием выбора категории и страны
//&ordering= не обязательное но по дефолту есть значения

function removeFirstCharacter(str) {
    if (typeof str !== "string" || str.length === 0) {
        return str
    }
    return str.substring(1)
}

export const getCompanyPointsApi = (navigate) => {
    // const token = localStorage.getItem("token")

    const headers = {
        "Content-Type": "application/json",
    }

    // if (!!token) {
    // headers.Authorization = `Token ${token}`
    // }

    return fetch(
        `api/analytics-and-news/prefilter/?${removeFirstCharacter(navigate)}`,
        {
            method: "GET",
            headers,
        }
    )
        .then(responseStatus)
        .catch((err) => {
            console.log(err)
        })
        .finally(() => {
        })
}

export const getCardToFilter = (navigate = "", page = 1) => {
    const headers = {
        "Content-Type": "application/json",
    }
    return fetch(`api/analytics-and-news/?page=${page}${navigate}`, {
        method: "GET",
        headers,
    }).then(responseStatus)
}

// export const getFilterPointsApi = (countryTag = "", isUrlRequest = false) => {
//   if (!isUrlRequest) {
//     if (
//     localStorage.getItem("country_tag") === "?country_tag=-1" &&
//     countryTag === ""
//   ) {
//     countryTag = "?country_tag=-1"
//   }
//   if (
//     localStorage.getItem("country_tag") === "?country_tag=1" &&
//     countryTag === ""
//   ) {
//     countryTag = "?country_tag=1"
//   }
// }

//   const headers = {
//     "Content-Type": "application/json",
//   }
//   // if (!!token) {
//   //   // headers.Authorization = `Token ${token}`  //  тут не нужен токен
//   //   countryTag = ""
//   // }
//   return (
//     fetch(`api/analytics-and-news/prefilter/${countryTag}`, {
//       method: "GET",
//       headers,
//     })
//       .then(responseStatus)
//       // .then((res) => {
//       //   // console.log(`api/analytics-and-news/prefilter/${countryTag}`);

//       //   // console.log("getFilterPointsApi", res);
//       //   return res
//       // })
//       .catch((err) => {
//         console.log(err)
//       })
//     // .finally(() => {
//     // })
//   )
// }

export const getCardsMainNew = () => {
    return (dispatch) => {
        return fetch("api/news/?limit=12", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Cache-Control": "no-cache",
            },
        })
            .then(responseStatus)
            .then((res) => {
                dispatch(setCardsMainNew(res.results))
            })

            .catch((err) => {
                console.log(err)
            })

            .finally(() => {
                dispatch(setIsLoading(false))
            })
    }
}

export const getCardsMainAnalytics = () => {
    return fetch("api/analytics?limit=4", {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Cache-Control": "no-cache",
        },
    })
        .then(responseStatus)

}

export const emailCardAnalytic = (email) => {

    return fetch(`api/users/validate_email/`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            email
        })
    })

}


export const getThemesEducationCards = () => {
    return fetch("api/themes/", {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    })
    // .then(responseStatus)
    // .then((res) => {
    //   // console.log(res, "RESSSS");
    //   dispatch(setThemesCards(res.results))
    //   dispatch(setLessonsCards(res.results.map((element) => element.lessons)))
    // })

    // .catch((err) => {
    //   console.log(err)
    // })

    // .finally(() => {
    //   dispatch(setIsLoading(false))
    // })
    // }
}

export const getThemesCard = (slug) => {
    return fetch(`api/themes/${slug}/`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    })
}
export const getLesson = (slug) => {
    return fetch(`api/lessons/${slug}/`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    })
}


export const getInfoMainPageData = () => {
    return fetch(`api/info/`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    })
        .then(responseStatus)
        .then((res) => {
            return res
        })
}

export const urlDataPageInfo = (slug) => {
    return fetch(`api/info/${slug}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Cache-Control": "no-cache",
        },
    })
    // .then(responseStatus)
    // .then((res) => {
    //   // console.log(res);

    //   return res
    // })
}

// Запрос на получение данных для карты рынка
export const getMapMarket = async (period = '1y', ieChoice = false, includeImoex = false, moex_market_list = 'all', sector = 'all',) => {
    const ieChoiceQuery = ieChoice ? `&ie_choice=${ieChoice}` : ''
    const ImoexQuery = includeImoex ? `&include_imoex=${includeImoex}` : ''
    return fetch('https://indicator-tech.ru/' +
        `api/market-map?period=${period}${ieChoiceQuery}${ImoexQuery}&moex_market_list=${moex_market_list}&sector=${sector}`, {
        method: "GET",
        headers: {
            'Authorization': `Basic aW52ZXN0ZXJhX2FkbWluOkZPUG9KNTNoWVdtbVYzV3VhQVBq`,
            'Content-Type': 'application/json'
        }
    })
}

export async function getMarketMultipliersMap() {
    return fetch("https://indicator-tech.ru/api/multipliers", {
        headers: {
            'Authorization': `Basic aW52ZXN0ZXJhX2FkbWluOkZPUG9KNTNoWVdtbVYzV3VhQVBq`,
            'Content-Type': 'application/json'
        }
    })
      .then(res => res.json());
}

// Запрос на получение данных для глоссария
export const getGlossary = async () => {
    return fetch(`api/glossary`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        }
    })
}

// Запрос на получение общего списка акций (для главной страницы скринера)
export const getShareList = async (sectorId) => {
    const sectorsSearch = sectorId ? `?sectors=${sectorId}` : ''
    return fetch(`api/companies/shares-by-capitalization/${sectorsSearch}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        }
    })
}

// Запрос на получение списка акций для поисковой строки (для главной страницы скринера)
export const getSearchList = async (companyName) => {
    return fetch(`api/companies/find-company/?query=${companyName}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        }
    })
}

// Запрос на получение списка акций (для главной страницы скринера)
export const getStocksForChart = async (ticker, period) => {

    return fetch('https://indicator-tech.ru/'+`api/stocks?ticker_name=${ticker}&period=${period}`, {
        method: "GET",
        headers: {
            'Authorization': `Basic aW52ZXN0ZXJhX2FkbWluOkZPUG9KNTNoWVdtbVYzV3VhQVBq`,
            Accept: "application/json",
            "Content-Type": "application/json",
        }
    })
}

// Запрос на получение новостей и аналитики для скриннера
export const NewsForScreenerForScreener = (companyTag) => {
    return fetch(`api/analytics-and-news/?page=1&ordering=-pub_date&page_category=1&company_tag=${companyTag}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Cache-Control": "no-cache",
        },
    }).then(responseStatus)
}

export const AnalyticsForScreener = (companyTag) => {
    return fetch(`api/analytics-and-news/?page=1&ordering=-pub_date&page_category=2&company_tag=${companyTag}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Cache-Control": "no-cache",
        },
    }).then(responseStatus)
}

// Запрос на получение финансовых данных для компаний в скриннере
export const getFinanceDataForScreener = (ticker, period) => {
    return fetch(`api/financial-data/get-data/?ticker=${ticker}&type=${period}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Cache-Control": "no-cache",
        },
    }).then(responseStatus)
}

