import React, {useEffect, useState} from "react";
import {ResponsiveContainer, Tooltip, Treemap} from "recharts";
import {FiltersForMarketMap} from "../filters/filters-for-market-map";
import {formatNumberValue, heightForMobileTreemap, heightForTreemap} from "../utilsForMarketMap";
import {CustomizedContent} from "../customized-content/customized-content";
import {CustomTooltip} from "../custom-tooltip/custom-tooltip";
import Preloader from "../../preloader/preloader";
import {useTheme} from "../../../services/hooks/useTheme";
import {useResize} from "../../../services/hooks/useResize";
import styles from "./treemap.module.css";
import {FullScreenIcon} from "../FullScreenIcon";
import {useZoom} from "../useZoom";
import {useTreeMap1Data} from "./useTreeMap1Data";

const filtersRange = [
    {id: '1d', name: 'день'},
    {id: '1w', name: 'неделю'},
    {id: '1m', name: 'месяц'},
    {id: '3m', name: '3 месяца'},
    {id: '6m', name: '6 месяцев'},
    {id: '1y', name: '1 год'}
];
const filtersIndex = [
    {id: 'iMOEX', name: 'iMOEX'},
    {id: 'iMOEX+IE', name: 'iMOEX + выбор Invest Era'},
    {id: '1%2C2', name: '1-2 эшелоны'},
    {id: '3', name: '3 эшелон'},
    {id: 'all', name: 'все'},
];
const filtersSector = [
    {id: 'all', name: 'все'},
    {id: 'Энергетический', name: 'энергетический'},
    {id: 'Финансовый', name: 'финансовый'},
    {id: 'Добывающий', name: 'добывающий'},
    {id: 'IT', name: 'it'},
    {id: 'Коммуникации и развлечения', name: 'коммуникации и развлечения'},
    {id: 'REIT', name: 'reit'},
    {id: 'Потребительский защитный', name: 'потребительский защитный'},
    {id: 'Коммунальный', name: 'коммунальный'},
    {id: 'Здравоохранение', name: 'здравоохранение'},
    {id: 'Промышленный', name: 'промышленный'},
    {id: 'Потребительский цикличный', name: 'потребительский цикличный'},
];

export const TreemapComponent1 = ({isFullscreen, setIsFullscreen}) => {
    const {theme} = useTheme();
    const screenWidth = useResize()

    const [isInitialized, setIsInitialized] = useState(false);
    const [selectedRange, setSelectedRange] = useState(filtersRange[5]);
    const [selectedIndex, setSelectedIndex] = useState(filtersIndex[0]);
    const [selectedSector, setSelectedSector] = useState(filtersSector[0]);

    // Стягивает гет (квери) параметры из урла и обновляет стейты выбранных фильтров
    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const paramsObject = {};
        for (let [key, value] of searchParams.entries()) {
            if (key === 'sector') {
                paramsObject[key] = formatNumberValue(value);
            } else {
                paramsObject[key] = value;
            }
        }

        if (paramsObject.range) {
            setSelectedRange(filtersRange.find(item => item.id === paramsObject.range));
        }
        if (paramsObject.index) {
            setSelectedIndex(filtersIndex.find(item => item.id === paramsObject.index));
        }
        if (paramsObject.sector) {
            setSelectedSector(filtersSector.find(item => item.id === paramsObject.sector));
        }
        setIsInitialized(true);
    }, []);

    // Запрос данных
    const {loading, data} = useTreeMap1Data({isInitialized, selectedIndex, selectedRange, selectedSector});

    // zoom-эффект карты рынка
    const {currentZoom, svgRef} = useZoom(loading, data, screenWidth);

    return (
      <div className={styles.marketMapWrapper}>
          <div className={styles.filters}>
              <FiltersForMarketMap filtersRange={filtersRange} selectedRange={selectedRange}
                                   setSelectedRange={setSelectedRange} filtersIndex={filtersIndex}
                                   selectedIndex={selectedIndex} setSelectedIndex={setSelectedIndex}
                                   filtersSector={filtersSector} selectedSector={selectedSector}
                                   setSelectedSector={setSelectedSector} loading={loading}
              />
              {screenWidth > 1024 &&
                <div className={styles.screenMap} onClick={() => setIsFullscreen(!isFullscreen)}>
                    <FullScreenIcon theme={theme} isFullscreen={isFullscreen} />
                    {isFullscreen ? 'Свернуть полный экран' : 'Открыть во весь экран'}
                </div>}
          </div>

          <div style={{width: '100%'}} className={styles.treemapContainer}>
              {data.length > 0 && !loading ? (
                <div style={{
                    position: 'relative',
                    width: '100%',
                    maxWidth: `${screenWidth}px`,
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '50px',
                    marginBottom: `${screenWidth <= 940 && '50px'}`
                }}
                >
                    {screenWidth > 1440 ? <ResponsiveContainer
                        className={isFullscreen ? styles.fullscreen : styles.collapseFullscreen}
                        height={heightForTreemap(data)} ref={svgRef}>

                          <Treemap
                            data={data}
                            dataKey="size"
                            stroke="#FFFFFF"
                            content={<CustomizedContent currentZoom={currentZoom}/>}
                            aspectRatio={0.9}
                            isAnimationActive={false}
                          >
                              <Tooltip
                                content={<CustomTooltip/>}
                                allowEscapeViewBox={{x: false, y: false}}
                                animationDuration={300}
                              />
                          </Treemap>
                      </ResponsiveContainer>
                      : (
                        <>
                            {data.map((sector, index) => {
                                return <ResponsiveContainer key={index}
                                                            className={isFullscreen ? styles.fullscreen : styles.collapseFullscreen}
                                                            height={heightForMobileTreemap(sector)}
                                >
                                    <div className={styles.sectorHeader}>{sector.name.toLowerCase()}</div>
                                    <Treemap
                                      data={[sector]}
                                      dataKey="size"
                                      stroke="#FFFFFF"
                                      content={<CustomizedContent/>}
                                      aspectRatio={1}
                                      isAnimationActive={false}
                                    >
                                        <Tooltip
                                          content={<CustomTooltip/>}
                                          allowEscapeViewBox={{x: false, y: true}}
                                          animationDuration={0}
                                          animationEasing={'linear'}
                                        />
                                    </Treemap>

                                </ResponsiveContainer>
                            })}
                        </>
                      )
                    }
                </div>
              ) : <div className={styles.preloaderContainer}>
                  <Preloader/>
                  Скоро все загрузится. Пожалуйста, подождите
              </div>}
          </div>
      </div>
    );
};
