import {useCallback, useEffect, useRef, useState} from "react";
import {FilterList} from "./filterList/filterList";
import styles from "./filters-for-market-map.module.css";

export const FiltersForMultipliersMap = ({
                                      filtersMultiplicator,
                                      selectedMultiplier,
                                      setSelectedMultiplier,
                                      filtersIndex,
                                      selectedIndex,
                                      setSelectedIndex,
                                      filtersSector,
                                      selectedSector,
                                      setSelectedSector,
                                      loading
                                    }) => {
  const [activeMultipliersMenu, setActiveMultipliersMenu] = useState(false)
  const [activeIndexMenu, setActiveIndexMenu] = useState(false)
  const [activeSectorMenu, setActiveSectorMenu] = useState(false)
  const rangeBoxRef = useRef(null);
  const indexBoxRef = useRef(null);
  const sectorBoxRef = useRef(null);

  const singleOpener = useCallback((filterName) => {
    switch (filterName) {
      case "setActiveMultiplicatorMenu":
        setActiveMultipliersMenu((prev) => !prev)
        setActiveIndexMenu(false)
        setActiveSectorMenu(false)
        break

      case "setActiveIndexMenu":
        setActiveIndexMenu((prev) => !prev)
        setActiveMultipliersMenu(false)
        setActiveSectorMenu(false)
        break

      case "setActiveSectorMenu":
        setActiveSectorMenu((prev) => !prev)
        setActiveMultipliersMenu(false)
        setActiveIndexMenu(false)
        break
    }
  }, []);

  const handleClickOutside = (event) => {
    if (rangeBoxRef.current && !rangeBoxRef.current.contains(event.target)) {
      setActiveMultipliersMenu(false);
    }
    if (indexBoxRef.current && !indexBoxRef.current.contains(event.target)) {
      setActiveIndexMenu(false);
    }
    if (sectorBoxRef.current && !sectorBoxRef.current.contains(event.target)) {
      setActiveSectorMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className={styles.filters}>
      <FilterList loading={loading} singleOpener={singleOpener} valueForSingleOpener={'setActiveMultiplicatorMenu'}
                  selectedValue={selectedMultiplier} activeMenu={activeMultipliersMenu} setActiveMenu={setActiveMultipliersMenu}
                  setSelectedValue={setSelectedMultiplier} categoryPoints={filtersMultiplicator} typeValue={'multiplier'}
                  buttonName={'Мультипликатор'} ref={rangeBoxRef}
      />
      <FilterList loading={loading} singleOpener={singleOpener} valueForSingleOpener={'setActiveIndexMenu'}
                  selectedValue={selectedIndex} activeMenu={activeIndexMenu}
                  setActiveMenu={setActiveIndexMenu}
                  setSelectedValue={setSelectedIndex} categoryPoints={filtersIndex} typeValue={'index'}
                  buttonName={'Индекс'} ref={indexBoxRef}
      />
      <FilterList loading={loading} singleOpener={singleOpener} valueForSingleOpener={'setActiveSectorMenu'}
                  selectedValue={selectedSector} activeMenu={activeSectorMenu} setActiveMenu={setActiveSectorMenu}
                  setSelectedValue={setSelectedSector} categoryPoints={filtersSector} typeValue={'sector'}
                  buttonName={'Сектор'} ref={sectorBoxRef}
      />
    </div>
  );
};